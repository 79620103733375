import {
	Collapse,
	IconButton,
	makeStyles,
	TableCell,
	TableRow,
	Tooltip,
	withStyles,
} from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import React from "react"
import { dateFormat } from "../utils/dateTimeFormat"
import CollapsePanel from "../LeaveTable/CollapsePanel/CollapsePanel"
import ApproverAvatar from "../ApproverAvatar/ApproverAvatar"
import axios from "../utils/axios"
import { notify } from "../utils/toast"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import RejectLeaveModal from "../AdminLeaveTab/RejectLeaveModal/RejectLeaveModal"

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
})

const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapsiableUSLRTable({
	isLoading,
	setIsLoading,
	ld,
	showHour,
	setLeaveDetails
}) {
	const classes = useRowStyles()
	const [open, setOpen] = React.useState(false)

	const handleApprove = (id) => {
		axios
			.put(`api/leaveApprove/${id}`)
			.then((response) => {
				notify("success", "leave has been approved")
				setIsLoading(!isLoading);
			})
			.catch((error) => notify("error", error.response.data))
	}
	return (
		<>
			<TableRow className={classes.root}>
				<StyledTableCell>
					<IconButton onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>{ld.first_name + " " + ld.last_name}</StyledTableCell>
				<StyledTableCell>{ld.leave_type_name}</StyledTableCell>
				<StyledTableCell>{dateFormat(ld.date_of_leave)}</StyledTableCell>
				<StyledTableCell>{ld.leave_time}</StyledTableCell>
				<StyledTableCell>{dateFormat(ld.date_of_arrival)}</StyledTableCell>
				<StyledTableCell>{ld.arrival_time}</StyledTableCell>
				<StyledTableCell>
					{showHour ? ld.durationHour : ld.durationDay}
				</StyledTableCell>
				<StyledTableCell style={{ display: "flex" }}>
					{ld.approvers.map((ad) => (
						<ApproverAvatar ad={ad} declineMessage={ld.decline_message} />
					))}
				</StyledTableCell>
				<StyledTableCell>
					{ld.name === "Pending" && (
						<div>
							<IconButton
								style={{ color: "green" }}
								onClick={() => handleApprove(ld.id)}
							>
								<Tooltip title="Approve">
									<ThumbUpIcon />
								</Tooltip>
							</IconButton>
							<RejectLeaveModal id={ld.id} setLeaveDetails={setLeaveDetails} />
						</div>
					)}
				</StyledTableCell>
			</TableRow>
			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={11}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<CollapsePanel
							leaveDescription={ld.leave_description}
							leaveDoc={ld.leave_documents}
							status={ld.name}
							declineMessage={ld.decline_message}
							approvers={ld.first_name + " " + ld.last_name}
							requestId={ld.id}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
