import axios from "../../utils/axios";
import { notify } from "../../utils/toast";

export const fetchUsers = () => async (dispatch) => {
  try {
    const response = await axios.get("/api/users");
    const users = response.data.result;
    dispatch({
      type: "GET_USERS_SUCCESS",
      payload: users,
    });
  } catch (error) {
    dispatch({
      type: "GET_USERS_ERROR",
      payload: error.response,
    });
  }
};
export const fetchUserInventories = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/users/${id}/inventories`);
    dispatch({
      type: "GET_USER_INVENTORIES_SUCCESS",
      payload: response.data.result,
    });
  } catch (error) {
    dispatch({
      type: "GET_USER_INVENTORIES_ERROR",
      payload: error.response,
    });
  }
};
export const addUser = (user, history, setLoading) => async (dispatch) => {
  try {
    // const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await axios.post(
      "/api/users",
      JSON.stringify(user)
      // ,config
    );
    const result = response.data.result;
    dispatch({
      type: "ADD_USER_SUCCESS",
      payload: result,
    });
    history.push("/dashboard/users/" + result.employeeId);
    notify("success", "Successfully added!");
  } catch (error) {
    notify("error", error.response.data);
    dispatch({
      type: "ADD_USER_ERROR",
      payload: error.response.data.errors ?? error,
    });
  }
  setLoading(false);
};
export const updateUser = (id, user, history, setLoading, role) => async (dispatch) => {
  try {
    const data = JSON.stringify(user);
    const response = await axios.put("/api/users/" + id, data);

    dispatch({
      type: "UPDATE_USER_SUCCESS",
      payload: response.data.result,
    });
    if(role === 'ROLE_USER')
    {
      history.push("/users/" + response.data.result.employeeId);
    } else {
      history.push("/dashboard/users/" + response.data.result.employeeId);
    }
    notify("success", "Update success!");
  } catch (error) {
    notify("error", error.response.data);
    dispatch({
      type: "UPDATE_USER_ERROR",
      payload: error.response.data.errors,
    });
  }
  setLoading(false);
};

export const fetchUser = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/users/${id}`);
    const user = response.data.result;
    const supervisors = response.data.supervisors;
    dispatch({
      type: "GET_USER_SUCCESS",
      payload: user,
    });
    dispatch({
      type: "GET_USER_SUPERVISORS_SUCCESS",
      payload: supervisors,
    });
  } catch (error) {
    notify("error", error.response.data);
    dispatch({
      type: "GET_USER_ERROR",
      payload: error.response,
    });
  }
};

export const removeUser = (id) => async (dispatch) => {
  try {
    const response = await axios.put(`/api/admin/users/${id}/remove`, null);
    const user = response.data.result;
    dispatch({
      type: "REMOVE_USER_SUCCESS",
      payload: user,
    });
    notify("success", "successfully removed!");
  } catch (error) {
    notify("error", error.response.data);
    dispatch({
      type: "REMOVE_USER_ERROR",
      payload: error.response,
    });
  }
};
export const reviveUser = (id) => async (dispatch) => {
  try {
    const response = await axios.put(`/api/admin/users/${id}/revive`, null);
    const user = response.data.result;
    dispatch({
      type: "REVIVE_USER_SUCCESS",
      payload: user,
    });
    notify("success", "successfully restored!");
  } catch (error) {
    notify("error", error.response.data);
    dispatch({
      type: "REVIVE_USER_ERROR",
      payload: error.response,
    });
  }
};
