import React, { useEffect } from 'react'
import { useState } from 'react'
import { notify } from '../utils/toast.js';
import axios from '../utils/axios'
import './ManagePermission.css';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import AddRoleModal from './AddRoleModal/AddRoleModal.jsx';
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/styles"
import { makeStyles } from "@material-ui/core"
import { Add, Delete, Edit } from '@material-ui/icons';
import ConfirmModal from '../ConfirmModal/ConfirmModal.jsx';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ManagePermission() {
    const history = useHistory();
    const permissions = useSelector((store)=>store.authReducer.permissions)
    const [roles,setRoles] = useState([]);
    const [currentRole,setCurrentRole] = useState();
    const [addRole,setAddRole] = useState(false);
    const [deleteRole,setDeleteRole] = useState(false);
    const [reload,setReload] = useState(false);

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#ff6600",
            color: theme.palette.common.white,
            textAlign: "center",
        },
    }))(TableCell)
    
    const useStyles = makeStyles({
        table: {
            minWidth: 700,
            padding: "10px",
        },
        tableContainer: {
            width: "90%",
            margin: "auto",
        },
    })

    const classes = useStyles()

	// const emptyRows =
	// 	rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

	// const handleChangePage = (event, newPage) => {
	// 	setPage(newPage)
	// }

	// const handleChangeRowsPerPage = (event) => {
	// 	setRowsPerPage(parseInt(event.target.value, 10))
	// 	setPage(0)
	// }

    const onConfirm = (action,role,roleId) =>{
        setAddRole(false);
        setCurrentRole(false);
        action === "add" ?
        // add role here
        axios.post("/api/role",{role:[role]})
            .then((res)=>{
                notify("success",res.data.message);
                setReload(!reload);
                history.push('/dashboard/permission/'+res.data.id);
            })  
            .catch((err)=>{
                notify("error","Error while adding role")
            })  
        :
        // edit role here
        axios.put("/api/role/"+roleId,{role:role})
        .then((res)=>{
            notify("success",res.data);
            setReload(!reload);
        })  
        .catch((err)=>{
            notify("error",err.response.data)
        })  

    }

    const onDelete = (role) => {
        setDeleteRole(false);
        // delete role here
        axios.delete("/api/role/"+role.id)
        .then((res)=>{
            notify("success",res.data);
            setReload(!reload);
        })  
        .catch((err)=>{
            notify("error",err.response.data)
        })  
    }

    useEffect(()=>{
        // roles fetch
        axios.get('api/get/role')
        .then((res)=>{
            setRoles(res.data);
            // SetCurrentRole(res.data[0].id);
        })
        .catch((err)=>{
            notify("error","Something went wrong")
        })
    },[reload])

    
    return (
        <div>
            <div className="container-fluid text-center mb-4">
                <h2 style={{color: '#ff6600'}} className="my-3">Manage Permissions</h2>
                <div className="row">

                    {/* table of roles here */}
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        {
				            (permissions?.includes("add_role")) &&
                            <div className="d-flex justify-content-end">
                                <IconButton aria-label='add new role' onClick={()=>{
                                    setCurrentRole({});
                                    setAddRole(true);
                                }}>
                                    <Add/>                                                        
                                </IconButton>
                            </div>
                        }

                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell>S.N</StyledTableCell>
                                    <StyledTableCell>Role</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    roles && roles.map((rowData,key)=>
                                    (
                                        <TableRow key={key} hover onClick={()=>{
                                            history.push('/dashboard/permission/'+rowData.id)
                                        }}>
                                            <TableCell className='text-center'>{rowData.id}</TableCell>
                                            <TableCell className='text-center'>{rowData.name}</TableCell>
                                            <TableCell className='text-center'>
                                                <div className="d-flex justify-content-center">
                                                    {
                                                        (permissions?.includes("edit_role")) &&
                                                        <IconButton style={{color: "#ff6600"}} onClick={(e)=>{
                                                            e.stopPropagation(); //stops bubbling (stops trigger event of parent when clicked in child)
                                                            setCurrentRole({name:rowData.name,id:rowData.id});
                                                            setAddRole(true);
                                                        }}>
                                                            <Edit/>                                                        
                                                        </IconButton>   
                                                    }
                                                    {
                                                        (permissions?.includes("delete_role")) &&
                                                        <IconButton style={{color: "red"}} onClick={(e)=>{
                                                            e.stopPropagation();
                                                            setCurrentRole({name:rowData.name,id:rowData.id});
                                                            setDeleteRole(true);
                                                        }}>
                                                            <Delete/>
                                                        </IconButton>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {/* {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={1} />
                                    </TableRow>
                                )} */}
                            </TableBody>
                            {/* <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={9}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { "aria-label": "rows per page" },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter> */}
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {
                currentRole &&
                <AddRoleModal defRole={currentRole} open={addRole} setOpen={setAddRole} onCancel={()=>{setAddRole(false);setCurrentRole(false)}} onConfirm={onConfirm}/>
            }
            <ConfirmModal content={"Are you sure you want to delete this role?"} open={deleteRole} setOpen={setDeleteRole} onCancel={()=>{setDeleteRole(false)}} onConfirm={
                ()=>{
                    onDelete(currentRole);
                }
            }></ConfirmModal>
        </div>
    )
}

export default ManagePermission