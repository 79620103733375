import React from "react"
import CollapsePanel from "./CollapsePanel/CollapsePanel"
import Collapse from "@material-ui/core/Collapse"
import TableRow from "@material-ui/core/TableRow"
import { IconButton, makeStyles } from "@material-ui/core"
import { Chip } from "@material-ui/core"
// import { Button } from "@material-ui/core"
import { TableCell } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
// import BorderColorIcon from "@material-ui/icons/BorderColor"
import CancelIcon from "@material-ui/icons/Cancel"
import { Tooltip } from "@material-ui/core"
import axios from "../utils/axios"
// import { notify } from "../utils/toast"
import ApproverAvatar from "../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../utils/dateTimeFormat"
import { useHistory } from "react-router-dom"
import { withStyles } from "@material-ui/styles"
import EditIcon from "@material-ui/icons/Edit";
import {notify} from "../../Components/utils/toast";
import SettlementChip from "../Common/SettlementChip/SettlementChip"

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
})
const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapsiableTable({
	ld,
	setLeaveDetails,
	page,
	rowsPerPage,
	setCount,
	findBy,
	showHour,
	fromSummary,
}) {
	const [open, setOpen] = React.useState()
	React.useEffect(() => {
		setOpen(false)
	}, [page])
	const classes = useRowStyles()
	const DOL = dateFormat(ld.date_of_leave)
	const DOA = dateFormat(ld.date_of_arrival)
	const LT = ld.leave_time
	const AT = ld.arrival_time
	const history = useHistory()
	const handleClick = () => {
		setOpen(!open)
	}

	const handleCancel = async (id, page, rowsPerPage) => {
		const responseData = await axios
			.delete(
				`api/leaveRequest/cancel/${id}?page=${page}&rowsPerPage=${rowsPerPage}&findBy=${JSON.stringify(
					findBy
				)}`
			)
			.then((res) => {
				return res.data
			})
			.catch((err) => notify('error','Something went wrong !'))
		setLeaveDetails(responseData.result)
		setCount(responseData.count)
	}
	const handleClickEdit = (id) => {
		history.push(`/leave/myLeaveDetails/edit/${id}`)
	}

	return (
		<>
			<TableRow className={classes.root}>
				<StyledTableCell>
					<IconButton onClick={() => handleClick()}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>
					{ld.leave_type_name}<br></br>
					{ld.leave_type_name === "Unpaid Leave" && ld.name === "Approved" && ld.settled_with_cl > 0 && (<SettlementChip settleDays={ld.settled_with_cl?ld.settled_with_cl:0}/>)}
				</StyledTableCell>
				<StyledTableCell>{DOL}</StyledTableCell>
				<StyledTableCell>{LT}</StyledTableCell>
				<StyledTableCell>{DOA}</StyledTableCell>
				<StyledTableCell>{AT}</StyledTableCell>
				<StyledTableCell>
					{showHour === true ? ld.durationHour : ld.durationDay}
				</StyledTableCell>
				<StyledTableCell>
					<Chip
						label={ld.name}
						style={{
							backgroundColor:
								(ld.name === "Pending" && "#1769aa") ||
								(ld.name === "Rejected" && "red") ||
								(ld.name === "Approved" && "green"),
							color: "white",
						}}
					/>
				</StyledTableCell>
				{!fromSummary && (
				<StyledTableCell >
					{ld.approvers.map((ad,key) => (
						<ApproverAvatar key={key} ad={ad} declineMessage={ld.decline_message} />
					))}
				</StyledTableCell>
				)}
				{!fromSummary && (
				<StyledTableCell>
					{ld.name === "Pending" && (
						<IconButton
							style={{ color: "#ba000d" }}
							onClick={() => handleCancel(ld.id, page, rowsPerPage)}
						>
							<Tooltip title="Cancel">
								<CancelIcon />
							</Tooltip>
						</IconButton>
					)}
					{ld.name === "Pending" && (
						<IconButton onClick={() => handleClickEdit(ld.id)}>
							{/* <BorderColorIcon style={{ color: "#ff6600" }} /> */}
							<EditIcon style={{ color: "#ff6600"}} />
						</IconButton>
					)}
				</StyledTableCell>
				)}
			</TableRow>
			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={11}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<CollapsePanel
							leaveDescription={ld.leave_description}
							leaveDoc={ld.leave_documents}
							status={ld.name}
							declineMessage={ld.decline_message}
							approvers={ld.first_name + " " + ld.last_name}
							requestId={ld.id}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
