const INITIAL_STATE = {
    servicings: [],
    inventoryServicings: [],
    isLoading: true,
    error: {}
}

export default function servicingReducer(state=INITIAL_STATE, action){
    switch(action.type){
        case 'GET_SERVICINGS_SUCCESS':
            return {
                ...state,
                servicings: action.payload,
                isLoading:false
            }
        case 'GET_INVENTORY_SERVICINGS_SUCCESS':
            return {
                ...state,
                inventoryServicings: action.payload,
                isLoading:false
            }
        case 'GET_SERVICINGS_ERROR':
        case 'GET_INVENTORY_SERVICINGS_ERROR':
        case 'UPDATE_INVENTORY_SERVICINGS_FAILURE':
            return {
                ...state,
                error: action.payload,
                isLoading: false
            }
        case 'UPDATE_INVENTORY_SERVICINGS_SUCCESS':
            return {
                ...state,
                inventoryServicings: state.inventoryServicings.map(is => {
                    if(is.id === action.payload.id){
                        return action.payload
                    }
                    return is
                }),
                isLoading: false
            }
        case 'CREATE_INVENTORY_SERVICINGS_SUCCESS':
            return {
                ...state,
                inventoryServicings: [action.payload, ...state.inventoryServicings],
                isLoading: false
            }
        default:
            return state;
    }
}