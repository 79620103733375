import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "./ForgotPassword.css";
import { Button } from "@material-ui/core";
import axios from "../utils/axios";
import { Link, useHistory } from "react-router-dom";
import { notify } from "../utils/toast";
import ButtonLoader from '../ButtonLoader/ButtonLoader';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const emailRef = useRef();
  const history = useHistory();
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "/forgotpassword",
        JSON.stringify({ email: emailRef.current.value })
      )
      .then((res) => {
        setLoading(false);
        history.push("/login");
        notify("success", "Verification link is send to your email");
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err.response?.data?.errors)
      });
  }
  return (
    <div className="main-wrapper" data-aos="zoom-out-down">
      <label className="text-center h5">Forgot password</label>
      <div className="formInput">
        <Form onSubmit={handleSubmit}>
          <Form.Label>Email address</Form.Label>
          <Form.Control ref={emailRef} placeholder="Enter your email address" />
          {
            errors?.email && <Form.Label className="text-danger mt-2">{errors?.email}</Form.Label>
          }
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            className="my-5"
          >
            {
              loading ? <ButtonLoader color="orange" /> : "Submit" 
            }
          </Button>
          <Link to="/login" className='link'>
            <Button
              variant="outlined"
              color="secondary"
              style={{margin: '5px 5px'}}
            >
              Cancel
            </Button>
          </Link>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
