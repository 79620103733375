const INITIAl_STATE = {
  adminInventories: [],
  unallocatedInventories: [],
  userinventories: [],
  inventory: null,
  isLoading: true,
  errors: {},
};

export default function inventoryReducer(state = INITIAl_STATE, action) {
  switch (action.type) {
    case "GET_ADMIN_INVENTORIES_SUCCESS":
      return {
        ...state,
        adminInventories: action.payload,
        isLoading: false,
        errors: {},
      };
    case "GET_UNALLOCATED_INVENTORIES_SUCCESS":
      return {
        ...state,
        unallocatedInventories: action.payload,
        isLoading: false,
        errors: {},
      };
    case "GET_USER_INVENTORIES_SUCCESS":
      return {
        ...state,
        userInventories: action.payload,
        isLoading: false,
      };
    case "GET_INVENTORY_SUCCESS":
      return {
        ...state,
        inventory: action.payload,
        isLoading: false,
        errors: {},
      };
    case "GET_INVENTORIES_ERROR":
    case "ADD_INVENTORY_ERROR":
    case "GET_INVENTORY_ERROR":
    case "DISPOSE_INVENTORY_ERROR":
    case "REVIVE_INVENTORY_ERROR":
    case "UPDATE_INVENTORY_ERROR":
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
    case "ADD_INVENTORY_SUCCESS":
      return {
        ...state,
        adminInventories: [...state.adminInventories, action.payload],
        inventory: action.payload,
        isLoading: false,
        errors: {},
      };
    case "DISPOSE_INVENTORY_SUCCESS":
    case "REVIVE_INVENTORY_SUCCESS":
      return {
        ...state,
        adminInventories: state.adminInventories.map((inventory) => {
          if (inventory.inventoryId === action.payload.inventoryId) {
            inventory = action.payload;
          }
          return inventory;
        }),
      };
    case "UPDATE_INVENTORY_SUCCESS":
      return {
        ...state,
        errors: {},
        adminInventories: state.adminInventories.map((inventory) => {
          if (inventory.inventoryId === action.payload.inventoryId) {
            inventory = action.payload;
          }
          return inventory;
        }),
        inventory: action.payload,
      };
    case "UPDATE_INVENTORY_STATUS":
      return {
        ...state,
        inventory: { ...state.inventory, isAllocated: action.payload },
      };
    case "UPDATE_REPAIR_STATUS":
      return {
        ...state,
        inventory: { ...state.inventory, inRepair: action.payload },
      };
    default:
      return state;
  }
}
