import React from "react"
import { withStyles } from "@material-ui/core/styles"
import FormGroup from "@material-ui/core/FormGroup"
import Switch from "@material-ui/core/Switch"
import { Grid, Typography } from "@material-ui/core"

const CustomSwitch = withStyles({
	switchBase: {
		color: "#ff6600",
		"&$checked": {
			color: "#ff6600",
		},
		"&$checked + $track": {
			backgroundColor: "#ff6600",
		},
	},
	checked: {},
	track: {},
})(Switch)

export default function CommonSwitch({first, second, showFirst, setShowFirst }) {
	const handleChange = (e) => {
		setShowFirst(!showFirst)
	}

	return (
		<div>
			<FormGroup>
				<Typography component="div">
					<Grid component="label" container alignItems="center" spacing={1}>
						<Grid item style={{ color: "#ff6600" }}>
							{first}
						</Grid>
						<Grid item>
							<CustomSwitch checked={showFirst} onChange={handleChange} />
						</Grid>
						<Grid item style={{ color: "#ff6600" }}>
							{second}
						</Grid>
					</Grid>
				</Typography>
			</FormGroup>
		</div>
	)
}
