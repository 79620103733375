import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import axios from "../utils/axios"
import Spinner from "../Spinner/Spinner"
import CountPaper from "../Common/CountPaper/CountPaper"
import PeopleAltIcon from "@material-ui/icons/PeopleAlt"
import BirthdayAlert from "../BirthdayAlert/BirthdayAlert"

import TodaysLeavePaper from "../TodaysLeavePaper/TodaysLeavePaper"
import MyLeaveStatistics from "../MyLeaveStatistics/MyLeaveStatistics"
import PublicHolidays from "../PublicHolidays/PublicHolidays"
import { useSelector } from "react-redux"
import PhysicalEmployees from "../EmployeesStatus/PhysicalEmployees"

const Charts = () => {
	const [loading, setLoading] = useState(false)
	const [data, setData] = React.useState({})
	const permissions = useSelector((store)=> store.authReducer.permissions);
	React.useEffect(() => {
		setLoading(true)
		axios.get("/api/dashboard").then((res) => {
			setLoading(false)
			setData(res.data.result)
		})
	}, [])
	if (loading) return <Spinner />
	return (
		<Container fluid data-aos="zoom-out-up"  className="my-3">
			{
				(permissions?.includes("view_dashboard_overview")) &&
				<>
					<Container fluid>
						<h4 className="mb-4 mx-2" style={{ color: "#ff6600" }}>
							Dashboard Overview
						</h4>
						<Row>
							<Col xl={3} lg={4} md={6}>
								<CountPaper
									path={"/dashboard/users"}
									icon={
										<PeopleAltIcon style={{ fontSize: "2.5rem", color: "#6F462B" }} />
									}
									label="Total Employees"
									value={data.user?.total}
								/>
							</Col>
							<Col xl={3} lg={4} md={6}>
								<CountPaper
									path={"/dashboard/requests"}
									icon={
										<i
											className="fas fa-boxes text-info"
											style={{ fontSize: "1.8rem" }}
										/>
									}
									label=" Pending Inventories"
									value={data.request?.pending}
								/>
							</Col>
							<Col xl={3} lg={4} md={6}>
								<CountPaper
									path={"/dashboard/leaveTab"}
									icon={
										<i
											className="fas fa-stopwatch text-warning"
											style={{ fontSize: "2rem" }}	
										></i>
									}
									label=" Pending Leave Requests"
									value={data?.pending_leaves}
								/>
							</Col>
							<Col xl={3} lg={4} md={6}>
								<CountPaper
									path={"/dashboard/servicings"}
									icon={
										<i
											className="fas fa-tools text-primary"
											style={{ fontSize: "1.8rem" }}
										></i>
									}
									label="Total servicings & Repairs"
									value={data.repair?.ongoing + data.servicing?.ongoing}
								/>
							</Col>
						</Row>
					</Container>
					<hr />
				</>
			}
			{/* Hide graph and charts according to OMS-011 */}
			{/* <Row className="m-5">
				<Col xl={3} lg={6} xs={12} className="my-3">
					<BarDiagram data={data.inventory} label="Inventories by category" />
				</Col>
				<Col xl={2} lg={5} xs={10} className="my-3">
					<DonutChart data={data.user} />
				</Col>
			</Row> */}
			
			<Row className="justify-content-between">
				<div className={permissions?.includes("view_events")?"col-md-6":"col-md-12"}>
					<TodaysLeavePaper />
				</div>
				<div className="col-md-6">
					{
						(permissions?.includes("view_events")) &&
						<BirthdayAlert birthdays={data.birthdays} />
					}
				</div>
			</Row>
			<hr />
			<div className="row g-5">
				<div className="col-md-6">
					<MyLeaveStatistics />
				</div>
				<div className="col-md-6">
					<PublicHolidays />
				</div>
				<div className="col-md-6" >
					<PhysicalEmployees />
				</div>
			</div>
			<hr />
		</Container>
	)
}

export default Charts
