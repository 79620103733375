import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Button} from '@material-ui/core'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../../Common/Form/TextInput/TextInput";
import axios from "../../utils/axios";
import { notify } from "../../utils/toast";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AddCategoryAccordion({categories, setCategories}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className={classes.root}>
      <Accordion expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
          <Typography className={classes.heading}>Add Category</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Formik
            initialValues={{ category: "" }}
            validationSchema={Yup.object({
              category: Yup.string()
                .max(15, "Must be 15 characters or less")
                .required("Required"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                axios
                .post("/api/users/documents/categories", JSON.stringify(values))
                .then((res) => {
                    setExpanded(false);
                    setSubmitting(false);
                    resetForm();
                    setCategories([...categories, res.data.result])
                })
                .catch((err) => 
				          notify('error','Something went wrong !')
                );
            }}
          >
            <Form>
              <TextInput
                label="Category"
                name="category"
                type="text"
                placeholder="Category"
              />
              <br/>
              <Button type="submit" variant='outlined' color='primary'>Add</Button>
            </Form>
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
