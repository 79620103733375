import {
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	makeStyles,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

import React from "react"
import CollapseHistoryTable from "./CollapsibleTable/CollapseHistoryTable"
import TableFooter from "@material-ui/core/TableFooter"
import TablePaginationActions from "../../TablePaginationActions/TablePaginationActions"
import TablePagination from "@material-ui/core/TablePagination"
import axios from "../../utils/axios"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"
import { notify } from "../../utils/toast"
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 900,
	},
	tableContainer: {
		widh: "100%",
	},
})

export default function WorkHistoryTable() {
	const classes = useStyles()
	const [page, setPage] = React.useState(0)
	const [workDetails,setWorkDetails] = React.useState()
	const [isLoaded,setIsLoaded] = React.useState()
	const [rowsPerPage, setRowsPerPage] = React.useState(25)
	const [count, setCount] = React.useState()
	const [employees,setEmployees] = React.useState()
	const [showHour, setShowHour] = React.useState(false)
	const [findBy, setFindBy] = React.useState({
		startDate: "",
		endDate: "",
		employeeId:""
	})
	var currDate = new Date()
	var currMonth = currDate.getMonth()
	const handleChangeFind = (e) => {
		setFindBy({ ...findBy, [e.target.name]: e.target.value })
	}

	React.useEffect(()=>{
		setFindBy({
			...findBy, 
			["startDate"]: parseInt(currMonth < 6 ? (new Date().getFullYear() -1) : (new Date().getFullYear()))  + "-07-01",
			["endDate"]: parseInt(currMonth < 6 ? (new Date().getFullYear()) : (new Date().getFullYear()+1)) + "-06-30",
		})
	},[])

	React.useEffect(()=>{
		let isMounted=true;
		axios
			.get(
				`api/getEmployees`
			)
			.then((res) => {
				if(isMounted){
					setEmployees(res.data)
				}
			})
			.catch((err) => notify("error", err))
		return ()=>{
			isMounted=false;
		}
	},[])
	React.useEffect(() => {
		let isMounted=true;
		axios
			.get(
				`api/allWorkRequestHistory?page=${page}&rowsPerPage=${rowsPerPage}&findBy=${JSON.stringify(
					findBy
				)}`
			)
			.then((res) => {
				if(isMounted){
					setWorkDetails(res.data.result)
					setCount(res.data.count)
				}
			})
			.catch((err) => notify("error", err.response.data))
		return ()=>{
			isMounted=false;
		}
	}, [page, rowsPerPage, findBy, isLoaded])
	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}
	return (
		<div>
			<h4 style={{ color: "#ff6600" }}>Work History of Employees</h4>
			<TableContainer className={classes.tableContainer}>
				<div className="row">
					<div className="col"
						style={{ marginLeft: "1%", marginBottom: "1%", marginTop: "2%", textAlign: "center" }}					
					>
						<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
					</div>
					<div className="col">
						<label htmlFor="employeeId" style={{ color: "#ff6600" }}>Choose Subordinate</label>
						<select
							name="employeeId"
							className="custom-select form-control mb-2"
							id="employeeId"
							onChange={handleChangeFind}
						>
							<option value="">
								Employee Name. . .
							</option>
							{employees && employees.map((em) => (
								<option key={em.id} value={em.id}>
									{em.first_name+" "+em.last_name}
								</option>
							))}
						</select>
					</div>
					<div
						className="col"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="startDate" style={{ color: "#ff6600" }}>
							From
						</label>
						<input
							type="date"
							name="startDate"
							onChange={handleChangeFind}
							value={findBy.startDate}
						/>
					</div>
					<div
						className="col"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="endDate" style={{ color: "#ff6600" }}>
							To
						</label>
						<input
							type="date"
							name="endDate"
							onChange={handleChangeFind}
							value={findBy.endDate}
						/>
					</div>
				</div>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow >
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Employee Name</StyledTableCell>
							<StyledTableCell>Start Date</StyledTableCell>
							<StyledTableCell>Start Time</StyledTableCell>
							<StyledTableCell>EndDate</StyledTableCell>
							<StyledTableCell>End Time</StyledTableCell>
							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Leave Status</StyledTableCell>
							<StyledTableCell>Approvers</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{workDetails && workDetails.map((ld,key) => (
							<CollapseHistoryTable
								key={key}
								ld={ld}
								setWorkDetails={setWorkDetails}
								page={page}
								rowsPerPage={rowsPerPage}
								setCount={setCount}
								showHour={showHour}
								isLoaded={isLoaded}
								setIsLoaded={setIsLoaded}
							/>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={1} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[25, 50, 100]}
								colSpan={10}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	)
}
