import React, { useState, useEffect } from "react"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import axios from "../utils/axios"
import { notify } from "../utils/toast"
import { useHistory } from "react-router"
import { Card,Chip } from "@material-ui/core"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { Formik } from "formik"
import * as Yup from "yup"
import { useSelector } from "react-redux"
import Spinner from "../Spinner/Spinner"
import TipTap from "../TipTap/TipTap"

export default function ExtraWorkApplyForm() {
    const [workDetails, setWorkDetails] = useState({
	})
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	var approvers = []
	const [content, setContent] = useState("");

	const supervisors = useSelector((store) => store.authReducer.supervisors)
	useEffect(()=>{
		supervisors?.map((e)=>{
			approvers.push(e.employee_id);
		})
		setWorkDetails({...workDetails, ['approvers']: approvers})
	},[supervisors])

	return (
		<Container style={{ background: "#fafafa", border: "1px solid #dee2e6", padding: "2%" }} data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<div className="leaveApplyFormHeader">
				<Card style={{ background: "#ff6600", height: "50px" }}>
					<h5 style={{ color: "white", marginTop: "1%", textAlign: "center" }}>
						<ListAltIcon style={{ fontSize: "2.2rem" }} /> Extra Work Application
						Form
					</h5>
				</Card>
			</div>

			<Formik
				initialValues={{
					startDate: "",
					endDate: "",
					startTime: "",
					endTime: "",
					workDescription: "",
					workMode: "",
					periodOfDay: "",
				}}
				validationSchema={Yup.object({
					startDate: Yup.date().required("Required"),
					// workDescription: Yup.string().required("Required"),
					workMode: Yup.string().required("Required"),
					periodOfDay: Yup.string().when("workMode", {
						is: "halfDay",
						then: Yup.string().required("Required"),
					}),
					endDate: Yup.date().when("workMode", {
						is: (workMode) => workMode === "fullDay",
						then: Yup.date().required("Required"),
					}),
				})}
				onSubmit={(values, { setSubmitting }) => {
					var formData = new FormData()
					for (let key in values) {
						if (values["workMode"] === "halfDay") {
							if (values["periodOfDay"] === "firstHalf") {
								values["startTime"] = "09:00"
								values["endTime"] = "13:00"
							}
							if (values["periodOfDay"] === "secondHalf") {
								values["startTime"] = "14:00"
								values["endTime"] = "18:00"
							}
							values["endDate"] = values.startDate
						}
						if (values["workMode"] === "fullDay") {
							values["startTime"] = "09:00"
                            values["endTime"] = "18:00"
							values["periodOfDay"] = ""
						}
						formData.append(key, values[key])
					}
					formData.append("workDescription", content)
					for (let key in workDetails) {
						formData.append(key, workDetails[key])
					}
					
					const routePath = "/dashboard/work/myExtraWorks"
					setIsLoading(true)
					axios
						.post("api/work/request", formData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						})
						.then((response) => {
							let mails = response.data;
							mails = mails.map((e)=> {
								let data = {
									type : "WorkRequest"
								};
								let req = axios.post("api/mail/send/"+e,data)
								return req;
							})
						
							setIsLoading(false)
				
							// for mail
							Promise
							.all(mails)
							.then((responses) => {
								notify("success","Emails sent succesfully")
							})
							.catch(errors => {
								notify("error","could not send mail due to some errors.")
							})

							history.push(routePath)
						})

						.catch((err) => {
							notify("error", err.response.data)
							setIsLoading(false)
						})
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) =>
					isLoading === true ? (
						<Spinner />
					) : (
						<form onSubmit={handleSubmit}>
							<div className="row my-4">
								<div className="col">
									<label htmlFor="workMode">Work Mode:</label>
									<input
										type="radio"
										id="halfDay"
										name="workMode"
										value="halfDay"
										checked={values.workMode === "halfDay"}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<label htmlFor="halfDay">Half Day</label>

									<input
										type="radio"
										id="fullDay"
										name="workMode"
										value="fullDay"
										checked={values.workMode === "fullDay"}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<label htmlFor="fullDay">Full Day</label>
                                    <p className="text-danger">
										{errors.workMode && touched.workMode && errors.workMode}
									</p>
								</div>
								{values.workMode === "halfDay" && (
									<div className="col">
										<label htmlFor="periodOfDay">Period of Day:</label>
										<input
											type="radio"
											name="periodOfDay"
											value="firstHalf"
                                            id="firstHalf"
											checked={values.periodOfDay === "firstHalf"}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label htmlFor="firstHalf">First Half</label>
										<input
											type="radio"
											name="periodOfDay"
											value="secondHalf"
											checked={values.periodOfDay === "secondHalf"}
                                            id="secondHalf"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label htmlFor="secondHalf">Second Half</label>
                                        <p className="text-danger">
											{errors.periodOfDay &&
												touched.periodOfDay &&
												errors.periodOfDay}
										</p>
									</div>
								)}
							</div>
							<hr/>
							<div className="row">
								<div className="row">
									<div className="col-6">
										<label>{values.workMode === "halfDay" ? "Work Date" : "Start Date"}</label>
										<input
											type="date"
											className="form-control"
											name="startDate"
											id="startDate"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.startDate}
										/>
										<p className="text-danger">
											{errors.startDate &&
												touched.startDate &&
												errors.startDate}
										</p>
									</div>
								</div>

								<div className="row">
									{values.workMode !== "halfDay" && (
										<div className="col-6">
											<label>End Date</label>
											<input
												type="date"
												className="form-control"
												name="endDate"
												id="endDate"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.endDate}
												// min={new Date().toJSON().slice(0,10).replace(/-/g,'-')}
											/>
											<p className="text-danger">
												{errors.endDate &&
													touched.endDate &&
													errors.endDate}
											</p>
										</div>
									)}
								</div>
							</div>
							<hr/>
							<br />
							<div className="row">
                                    <div className="col-6">
                                        <p>Supervisors</p>
                                        <div className="d-flex justify-content-start gap-3">
                                        {
                                            supervisors?.map((element,key)=>(
                                            <Chip label={element.first_name + " " + element.last_name} style={{
                                                color: "#ffffff",
                                                backgroundColor: "#ff6600"}}/>
                                            ))
                                        }
                                        
                                    </div>
                                    <br />
                                    <p style={{color:"#888888"}}>
                                    (This work request must be approved by all the supervisors)
                                    </p>
                                </div>
								<div className="col">
									<label>Work Description</label>
									<br />
									{/* <textarea
										name="workDescription"
										id="workDescription"
										cols="40"
										rows="3"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.workDescription}
									>				
									</textarea> */}
									<TipTap content={content} setContent={setContent} editable = {true} />
									<p className="text-danger">
										{errors.workDescription &&
											touched.workDescription &&
											errors.workDescription}
									</p>
								</div>
							</div>
							<hr/>
							<div className="row">
								<Button
									type="submit"
									style={{ width: "20%", color: "white",marginLeft:"10px" }}
									variant="contained"
									color="primary"
                                    
								>
									Apply
								</Button>
							</div>
						</form>
					)
				}
			</Formik>
		</Container>
	)
}
