import React, { useState } from "react";
import "./RequestForm.css";
import { Person, Email, Create } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import InventoryPreview from "../../Allocation/InventoryPreview";
import ButtonLoader from "../../ButtonLoader/ButtonLoader";
import { notify } from "../../utils/toast";
export default function RequestForm() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const inventories = useSelector((store) =>
    store.authReducer.user.allocations
      .filter((a) => a.isCurrentAllocation && !a.inventory.inRepair)
      .map((a) => a.inventory)
  );

  return (
    <>
      <div className="main container bg-light mt-5" data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
        <div className="row">
          <div
            className="card-header text-white text-center mx-2 my-2"
            style={{ background: "#ff6600" }}
          >
            <Create />
            Create Request
          </div>

          <Formik
            initialValues={{
              subject: "",
              message: "",
              type: "",
              isUrgent: false,
              inventoryId: "",
            }}
            validationSchema={Yup.object({
              subject: Yup.string()
                .min(5, "Must be 5 characters or more")
                .required("Required"),
              message: Yup.string()
                .min(20, "Must be 10 characters or more")
                .required("Required"),
              type: Yup.string().required("Required"),
              isUrgent: Yup.boolean().required("Required"),
              inventoryId: Yup.string().when("type", {
                is: "inventory",
                then: (schema) => schema,
                otherwise: Yup.string().required("Required"),
              }),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setLoading(true);
              axios
                .post("/api/requests", JSON.stringify(values))
                .then((res) => {
                  setLoading(false);
                  history.push("/dashboard/requests")
                })
                .catch(err => {
                  setLoading(false);
                  notify('error','Something went wrong !');
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="container mx-2 my-2">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group my-2">
                        <label>
                          <Person />
                          Subject
                        </label>
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder="Enter subject here"
                          name="subject"
                          value={values.subject}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></textarea>
                        <p className="text-danger">
                          {errors.subject && touched.subject && errors.subject}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group my-2">
                        <label>
                          <Email />
                          Message
                        </label>
                        <textarea
                          rows={5}
                          className="form-control"
                          placeholder="Enter message here"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></textarea>
                        <p className="text-danger">
                          {errors.message && touched.message && errors.message}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="my-2">
                      <label style={{display: "block"}}>
                        What do you want to request for ?
                      </label>
                    <select
                      className="form-control"
                      style={{width: "300px"}}
                      id="inputGroupSelect01"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Please choose an option...</option>
                      <option value="inventory">New inventory</option>
                      <option value="repair">Repair</option>
                      <option value="upgrade">Upgrade</option>
                    </select>
                  </div>
                  <p className="text-danger">
                    {errors.type && touched.type && errors.type}
                  </p>
                  {(values.type !== "inventory") && <div className="input-group my-2 d-block">
                    <div>
                      <label>
                        Choose your inventory
                      </label>
                      <select
                        className="form-control"
                        style={{width: "300px"}}
                        name="inventoryId"
                        value={values.inventoryId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option selected value="">
                          Please choose an option...
                        </option>
                        {inventories.map((inventory) => (
                          <option value={inventory.inventoryId}>
                            {inventory.name}({inventory.inventoryId})
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-4">
                      <InventoryPreview
                        inventories={inventories}
                        inventoryId={values.inventoryId}
                      />
                    </div>
                  </div>}
                  <p className="text-danger">
                    {errors.inventoryId &&
                      touched.inventoryId &&
                      errors.inventoryId}
                  </p>
                  <div className="form-group d-flex align-items-center">
                    <label className="form-check-label me-5">
                      Is your request urgent ?
                    </label>
                    <input
                      className="form-check-input border border-dark text-dark"
                      type="checkbox"
                      name="isUrgent"
                      checked={values.isUrgent}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group my-3">
                    <Button type="submit" variant="outlined" color="primary">
                      {
                        loading ? <ButtonLoader color="orange" /> : "Submit Request"
                      }
                    </Button>
                
                    <Button variant="outlined" color="secondary" onClick={()=>{
                      history.goBack()
                    }}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
