import React from 'react'

function SettlementChip({settleDays}) {
  return (
    <span style={{
        fontSize:"10px",
        backgroundColor:"#1363DF", 
        height:"15px", 
        lineHeight:"13px", 
        padding:"3px",
        color:"white",
        // border:"1px solid black",
        width:"70%",
        borderRadius:"3px"
    }}>
        <strong>Settled With CL: {settleDays} days</strong>
    </span>
  )
}

export default SettlementChip