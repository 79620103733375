const INITIAl_STATE = {
  users: [],
  user: null,
  userInventories: [],
  isLoading: true,
  errors: {},
};

export default function userReducer(state = INITIAl_STATE, action) {
  switch (action.type) {
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        users: action.payload,
        user: null,
        isLoading: false,
      };
    case "GET_USER_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        errors: {},
      };
    case "GET_USER_SUPERVISORS_SUCCESS":
      return {
        ...state,
        supervisors: action.payload,
        isLoading: false,
        errors: {},
      };
    case "GET_USER_INVENTORIES_SUCCESS":
      return {
        ...state,
        userInventories: action.payload,
        isLoading: false,
        errors: {},
      };
    case "GET_USERS_ERROR":
    case "GET_USER_ERROR":
    case "GET_USER_INVENTORIES_ERROR":
    case "ADD_USER_ERROR":
      return {
        ...state,
        errors: action.payload,
        user: null,
        users: [],
        isLoading: false,
      };
    case "UPDATE_USER_ERROR":
      return {
        ...state,
        errors: action.payload,
        users: [],
        isLoading: false,
      };
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        users: [...state.users, action.payload],
        isLoading: false,
        errors: {},
      };
    case "REMOVE_USER_SUCCESS":
    case "REVIVE_USER_SUCCESS":
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            user.hasLeft = action.payload.hasLeft;
            user.leftAt = action.payload.leftAt;
          }
          return user;
        }),
      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            user = action.payload;
          }
          return user;
        }),
        user: action.payload,
        errors: {},
      };
    case "REMOVE_USER_INVENTORY":
      return {
        ...state,
        userInventories: state.userInventories.filter(
          (ui) => ui.inventoryId !== action.payload
        ),
        errors: {},
      };
    default:
      return { ...state };
  }
}
