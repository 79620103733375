import React from "react";
import { Col, Row } from "react-bootstrap";

const UserPreview = ({ users, employeeId }) => {
  const selectedUser = users.find((inv) => inv.employeeId === employeeId);
  const imagePath = selectedUser?.userInfo?.ppImagePath;
  const avatarImageUrl =
    selectedUser && selectedUser.gender === "female"
      ? "/femaleAvatar.png"
      : "/maleAvatar.png";
  return (
    <>
      {selectedUser && (
        <Row className="mt-4 p-1">
          <Col>
            <img
              width="140px"
              alt="inventory"
              src={
                imagePath
                  ? process.env.REACT_APP_API_URL + imagePath
                  : avatarImageUrl
              }
            />
          </Col>
          <Col>
            <span className="h6 p-0">
              Designation:{" "}
              <label style={{ color: "#5E5E5E" }}>
                {selectedUser.designation}
              </label>
            </span>
            <br />
            <span className="h6 p-0">
              Department:{" "}
              <label style={{ color: "#5E5E5E" }}>
                {selectedUser.department}
              </label>
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UserPreview;
