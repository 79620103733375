import React from "react";
import "./Login.css";

const Zoho = () => {
  return (
    <div className="text-center mt-2">
      <a
        className="m-3 text-align-center"
        href={`https://accounts.zoho.com/oauth/v2/auth?client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&response_type=token&scope=zohoprofile.userinfo.read&redirect_uri=${window.location.origin}/oauth/`}
      >
        <label className="navlink">Login with: </label>
        <img src="./zohologo" width="80px" alt="logo" />
      </a>
    </div>
  );
};

export default Zoho;