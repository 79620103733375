import React from "react"
import { Button, CircularProgress} from "@material-ui/core"
import { Modal } from "@material-ui/core"
import { Box } from "@material-ui/core"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import axios from "../../utils/axios"
import { notify } from "../../utils/toast"
import TipTap from "../../TipTap/TipTap"

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "400",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default function RejectWorkModal({
	id,
	setIsLoaded,
	isLoaded
}) {
	const [opens, setOpens] = React.useState(false)
	const [message, setMessage] = React.useState()
	const [process, setProcess] = React.useState(false)
	const handleOpen = () => {
		setOpens(true)
	}
	const handleClose = () => {
		setOpens(false)
	}

	// const handleChange = (e) => {
	// 	setMessage({ [e.target.name]: e.target.value })
	// }

	const handleSubmit = (id) => {
		if (message) {
			setProcess(true)
			axios
				.put(
					`api/workRequestReject/${id}`,
					{"declineMessage": message}
				)
				.then((response) => {
					setIsLoaded(!isLoaded)
					setProcess(false)
					setOpens(false)
					notify("success",response.data)
				})
				.catch((error) => console.log(error))
		} else {
			notify("error", "empty message cannot be posted")
		}
	}

	return (
		<>
			
			<div onClick={handleOpen}>
				Reject
			</div>
			<Modal open={opens} onClose={handleClose}>
				<Box sx={style}>
					<h5 style={{ color: "#ff5722", marginBottom: "10%" }}>
						Reject Message
					</h5>
					{/* <TextareaAutosize
						aria-label="minimum height"
						minRows={3}
						style={{ width: 300 }}
						name="declineMessage"
						value={message}
						onChange={handleChange}
					/> */}
					<TipTap setContent={setMessage} editable={true} />
					<br />
					<Button
						variant="outlined"
						style={{
							color: "#ff0000",
							borderColor: "#ff0000",
							marginTop: "10%",
							width: "50%",
						}}
						onClick={() => handleSubmit(id)}
					>
						{process? <CircularProgress/> :"Reject"}
					</Button>
				</Box>
			</Modal>
		</>
	)
}
