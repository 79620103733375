import axios from "../../utils/axios";
import { notify } from "../../utils/toast";
import { fetchUserInventories } from "./userActions";

export const getInventoryAllocations = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/api/admin/inventories/${id}/allocations`
    );
    const allocations = response.data.result;
    dispatch({
      type: "GET_INVENTORY_ALLOCATIONS_SUCCESS",
      payload: allocations,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "GET_INVENTORY_ALLOCATIONS_ERROR",
      payload: error.reponse,
    });
  }
};

export const createAllocation = (allocations, setLoading) => async (dispatch) => {
  try {
    const data = JSON.stringify(allocations);
    const response = await axios.post(`/api/admin/allocations`, data);
    if (response.data.previousAllocation) {
      dispatch({
        type: "UPDATE_PREVIOUS_ALLOCATION_SUCCESS",
        payload: response.data.previousAllocation,
      });
    }
    if (response.data.newAllocation) {
      dispatch({
        type: "CREATE_INVENTORY_ALLOCATION_SUCCESS",
        payload: response.data.newAllocation,
      });
      dispatch({
        type: "UPDATE_INVENTORY_STATUS",
        payload: response.data.newAllocation.isCurrentAllocation,
      });
    }
    dispatch(fetchUserInventories(response.data.employeeId));
    notify("success", "Successfully allocated!");
  } catch (error) {
    dispatch({
      type: "CREATE_INVENTORY_ALLOCATIONS_FAILURE",
      payload: error.response,
    });
  }
  setLoading(false);
};

export const unallocation = (id) => async (dispatch) => {
  try {
    const response = await axios.put(
      `/api/admin/inventories/${id}/allocations/unallocate`
    );
    dispatch({
      type: "INVENTORY_UNALLOCATION_SUCCESS",
      payload: response.data.allocation,
    });
    dispatch({
      type: "UPDATE_INVENTORY_STATUS",
      payload: response.data.allocation.inventory.isCurrentAllocation,
    });
    dispatch({
      type: "REMOVE_USER_INVENTORY",
      payload: response.data.inventoryId,
    });
    notify("success", "Successfully unallocated!");
  } catch (error) {
    dispatch({
      type: "INVENTORY_UNALLOCATION_FAILURE",
      payload: error.response,
    });
  }
};
