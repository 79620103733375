import moment from 'moment';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom';
import { logoutUser } from '../Redux/actions/authActions';
import Spinner from '../Spinner/Spinner'
import { notify } from '../utils/toast';
import routes from './Routes';

const hasPermission = (path,permissions)=>{
    var selectedData = routes[routes.map(function (item) { return item.path; }).indexOf(path)];
    if(!selectedData){
        return false;
    }
    if(selectedData.permissions.length===0){
        return true;
    }
    if(permissions && selectedData.permissions && (selectedData.permissions.filter(perm => permissions.includes(perm))).length > 0){
        return true;
    }else{
        return false;
    }
}
const CommonRoute = ({component:Component,path, ...rest}) => {
    const auth = useSelector(store => store.authReducer);
    const { isLoading, isAuthenticated } = auth;
    const dispatch = useDispatch()

    const isTokenExpired = moment(localStorage.getItem('token_expires_at')).diff(moment()) < 0
    const isPermitted = hasPermission(path,auth.permissions);
    if(isLoading){
        return <Spinner />
    } 
    if(isTokenExpired && isAuthenticated){
        return dispatch(logoutUser());
    }
    if(!isAuthenticated){
        return <Redirect to="/login" />
    }
    if(isPermitted){
        return <Route component={Component} path={path} {...rest} />
    }
    else{
        notify("error", "Entered URL doesnt exist!");
        return <Redirect to="/dashboard" />
    } 
}

export default CommonRoute;
