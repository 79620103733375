import React from "react"
import AdminCollapsePanel from "../AdminCollapsePanel/AdminCollapsePanel"
import Collapse from "@material-ui/core/Collapse"
import {
	TableRow,
	IconButton,
	Chip,
	TableCell,
	Tooltip,
	withStyles,
} from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import { makeStyles } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import EditIcon from "@material-ui/icons/Edit";
import axios from "../../../utils/axios"
import ApproverAvatar from "../../../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../../../utils/dateTimeFormat"
import { useHistory, useLocation } from "react-router-dom"
import { notify } from "../../../utils/toast"
const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
})
const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapseMyLeaveDetails({
	ld,
	setLeaveDetails,
	page,
	rowsPerPage,
	setCount,
	findBy,
	showHour,
}) {
	const [open, setOpen] = React.useState()
	React.useEffect(() => {
		setOpen(false)
	}, [page])

	const classes = useRowStyles()
	const DOL = dateFormat(ld.date_of_leave)
	const DOA = dateFormat(ld.date_of_arrival)
	const LT = ld.leave_time
	const AT = ld.arrival_time
	const history = useHistory()
	const path = useLocation()
	const handleClick = () => {
		setOpen(!open)
	}

	const handleCancel = async (id, page, rowsPerPage) => {
		const responseData = await axios
			.delete(
				`api/leaveRequest/cancel/${id}?page=${page}&rowsPerPage=${rowsPerPage}&findBy=${JSON.stringify(
					findBy
				)}`
			)
			.then((res) => {
				return res.data
			})
			.catch((err) => 
			notify('error','Something went wrong !')
			)
		setLeaveDetails(responseData.result)
		setCount(responseData.count)
	}
	const handleClickEdit = (id) => {
		history.push(path.pathname + `/edit/${id}`)
	}

	return (
		<>
			<TableRow className={classes.root}>
				<StyledTableCell>
					<IconButton onClick={() => handleClick()}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>{ld.leave_type_name}</StyledTableCell>
				<StyledTableCell>{DOL}</StyledTableCell>
				<StyledTableCell>{LT}</StyledTableCell>
				<StyledTableCell>{DOA}</StyledTableCell>
				<StyledTableCell>{AT}</StyledTableCell>
				<StyledTableCell>
					{showHour === true ? ld.durationHour : ld.durationDay}
				</StyledTableCell>
				<StyledTableCell>
					<Chip
						label={ld.name}
						style={{
							backgroundColor:
								(ld.name === "Pending" && "#1769aa") ||
								(ld.name === "Rejected" && "red") ||
								(ld.name === "Approved" && "green"),
							color: "white",
						}}
					/>
				</StyledTableCell>
				<StyledTableCell>
					{ld.approvers.map((ad) => (
						<ApproverAvatar ad={ad} declineMessage={ld.decline_message} />
					))}
				</StyledTableCell>
				<StyledTableCell>
					{ld.name === "Pending" && (
						<>
							<IconButton
								style={{ color: "#ba000d" }}
								onClick={() => handleCancel(ld.id, page, rowsPerPage)}
							>
								<Tooltip title="Cancel">
									<CancelIcon />
								</Tooltip>
							</IconButton>
							<IconButton onClick={() => handleClickEdit(ld.id)}>
								<Tooltip title="Edit Request">
									<EditIcon style={{color: "#ff6600"}}/>
								</Tooltip>
							</IconButton>
						</>
					)}
				</StyledTableCell>
			</TableRow>
			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={11}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<AdminCollapsePanel
							leaveDescription={ld.leave_description}
							leaveDoc={ld.leave_documents}
							status={ld.name}
							declineMessage={ld.decline_message}
							approvers={ld.first_name + " " + ld.last_name}
							requestId={ld.id}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
