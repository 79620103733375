import React from "react";
import "./pageNotFound.css";
import { Link } from "react-router-dom";

export default function PageNotFound() {
	return (
		<>
			<div id="notfound">
				<div className="notfound">
					<div className="notfound-404">
						<h1>Oops!</h1>
						<h2>404 - The Page can't be found</h2>
					</div>
					<Link to="/login" className="nav-link d-flex text-light a">
						Go To Homepage
					</Link>
				</div>
			</div>
		</>
	);
}
