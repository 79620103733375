import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { returnFromServicing } from "../Redux/actions/servicingAction";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
}));

export default function ReturnServicingModal({ sentAt, children }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        {children}
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h4
              id="transition-modal-title"
              className="text-center"
              style={{ color: "#ff6600" }}
            >
              Return details
            </h4>
            <p id="transition-modal-description">
              <Formik
                initialValues={{ cost: "", remarks: "", returnedAt: "" }}
                validationSchema={Yup.object({
                  cost: Yup.number().positive().integer().required("Required"),
                  remarks: Yup.string().required("Required"),
                  returnedAt: Yup.date()
                    .required("Required")
                    .max(new Date(), "Date cannot be future")
                    .min(
                      new Date(sentAt),
                      "Returned date cannot be before Sent date"
                    ),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setLoading(true);
                  dispatch(returnFromServicing(params.id, values, setLoading));
                  handleClose();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Servicing cost(Nrs)</Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        placeholder="Enter cost"
                        name="cost"
                        value={values.cost}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className="text-danger">
                        {errors.cost && touched.cost && errors.cost}
                      </p>
                    </Form.Group>

                    <Form.Group className="my-2">
                      <Form.Label>Return date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Enter returned date"
                        name="returnedAt"
                        value={values.returnedAt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className="text-danger">
                        {errors.returnedAt &&
                          touched.returnedAt &&
                          errors.returnedAt}
                      </p>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Remarks here"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className="text-danger">
                        {errors.remarks && touched.remarks && errors.remarks}
                      </p>
                    </Form.Group>

                    <Button
                      color="primary"
                      variant="outlined"
                      type="submit"
                      disableElevation
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
