import React from "react"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import EditIcon from "@material-ui/icons/Edit"
import { IconButton, withStyles, FormControlLabel, Checkbox, makeStyles } from "@material-ui/core"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import axios from "../../utils/axios"
import { notify } from "../../utils/toast"


const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles(() => ({
	label: {
		fontSize: "15px",
	},
}))

export default function LeaveTypeEdit({ lt, setValue }) {
	const [editInput, setEditInput] = React.useState(false)
	const [leaveTypes, setLeaveTypes] = React.useState({
		leaveTypeTotalDays: lt.leaveTypeTotalDays,
		is_payable: lt.isPayable,
		is_yearly_renewed: lt.isYearlyRenewed
	})
	const handleClickEdit = () => {
		setEditInput(true)
	}
	const handleChange = (e) => {
		setLeaveTypes({ ...leaveTypes, [e.target.name]: e.target.value })
	}
	const handleSubmitEdit = (id) => {
		setEditInput(false)

		axios
			.put(`api/leaveTypes/edit/${id}`, leaveTypes)
			.then((res) => setValue(res.data))
			.catch((err) => notify("error", "you should change value to edit"))
	}
	const classes = useStyles()
	return (
		<>
			{!editInput && (
				<TableRow key={lt.id}>
					<StyledTableCell>{lt.leaveTypeName}</StyledTableCell>
					<StyledTableCell>{lt.leaveTypeTotalDays}</StyledTableCell>
					<StyledTableCell>
						<IconButton onClick={handleClickEdit}>
							<EditIcon color="primary" />
						</IconButton>
					</StyledTableCell>
				</TableRow>
			)}
			{editInput && (
				<TableRow key={lt.id}>
					<StyledTableCell>{lt.leaveTypeName}</StyledTableCell>
					<StyledTableCell>
						<input
							type="number"
							name="leaveTypeTotalDays"
							value={leaveTypes.leaveTypeTotalDays}
							onChange={handleChange}
							style={{ width: "10%" }}
						/>&nbsp;&nbsp;&nbsp;
						<FormControlLabel
									classes={{ label: classes.label }}
									control={
										<Checkbox
											onChange={() =>
												setLeaveTypes({
													...leaveTypes,
													is_payable: !leaveTypes.is_payable,
												})
											}
										/>
									}
									label="Is Payable"
									checked={leaveTypes.is_payable === true ? true : false}
								/>	
								&nbsp;&nbsp;
						<FormControlLabel
									classes={{ label: classes.label }}
									control={
										<Checkbox
											onChange={() =>
												setLeaveTypes({
													...leaveTypes,
													is_yearly_renewed: !leaveTypes.is_yearly_renewed,
												})
											}
										/>
									}
									label="Is Yearly Renewed"
									checked = {leaveTypes.is_yearly_renewed === true ? true : false}
								/>
					</StyledTableCell>
					{console.log(lt)}
					<StyledTableCell>
						<IconButton onClick={() => handleSubmitEdit(lt.id)}>
							<DoneOutlineIcon color="primary" />
						</IconButton>
					</StyledTableCell>
				</TableRow>
			)}
		</>
	)
}
