import React from "react"
import { Button, CircularProgress, IconButton, Tooltip } from "@material-ui/core"
import { Modal } from "@material-ui/core"
import { Box } from "@material-ui/core"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import axios from "../../utils/axios"
import { notify } from "../../utils/toast"

import ThumbDownIcon from "@material-ui/icons/ThumbDown"

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "400",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default function RejectLeaveModal({
	isLoading,
	setIsLoading,
	id
}) {
	const [opens, setOpens] = React.useState(false)
	const [message, setMessage] = React.useState({
		declineMessage: "",
	})
	const [process, setProcess] = React.useState(false)
	const handleOpen = () => {
		setOpens(true)
	}
	const handleClose = () => {
		setOpens(false)
	}

	const handleChange = (e) => {
		setMessage({ [e.target.name]: e.target.value })
	}

	const handleSubmit = (id) => {
		if (message.declineMessage) {
			setProcess(true)
			axios
				.put(
					`api/leaveReject/${id}`,
					message
				)
				.then((response) => {
					setProcess(false)
					setOpens(false)
					notify("success","notified applicant for leave request succesfully")
					setIsLoading(!isLoading)
				})
				.catch((error) => console.log(error))
			// console.log(setLeaveDetails ? "true" : "false")
		} else {
			notify("error", "empty message cannot be posted")
		}
	}

	return (
		<>
			<IconButton
				style={{
					marginLeft: "10%",
					color: "#ba000d",
				}}
				onClick={handleOpen}
			>
				<Tooltip title="Reject">
					<ThumbDownIcon />
				</Tooltip>
			</IconButton>
			<Modal open={opens} onClose={handleClose}>
				<Box sx={style}>
					<h5 style={{ color: "#ff5722", marginBottom: "10%" }}>
						Reject Message
					</h5>
					<TextareaAutosize
						aria-label="minimum height"
						minRows={3}
						style={{ width: 300 }}
						name="declineMessage"
						value={message.declineMessage}
						onChange={handleChange}
					/>
					<br />
					<Button
						variant="outlined"
						style={{
							color: "#1769aa",
							borderColor: "#1769aa",
							marginTop: "10%",
							marginLeft: "50%",
							width: "50%",
						}}
						onClick={() => handleSubmit(id)}
					>
						{process? <CircularProgress/> :"Post"}
					</Button>
				</Box>
			</Modal>
		</>
	)
}
