import { Avatar } from '@material-ui/core'
import React from 'react'
function CustomCard({title,src, date_of_leave, leave_mode, leave_type_name, period_of_day, diff_days,leave_count}) {
  return (
    <div className='container p-3 shadow-sm my-3 border border-2 rounded-3' style={diff_days !== 0 ? {color:"#888888"}:{color:"#000000"}}>
        <div className="row">
          <div className="col-2 avatar">
              <Avatar
                  alt={title}
                  src={src}
                  style={{
                    width:"5rem",
                    height:"5rem",
                    marginLeft:"10px"
                  }}
              />
          </div>
          <div className="col-2 d-flex align-items-center">
            <h5>{title}</h5>
          </div>
          <div className="col-8">            
              <div className='row'>
                <div className="col-4 d-flex flex-column">
                    <h6>{leave_type_name}</h6>
                    <h6>From: {date_of_leave}</h6>
                </div>
                <div className="col-4 d-flex flex-column">
                    <h6>{leave_mode === "fullDay"? "Full Day": "Half Day"} {leave_mode  === "fullDay" ? " " : (period_of_day==="firstHalf"? "(First Half)":"(Second Half)" )}</h6>
                    <h6>For: {leave_count} Days</h6>
                </div>
                <div className="col-4 d-flex align-items-center">
                  <h6>
                    {
                      diff_days !== 0 
                      ? 
                        (diff_days<0 ?
                          Math.abs(diff_days) + (Math.abs(diff_days) === 1 
                          ?
                          " Day" 
                          :
                          " Days") + " ago"  
                        : 
                        (diff_days) + (Math.abs(diff_days) === 1 
                        ? 
                          " Day" : " Days") +  " to go") 
                      : 
                        "On leave today"
                    } 
                  </h6>
                </div>
              </div>
          </div>
        </div>
    </div>
  )
}

export default CustomCard