import {
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	makeStyles,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

import React from "react"
import CollapseHistoryTable from "./CollapsibleTable/CollapseHistoryTable"
import TableFooter from "@material-ui/core/TableFooter"
import TablePaginationActions from "../../TablePaginationActions/TablePaginationActions"
import TablePagination from "@material-ui/core/TablePagination"
import axios from "../../utils/axios"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"
import { notify } from "../../utils/toast"
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 900,
	},
	tableContainer: {
		widh: "100%",
	},
})

export default function LeaveHistoryTable() {
	const classes = useStyles()
	const [page, setPage] = React.useState(0)
	const [allLeaveData, setAllLeaveData] = React.useState()
	const [rowsPerPage, setRowsPerPage] = React.useState(5)
	const [count, setCount] = React.useState()
	const [loggedInId, setLoggedInId] = React.useState()
	const [findBy, setFindBy] = React.useState({
		startDate: "",
		endDate: "",
	})
	var currDate = new Date()
	var currMonth = currDate.getMonth()
	const [showHour, setShowHour] = React.useState(false)
	const handleChangeFind = (e) => {
		setFindBy({ ...findBy, [e.target.name]: e.target.value })
	}
	React.useEffect(()=>{
		setFindBy({
			...findBy, 
			["startDate"]: parseInt(currMonth < 6 ? (new Date().getFullYear() -1) : (new Date().getFullYear()))  + "-07-01",
			["endDate"]: parseInt(currMonth < 6 ? (new Date().getFullYear()) : (new Date().getFullYear()+1)) + "-06-30",
		})
	},[])
	React.useEffect(() => {
		let isMounted=true;
		axios
			.get(
				`api/allLeave/noPendingRequests?page=${page}&rowsPerPage=${rowsPerPage}&findBy=${JSON.stringify(
					findBy
				)}`
			)
			.then((res) => {
				if(isMounted){
					setAllLeaveData(res.data.result)
					setCount(res.data.count)
					setLoggedInId(res.data.useId)
				}
			})
			.catch((err) => notify("error",err.response.data))
		return ()=>{
			isMounted=false;
		}
	}, [page, rowsPerPage, findBy])
	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}
	return (
		<div>
			<h4 style={{ color: "#ff6600" }}>Leave History of Employees</h4>
			<TableContainer className={classes.tableContainer}>
				<div className="row">
					<div className="col"
						style={{ marginLeft: "1%", marginBottom: "1%", marginTop: "2%", textAlign: "center" }}					
					>
						<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
					</div>
					<div
						className="col"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="startDate" style={{ color: "#ff6600" }}>
							From
						</label>
						<input
							type="date"
							name="startDate"
							onChange={handleChangeFind}
							value={findBy.startDate}
						/>
					</div>
					<div
						className="col"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="endDate" style={{ color: "#ff6600" }}>
							To
						</label>
						<input
							type="date"
							name="endDate"
							onChange={handleChangeFind}
							value={findBy.endDate}
						/>
					</div>
				</div>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Employee`s Name</StyledTableCell>
							<StyledTableCell>Leave Type</StyledTableCell>
							<StyledTableCell>From</StyledTableCell>
							<StyledTableCell>Leave Time</StyledTableCell>
							<StyledTableCell>To</StyledTableCell>
							<StyledTableCell>Arrival Time</StyledTableCell>
							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Leave Status</StyledTableCell>
							<StyledTableCell>Approvers</StyledTableCell>
							<StyledTableCell>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{allLeaveData &&
							allLeaveData.map(
								(data,key) =>
									data.name !== "Pending" && (
										<CollapseHistoryTable
											key={key}
											data={data}
											setAllLeaveData={setAllLeaveData}
											setCount={setCount}
											setLoggedId={setLoggedInId}
											page={page}
											rowsPerPage={rowsPerPage}
											findBy={findBy}
											showHour={showHour}
										/>
									)
							)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={1} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								colSpan={10}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	)
}
