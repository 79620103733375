import React, { useEffect, useState } from "react"
import axios from "../../utils/axios"
import { withStyles, makeStyles } from "@material-ui/styles"
import {
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	TableFooter,
	TablePagination,
	Button,
} from "@material-ui/core"
import CollapseMyLeaveDetails from "./CollapsibleTable/CollapseMyLeaveDetails"
import TablePaginationActions from "../../TablePaginationActions/TablePaginationActions"
import { useHistory } from "react-router-dom"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"
import { notify } from "../../utils/toast"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 900,
		padding: "10px",
	},
	tableContainer: {
		width: "100%",
	},
})

export default function MyLeaveDetailsTable() {
	const [leaveDetails, setLeaveDetails] = useState([])
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(5)
	const [count, setCount] = React.useState()
	const [findBy, setFindBy] = React.useState({
		startDate: parseInt(currMonth < 6 ? (new Date().getFullYear() -1) : (new Date().getFullYear()))  + "-07-01",
		endDate: parseInt(currMonth < 6 ? (new Date().getFullYear()) : (new Date().getFullYear()+1)) + "-06-30",
	})
	var currDate = new Date()
	var currMonth = currDate.getMonth()
	const [showHour, setShowHour] = React.useState(false)
	const classes = useStyles()
	const history = useHistory()

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}
	const handleChangeFind = (e) => {
		setFindBy({ ...findBy, [e.target.name]: e.target.value })
	}

	useEffect(async () => {
		const responseData = await axios
			.get(
				`api/leave/requests?page=${page}&rowsPerPage=${rowsPerPage}&findBy=${JSON.stringify(
					findBy
				)}`
			)
			.then((response) => {
				return response.data
			})
			.catch((error) => notify('error','Something went wrong !'))

		setLeaveDetails(responseData.result)
		setCount(responseData.count)
	}, [page, rowsPerPage, findBy])
	return (
		<div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<div>
				<h4
					style={{ color: "#ff6600", marginTop: "0.7%", textAlign: "center" }}
				>
				Leave Details
				</h4>
			</div>

			<TableContainer className={classes.tableContainer}>
				<div className="row">
					<div className="col-2">
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								history.push("/dashboard/applyLeave")
							}
							style={{
								color: "white",
								marginBottom: "1%",
								marginTop: "15%",
								marginLeft: "5%",
							}}
						>
							Apply Leave
						</Button>
					</div>
					<div
						className="col-4"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="startDate" style={{ color: "#ff6600" }}>
							From
						</label>
						<input
							type="date"
							name="startDate"
							onChange={handleChangeFind}
							value={findBy.startDate}
						/>
					</div>
					<div
						className="col-4"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="endDate" style={{ color: "#ff6600" }}>
							To
						</label>
						<input
							type="date"
							name="endDate"
							onChange={handleChangeFind}
							value={findBy.endDate}
						/>
					</div>
					<div
						style={{
							marginBottom: "1%",
							marginTop: "2%",
							marginLeft: "4%",
							textAlign: "center",
						}}
						className="col"
					>
						<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
					</div>
				</div>

				<Table className={classes.table} aria-label="customized-table">
					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Leave Type</StyledTableCell>
							<StyledTableCell>From</StyledTableCell>
							<StyledTableCell>Leave Time</StyledTableCell>
							<StyledTableCell>To</StyledTableCell>
							<StyledTableCell>Arrival Time</StyledTableCell>
							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Leave Status</StyledTableCell>
							<StyledTableCell>Approvers</StyledTableCell>
							<StyledTableCell>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{leaveDetails?.map((ld) => (
							<CollapseMyLeaveDetails
								ld={ld}
								setLeaveDetails={setLeaveDetails}
								page={page}
								rowsPerPage={rowsPerPage}
								setCount={setCount}
								findBy={findBy}
								showHour={showHour}
							/>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={1} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								colSpan={9}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	)
}
