import React, { useEffect, useState } from "react"
import {
	PersonAdd,
	Person,
	Email,
	Work,
	Business,
	Home,
	Event,
	AccountBalance,
	AddIcCall,
	AccessibilityNew,
	Phone,
	CardMembership,
} from "@material-ui/icons"
import { useDispatch, useSelector } from "react-redux"
import { addUser } from "../../Redux/actions/userActions"
import { useHistory } from "react-router"
import { Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import ButtonLoader from "../../ButtonLoader/ButtonLoader"
import MultipleSelectInput from './MultipleSelectInput/MultipleSelectInput'
import axios from '../../utils/axios';
import { notify } from "../../utils/toast"

export default function AddUser() {
	const [loading, setLoading] = useState(false);
	const [supervisorOption, setSupervisorOption] = useState([]);
	const [roles,setRoles] = useState([])

	//user form state
	const [user, setUser] = useState({
		firstName: "",
		lastName: "",
		email: "",
		designation: "Engineer",
		roles: "ROLE_USER",
		joinedAt: "",
		probationEndAt: "",
		employeeId: "",
		dateOfBirth: "",
		citizenshipNumber: "",
		panNumber: "",
		gender: "male",
		phoneNumber: "",
		temporaryAddress: "",
		permanentAddress: "",
		department: "IT",
		relationName: "",
		relation: "",
		relationPhoneNumber: "",
		notes: "",
		accountNumber: "",
		supervisors: []
	})
	const dispatch = useDispatch()
	const history = useHistory()
	const errors = useSelector((store) => store.userReducer.errors)

	useEffect(()=>{
		axios
		.get("api/get/allApprovers")
		.then()
		.then((res)=>{
			setSupervisorOption(res.data);
		})
		// for roles option
		axios.get('api/get/role')
        .then((res)=>{
            setRoles(res.data);
        })
        .catch((err)=>{
            notify("error","Something went wrong")
        })
	},[])

	function handleChange(e) {
		const { name, value } = e.target
		setUser({ ...user, [name]: value })
	}

	function handleSubmit(e) {
		e.preventDefault()
		setLoading(true);
		dispatch(addUser(user, history, setLoading))
	}

	return (
		<>
			<div className="border container mb-5" data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
				<div
					className="card-header text-white text-center mx-2 my-2"
					style={{ backgroundColor: "#ff6600" }}
				>
					<PersonAdd /> Add New User
				</div>
				<form onSubmit={handleSubmit} noValidate>
					<div className="container mx-2 my-2">
						<div className="row justify-content-center align-items-start">
							<div className="mt-2">
								<h4>Basic Information</h4>
								<hr />
							</div>
							<div className="col-lg-5">
								<div className="form-group my-2">
									<label>
										<Person />
										First Name<small className="text-danger">*</small>
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="First Name"
										name="firstName"
										value={user.firstName}
										onChange={handleChange}
									></input>
									{errors.firstName && (
										<small className="text-danger">{errors.firstName}</small>
									)}
								</div>
							</div>
							<div className="col-lg-5">
								<div className="form-group my-2">
									<label>
										<Person />
										Last Name<small className="text-danger">*</small>
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Last Name"
										name="lastName"
										value={user.lastName}
										onChange={handleChange}
									></input>
									{errors.lastName && (
										<small className="text-danger">{errors.lastName}</small>
									)}
								</div>
							</div>
							<div className="col-lg-2 p-4">
								<div className="row">
									<label>
										<Person />
										Gender<small className="text-danger">*</small>
									</label>
									<div className="col-6">
										<input
											className="form-check-input"
											type="radio"
											name="gender"
											value="male"
											onChange={handleChange}
											checked={user.gender === "male"}
										/>
										<label className="form-check-label">Male</label>
									</div>
									<div className="col-6">
										<input
											className="form-check-input"
											type="radio"
											name="gender"
											value="female"
											onChange={handleChange}
											checked={user.gender === "female"}
										/>
										<label className="form-check-label">Female</label>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Email />
										Email address<small className="text-danger">*</small>
									</label>
									<input
										type="email"
										className="form-control"
										placeholder="name@example.com"
										name="email"
										value={user.email}
										onChange={handleChange}
									></input>
									{errors.email && (
										<small className="text-danger">{errors.email}</small>
									)}
								</div>
							</div>

							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Person />
										Date of birth(AD)<small className="text-danger">*</small>
									</label>
									<input
										type="date"
										className="form-control"
										name="dateOfBirth"
										value={user.dateOfBirth}
										onChange={handleChange}
									/>
									{errors.dateOfBirth && (
										<small className="text-danger">{errors.dateOfBirth}</small>
									)}
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Phone />
										Phone Number<small className="text-danger">*</small>
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Phone Number"
										name="phoneNumber"
										value={user.phoneNumber}
										onChange={handleChange}
									></input>
									{errors.phoneNumber && (
										<small className="text-danger">{errors.phoneNumber}</small>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="mt-2">
								<h4>Correspondence Address</h4>
								<hr />
							</div>

							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Home />
										Permanent Address
									</label>
									<textarea
										className="form-control"
										col-lg="10"
										rows="3"
										name="permanentAddress"
										value={user.permanentAddress}
										onChange={handleChange}
									></textarea>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Home />
										Current Address
									</label>
									<textarea
										className="form-control"
										col-lg="10"
										rows="3"
										name="temporaryAddress"
										value={user.temporaryAddress}
										onChange={handleChange}
									></textarea>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="mt-2">
								<h4>Official Details</h4>
								<hr />
							</div>

							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<CardMembership />
										Citizenship Number
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Citizenship Number"
										name="citizenshipNumber"
										value={user.citizenshipNumber}
										onChange={handleChange}
										min={0}
									></input>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<CardMembership />
										PAN
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="PAN Number"
										name="panNumber"
										value={user.panNumber}
										onChange={handleChange}
										min={0}
									></input>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<AccountBalance />
										Bank Account Number
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Bank A/C Number"
										name="accountNumber"
										value={user.accountNumber}
										min={0}
										onChange={handleChange}
									></input>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Person />
										Employee ID<small className="text-danger">*</small>
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Employee ID"
										name="employeeId"
										value={user.employeeId}
										onChange={handleChange}
									></input>
									{errors.employeeId && (
										<small className="text-danger">{errors.employeeId}</small>
									)}
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2 ">
									<label htmlFor="inputState">
										<Work />
										Designation<small className="text-danger">*</small>
									</label>
									<select
										className="form-control"
										id="inputState"
										name="designation"
										value={user.designation}
										onChange={handleChange}
									>
										<option value="Engineer">Engineer</option>
										<option value="CEO">CEO</option>
										<option value="CTO">CTO</option>
										<option value="HR">HR</option>
										<option value="Intern">Intern</option>
										<option value="Accountant">Accountant</option>
										<option value="Employee">Employee</option>
									</select>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2 ">
									<label htmlFor="inputState">
										<Business />
										Department<small className="text-danger">*</small>
									</label>
									<select
										className="form-control"
										id="inputState"
										name="department"
										value={user.department}
										onChange={handleChange}
									>
										<option>IT</option>
										<option>Admin</option>
										<option>HR</option>
										<option>Engineering</option>
										<option>Operation</option>
									</select>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Event />
										Joined At<small className="text-danger">*</small>
									</label>
									<input
										type="date"
										className="form-control"
										placeholder="Date"
										name="joinedAt"
										value={user.joinedAt}
										onChange={handleChange}
									></input>
									{errors.joinedAt && (
										<small className="text-danger">{errors.joinedAt}</small>
									)}
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<Event />
										Probation End At
									</label>
									<input
										type="date"
										name="probationEndAt"
										className="form-control"
										value={user.probationEndAt}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="mt-2">
								<h4>Emergency Details</h4>
								<hr />
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<AccessibilityNew />
										Emergency Contact Name
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Full Name"
										name="relationName"
										value={user.relationName}
										onChange={handleChange}
									></input>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<AccessibilityNew />
										Emergency Contact Relation
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Relation"
										name="relation"
										value={user.relation}
										onChange={handleChange}
									></input>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2">
									<label>
										<AddIcCall />
										Emergency Contact Number
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Phone number"
										name="relationPhoneNumber"
										pattern="[0-9]{3}[0-9]{10}"
										value={user.relationPhoneNumber}
										onChange={handleChange}
									></input>
									<small>Format: 9774567890648</small>
								</div>
							</div>
						</div>
						<div className="mt-4 row">
							<h4>Authority</h4>
							<hr />
							<div className="form-group my-2 col-lg-6">
								<label>
									Role<small className="text-danger">*</small>
								</label>
								<select
									className="form-control"
									type="select"
									name="roles"
									value={user.roles}
									onChange={handleChange}
								>
									{/* <option value="ROLE_USER">User</option>
									<option value="ROLE_ADMIN">Admin</option> */}
									{
										roles.map((role)=>
											<option value={role.name}>{role.name}</option>
										)
									}
								</select>
							</div>
							<div className="form-group my-2 col-lg-6">
								<MultipleSelectInput
									supervisorDetails={user}
									setSupervisorDetails={setUser}
									supervisorOption={supervisorOption}
								/>
							</div>
							
						</div>
						<div className="form-group my-2">
							<label>Notes:</label>
							<textarea
								className="form-control"
								col-lg="10"
								rows="3"
								name="notes"
								value={user.notes}
								onChange={handleChange}
							></textarea>
						</div>
						<div className="form-group my-4">
							<Button type="submit" variant="outlined" color="primary">
								{ loading ? <ButtonLoader color="orange"/> : "Add"}
							</Button>
							<Link
								to="/dashboard/users"
								style={{ textDecoration: "none", margin: "8px" }}
							>
								<Button color="secondary" variant="outlined">
									Cancel
								</Button>
							</Link>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
