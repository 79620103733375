import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getInventoryServicings } from "../Redux/actions/servicingAction";
import ReturnServicingModal from "./ReturnServicingModal";
import SendServicingModal from "./SendServicingModal";

const ServicingActions = () => {
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(getInventoryServicings(params.id));
  }, [dispatch, params.id]);
  const inventoryServicings = useSelector(
    (store) => store.servicingReducer.inventoryServicings
  );
  const permissions = useSelector((store)=>store.authReducer.permissions)
  const currentServicing =
    inventoryServicings &&
    inventoryServicings.find((is) => is.isCurrentServicing === true);
  return ( permissions?.includes("send_servicing") ?
    <>
      {
        currentServicing &&
        currentServicing.isSent &&
        !currentServicing.isCompleted ? (
          <ReturnServicingModal sentAt={currentServicing.sentAt}>Return from servicing <i className="fas fa-arrow-alt-circle-left ms-2"></i></ReturnServicingModal>
        ) : (
          <SendServicingModal>Send to servicing <i className="fas fa-share-square ms-2"></i></SendServicingModal>
        )
      }
    </>:<></>
  );
};

export default ServicingActions;
