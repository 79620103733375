import React from "react"
import { logoutUser } from "../Redux/actions/authActions"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import HowToRegIcon from "@material-ui/icons/HowToReg"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import "./NavLinks.css"
import Avatar from "@material-ui/core/Avatar"
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
const NavLinks = () => {
	const me = useSelector((store) => store.authReducer)
	const { isAuthenticated } = me
	const firstName = me.user?.firstName
	const designation = me.user?.designation
	const employeeId = me.user?.employeeId
	const ppImagePath = me.user?.userInfo.ppImagePath || null

	const dispatch = useDispatch()
	const location = useLocation()
	const path = location.pathname
	function handleLogout() {
		dispatch(logoutUser())
	}
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
		return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
		event.preventDefault();
		setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		let isMounted=true;
		if(isMounted){
			if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
			}
		}

		prevOpen.current = open;
		return ()=>{
			isMounted=false;
		}
	}, [open]);
	let token = me.token;
	let docsUsers = ["engineer", "ceo", "cto", "intern"];
	return (
		<div className="topRight">
			{isAuthenticated ? (
				<>
					{
						docsUsers.includes(designation?.toLowerCase()) && 
						<a className="nav-link" target="_blank" rel="noreferrer" href={`https://docs.wolfmatrix.dev?token=${token}`}> Docs </a>
					}
					<div className={classes.root}>
						<div>
							<Button
							ref={anchorRef}
							aria-controls={open ? 'menu-list-grow' : undefined}
							aria-haspopup="true"
							onClick={handleToggle}
							className="d-flex flex-row justify-content-around wrap"
							>
							<Avatar
								alt={firstName}
								src={process.env.REACT_APP_API_URL + ppImagePath}
								style={{ width: "30px", height: "30px" }}
							/> <h5 className="mx-3 my-2 d-none d-lg-inline" style={{color: "#ff6600"}}>{firstName}</h5>
							</Button>
							<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
							{({ TransitionProps, placement }) => (
								<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
								>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
									<MenuList  autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} >
										<MenuItem >
											<Link
												to={
													"/dashboard/users/" + employeeId
												}
												style={{ textDecoration: "none", cursor: "pointer"}}
												className="nav-link"
											>
											<AccountCircleIcon />
											<span className="mx-2" >My Profile</span>
											</Link>
										</MenuItem>
										<MenuItem >
											<Link
												to={
													"/dashboard/users/setting/" + employeeId
												}
												style={{ textDecoration: "none", cursor: "pointer"}}
												className="nav-link"
											>
											<SettingsIcon/>
											<span className="mx-2">Account Settings</span>
											</Link>
										</MenuItem>
										<MenuItem >
											<Link
												to="/login"
												onClick={handleLogout}
												className="text-danger nav-link"
											>
												<ExitToAppIcon/>
												<span className="mx-2">Logout</span>
											</Link>	
										</MenuItem>
									</MenuList>
									</ClickAwayListener>
								</Paper>
								</Grow>
							)}
							</Popper>
						</div>
					</div>
				</>
			) : (
				path !== "/login" && (
					<Link
						to="/login"
						className="text-primary"
						style={{ textDecoration: "none", color: "red" }}
					>
						<HowToRegIcon style={{ color: "green", marginBottom: "2px" }} />
						<span style={{ color: "green", marginLeft: "2px" }}>Login</span>
					</Link>
				)
			)}
		</div>
	)
}

export default NavLinks
