export function validateFile(file={}) {
  let error = {};
  const fileTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
  ];
  const maxSize = 2;
  if (!fileTypes.includes(file.type)) {
    error["type"] = "Invalid image format";
  }
  if (file.size / (1000 * 1000) > maxSize) {
    error["size"] = "Size cannot be greater than 2 mb";
  }
  return error;
}
