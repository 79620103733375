import React, { useEffect } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import PeopleAltIcon from "@material-ui/icons/PeopleAlt"
import { Timer } from "@material-ui/icons"
import DashboardIcon from "@material-ui/icons/Dashboard"
import { useHistory } from "react-router"
import NavLinks from "../NavLinks/NavLinks"
import ContactMailIcon from "@material-ui/icons/ContactMail"
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom"
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ListAltIcon from "@material-ui/icons/ListAlt"
import RestoreIcon from '@material-ui/icons/Restore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import WorkIcon from '@material-ui/icons/Work';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from "react-redux"
import './SideDrawer.css';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import UpdateIcon from '@material-ui/icons/Update';
import Spinner from "../Spinner/Spinner"
import { fetchCurrentUser } from "../Redux/actions/authActions"
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import InboxIcon from '@material-ui/icons/Inbox';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

const drawerWidth = 200

export default function SideDrawer(props) {
	const history = useHistory()
	const classes = useStyles()
	const theme = useTheme()
	const [open, setOpen] = React.useState(false)
	const permissions = useSelector((store) => store.authReducer.permissions)
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = React.useState(true)
	const [pathName,setPathName] = React.useState()

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		new Promise((resolve,reject)=>{
			dispatch(fetchCurrentUser(resolve, reject))
		})
		.then((res)=>{
			setIsLoading(false)
		})
		.catch((err)=>{
			console.log("Error: ", err)
		})
	}, [])

	useEffect(()=>{
		setPathName(history.location.pathname)
	},[history.location])

	const navigate = (path) =>{
		setPathName(path);
		history.push(path);
	}

	return (
		<>
		{isLoading?<Spinner />:(
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<div className="d-flex justify-content-between align-items-center w-100">
						<div>
							<div className="d-flex justify-content-start align-items-center">
								<div>
									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={handleDrawerOpen}
										edge="start"
										className={clsx(classes.menuButton, {
											[classes.hide]: open,
										})}
									>
										<MenuIcon color="primary" />
									</IconButton>
								</div>
								<div>
									<Typography
										variant="h5"
										noWrap
										color="primary"
										component={"span"}
										className="d-none d-sm-inline"
									>
										Office Management System
									</Typography>
									<Typography
										variant="h5"
										noWrap
										color="primary"
										component={"span"}
										className="d-sm-none"
									>
										OMS
									</Typography>
								</div>
							</div>
						</div>
						<div>
							<NavLinks />
						</div>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</div>
				<Divider />
				<List>
					<>
					<Tooltip title="Dashboard" placement="right" arrow disableHoverListener={ open? true : false} >
						<ListItem onClick={()=>navigate("/dashboard")}
							className={
								pathName === "/dashboard" ? classes.active: classes.link
							}
						>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText primary="Dashboard" />
						</ListItem>
					</Tooltip>
					{
						(permissions?.includes("view_user")) &&
						<Tooltip title="Users" placement="right" arrow disableHoverListener={ open? true : false} >
							<ListItem onClick={()=>navigate("/dashboard/users")}
								className={
									pathName === "/dashboard/users" ? classes.active :classes.link
								}
							>
								<ListItemIcon>
									<PeopleAltIcon />
								</ListItemIcon>
								<ListItemText primary="Users" />
							</ListItem>
						</Tooltip>
					}
					{
						(permissions?.includes("manage_permission")) &&
						<Tooltip title="Permissions" placement="right" arrow disableHoverListener={ open? true : false} >
							<ListItem onClick={()=>navigate("/dashboard/permission")}
								className={ 
									/\/dashboard\/permission/.test(pathName) ? classes.active : classes.link
								}
							>
								<ListItemIcon>
									<VerifiedUserIcon />
								</ListItemIcon>
								<ListItemText primary="Permissions" />
							</ListItem>
						</Tooltip>
					}
					{
						(permissions?.includes("settle_leave")) &&
						<Tooltip title="Leave Settlement" placement="right" arrow disableHoverListener={ open? true : false} >
							<ListItem onClick={()=>navigate("/dashboard/ULsettlement")}
								className={
									/\/dashboard\/ULsettlement/.test(pathName) ? classes.active : classes.link
								}
							>
								<ListItemIcon>
									<AttachMoneyIcon/>
								</ListItemIcon>
								<ListItemText primary="Leave Settlement" />
							</ListItem>
						</Tooltip>
					}
				
						{/* accordian for Inventory */}
						
						<div className="accordion" id="accordionInventory">
							<div className="accordion-item">								
								<Tooltip title="Inventory" placement="right" arrow disableHoverListener={ open? true : false} >
									<h2 className="accordion-header" id="headingThree">
										<button className="accordion-button collapsed" style={{padding:0, paddingRight:"23px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
											<ListItem >
												<ListItemIcon>
													<i className="fas fa-boxes" />
												</ListItemIcon>
												<ListItemText primary="Inventory"/>
											</ListItem>
										</button>
									</h2>
								</Tooltip>
								<div id="collapseThree" className="accordion-collapse collapse " aria-labelledby="headingThree" data-bs-parent="#accordionInventory">
								<div className="accordion-body"  style={{padding:0,}}>
									<div className="d-flex flex-column">
										<Tooltip title="My Inventories" placement="right" arrow disableHoverListener={ open? true : false} >
											<ListItem onClick={()=>navigate("/dashboard/myInventories")}
												className={
													/\/dashboard\/myInventories/.test(pathName)
														? classes.active
														: classes.link
												}
											>
												<ListItemIcon style={{paddingLeft:"10px",}}>
													<InboxIcon />
												</ListItemIcon>
												<ListItemText primary="My Inventories" />
											</ListItem>
										</Tooltip>
										{
											(permissions?.includes("view_inventory")) &&
											<Tooltip title="Inventories" placement="right" arrow disableHoverListener={ open? true : false} >
												<ListItem onClick={()=>navigate("/dashboard/inventories")}
													className={
														/\/dashboard\/inventories/.test(pathName)
															? classes.active
															: classes.link
													}
												>
													<ListItemIcon style={{paddingLeft:"10px",}}>
														<i className="fas fa-boxes" style={{fontSize: "1.3rem"}} />
													</ListItemIcon>
													<ListItemText primary="Inventories" />
												</ListItem>
											</Tooltip>
										}
										{
											(permissions?.includes("request_inventory")) &&
											<Tooltip title="Requests" placement="right" arrow disableHoverListener={ open? true : false} >
												<ListItem onClick={()=>navigate("/dashboard/requests")}
													className={
														/\/dashboard\/requests/.test(pathName) ? classes.active : classes.link
													}
												>
													<ListItemIcon style={{paddingLeft:"10px",}}>
														<ContactMailIcon />
													</ListItemIcon>
													<ListItemText primary="Requests" />
												</ListItem>
											</Tooltip>
										}
										{
											(permissions?.includes("view_servicing")) &&
											<Tooltip title="Servicing" placement="right" arrow disableHoverListener={ open? true : false} >
												<ListItem onClick={()=>navigate("/dashboard/servicings")}
													className={
														/\/dashboard\/servicings/.test(pathName) ? classes.active : classes.link
													}
												>
													<ListItemIcon style={{paddingLeft:"10px",}}>
														<Timer />
													</ListItemIcon>
													<ListItemText primary="Servicing" />
												</ListItem>
											</Tooltip>	
										}								
									</div>
								</div>
								</div>
							</div>
						</div>

						{/* accordian for Leave */}

						<div className="accordion" id="accordionLeave">
							<div className="accordion-item">
								<Tooltip title="Leave" placement="right" arrow disableHoverListener={ open? true : false} >
									<h2 className="accordion-header" id="headingOne">
										<button className="accordion-button collapsed" style={{padding:0, paddingRight:"23px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
											<ListItem >
												<ListItemIcon>
													<MeetingRoomIcon />
												</ListItemIcon>
												<ListItemText primary="Leave"/>
											</ListItem>
										</button>
									</h2>
								</Tooltip>
								<div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionLeave">
								<div className="accordion-body"  style={{padding:0,}}>
									<div className="d-flex flex-column">
										<Tooltip title="Employees Leave" placement="right" arrow disableHoverListener={ open? true : false} >
											<ListItem onClick={()=>navigate("/dashboard/leaveTab")}
												className={
													/\/dashboard\/leaveTab/.test(pathName)
														? classes.active
														: classes.link
												}
											>
												<ListItemIcon style={{paddingLeft:"10px",}}>
													<EmojiPeopleIcon />
													
												</ListItemIcon>
												<ListItemText  primary="Employees Leave" />
											</ListItem>
										</Tooltip>
										<Tooltip title="My Leave Details" placement="right" arrow disableHoverListener={ open? true : false} >
											<ListItem onClick={()=>navigate("/dashboard/myLeaveDetails")}
												className={
													/\/dashboard\/myLeaveDetails/.test(pathName)
														? classes.active
														: classes.link
												}
											>
												<ListItemIcon style={{paddingLeft:"10px",}}>
													<AssignmentIndIcon />
												</ListItemIcon>
												<ListItemText primary="My Leave Details" />
											</ListItem>
										</Tooltip>
										<Tooltip title="Apply Leave" placement="right" arrow disableHoverListener={ open? true : false} >
											<ListItem onClick={()=>navigate("/dashboard/applyLeave")}
												className={
													/\/dashboard\/applyLeave/.test(pathName)
														? classes.active
														: classes.link
												}
											>
												<ListItemIcon style={{paddingLeft:"10px",}}>
													<ListAltIcon />
												</ListItemIcon>
												<ListItemText primary="Apply Leave" />
											</ListItem>
										</Tooltip>
									</div>
								</div>
								</div>
							</div>
						</div>

						{/* accordian for Extra work */}

						{
							(['view_extra_work','assign_extra_work','request_extra_work','view_extra_work'].some((el) => permissions?.includes(el))) &&
							<div className="accordion" id="accordionWork">
								<div className="accordion-item">
									<Tooltip title="Work" placement="right" arrow disableHoverListener={ open? true : false} >
										<h2 className="accordion-header" id="headingTwo">
											<button className="accordion-button collapsed" style={{padding:0, paddingRight:"23px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<ListItem >
													<ListItemIcon>
														<WorkIcon />
													</ListItemIcon>
													<ListItemText primary="Work"/>
												</ListItem>
											</button>
										</h2>
									</Tooltip>
									<div id="collapseTwo" className="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionWork">
										<div className="accordion-body"  style={{padding:0,}}>
											<div className="d-flex flex-column">
												{
													(permissions?.includes("view_extra_work_request")) &&
													<Tooltip title="Work Requests" placement="right" arrow disableHoverListener={ open? true : false} >
														<ListItem onClick={()=>navigate("/dashboard/workTab")}
															className={
																/\/dashboard\/workTab/.test(pathName)
																	? classes.active
																	: classes.link
															}
														>
															<ListItemIcon style={{paddingLeft:"10px",}}>
																<RestoreIcon />
																
															</ListItemIcon>
															<ListItemText  primary="Work Requests" />
														</ListItem>
													</Tooltip>
												}
												{
													(permissions?.includes("assign_extra_work")) &&
													<Tooltip title="Assign Extra Work" placement="right" arrow disableHoverListener={ open? true : false} >
														<ListItem onClick={()=>navigate("/dashboard/assignExtraWork")}
															className={
																/\/dashboard\/assignExtraWork/.test(pathName)
																	? classes.active
																	: classes.link
															}
														>
															<ListItemIcon style={{paddingLeft:"10px",}}>
																<AssignmentIcon />
																
															</ListItemIcon>
															<ListItemText  primary="Assign Extra Work" />
														</ListItem>
													</Tooltip>
												}
												{
													(permissions?.includes("request_extra_work")) &&
													<Tooltip title="Request Extra Work" placement="right" arrow disableHoverListener={ open? true : false} >
														<ListItem onClick={()=>navigate("/dashboard/work/applyForExtraWork")}
															className={
																/dashboard\/work\/applyForExtraWork/.test(pathName)
																	? classes.active
																	: classes.link
															}
														>
															<ListItemIcon style={{paddingLeft:"10px",}}>
																<UpdateIcon />
																
															</ListItemIcon>
															<ListItemText  primary="Request Extra Work" />
														</ListItem>
													</Tooltip>
												}
												<Tooltip title="My Extra Works " placement="right" arrow disableHoverListener={ open? true : false} >
													<ListItem onClick={()=>navigate("/dashboard/work/myExtraWorks")}
														className={
															/\/dashboard\/work\/myExtraWorks/.test(pathName)
																? classes.active
																: classes.link
														}
													>
														<ListItemIcon style={{paddingLeft:"10px",}}>
															<VerticalSplitIcon />
															
														</ListItemIcon>
														<ListItemText  primary="My Extra Works" />
													</ListItem>
												</Tooltip>
											</div>
										</div>
									</div>
								</div>
							</div>

						}

						{/* accordian for work from home */}

						{
							(['view_work_from_home_history','request_work_from_home'].some((el) => permissions?.includes(el))) &&
							<div className="accordion" id="accordionWFH">
								<div className="accordion-item">
									<Tooltip title="Work From Home" placement="right" arrow disableHoverListener={ open? true : false} >
										<h2 className="accordion-header" id="headingFour">
											<button className="accordion-button collapsed" style={{padding:0, paddingRight:"23px"}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
												<ListItem >
													<ListItemIcon>
														<HomeWorkIcon />
													</ListItemIcon>
													<ListItemText primary="Work From Home"/>
												</ListItem>
											</button>
										</h2>
									</Tooltip>
									<div id="collapseFour" className="accordion-collapse collapse " aria-labelledby="headingFour" data-bs-parent="#accordionWFH">
										<div className="accordion-body"  style={{padding:0,}}>
											<div className="d-flex flex-column">
												<Tooltip title="Requests" placement="right" arrow disableHoverListener={ open? true : false} >
													<ListItem onClick={()=>navigate("/dashboard/wfhTab")}
														className={
															/\/dashboard\/wfhTab/.test(pathName)
																? classes.active
																: classes.link
														}
													>
														<ListItemIcon style={{paddingLeft:"10px",}}>
															<RestoreIcon />
														</ListItemIcon>
														<ListItemText  primary="Requests" />
													</ListItem>
												</Tooltip>
												{
													(permissions?.includes("request_work_from_home")) &&
													<Tooltip title="Apply for Work From Home" placement="right" arrow disableHoverListener={ open? true : false} >
														<ListItem onClick={()=>navigate("/dashboard/applyForWorkFromHome")}
															className={
																/\/dashboard\/applyForWorkFromHome/.test(pathName)
																	? classes.active
																	: classes.link
															}
														>
															<ListItemIcon style={{paddingLeft:"10px",}}>
																<ListAltIcon />
															</ListItemIcon>
															<ListItemText  primary="Apply" />
														</ListItem>
													</Tooltip>
												}
												<Tooltip title="My Work From Home Details" placement="right" arrow disableHoverListener={ open? true : false} >
													<ListItem onClick={()=>navigate("/dashboard/wfh/myWFH")}
														className={
															/\/dashboard\/wfh\/myWFH/.test(pathName)
																? classes.active
																: classes.link
														}
													>
														<ListItemIcon style={{paddingLeft:"10px",}}>
															<VerticalSplitIcon />
															
														</ListItemIcon>
														<ListItemText  primary="My WFH Details" />
													</ListItem>
												</Tooltip>
											</div>
										</div>
									</div>
								</div>
							</div>

						}
					</>
					
				</List>
			</Drawer>
			<main className={classes.content}>
				{props.children}
			</main>
		</div>
		)}
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		height: "73px",
		backgroundColor: "#ffff",
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
		"&:hover": {
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
	},
	active: {
		backgroundColor: "#ff6600",
		cursor: "pointer",
		color: "#ffffff"
	},
	link: {
		textDecoration: "none",
		color: "inherit",
		cursor: "pointer",
	},
	navLinkContainer: {
		marginRight: "15px",
	},
}))
