import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import axios from "../utils/axios"
import { notify } from "../utils/toast"
import { useHistory } from "react-router"
import { Card } from "@material-ui/core"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { Formik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { fetchCurrentUser } from "../Redux/actions/authActions"
import Spinner from "../Spinner/Spinner"
import TipTap from "../TipTap/TipTap"

export default function ExtraWorkAssignForm({employeeId, id}) {
	const [isLoading, setIsLoading] = useState(false)
	const [subordinate,setSubordinate] = useState([])
	const history = useHistory()
	const dispatch = useDispatch()
	const [content, setContent] = useState("");

	useEffect(() => {
		let isMounted=true;
		axios
		.get(`api/getSubordinate`)
		.then((response) => {
			if(isMounted){
				setSubordinate(response.data);
			}
		})
		.catch((error) => console.log(error))
		return ()=>{
			isMounted=false;
		}
	}, [dispatch,employeeId])
	return (
		<div className="container-fluid" style={{ background: "#fafafa", color: "#000000", border: "1px solid #dee2e6", padding: "2%" }} data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<div className="leaveApplyFormHeader">
				<Card style={{ background: "#ff6600", height: "50px" }}>
					<h5 style={{ color: "white", marginTop: "1%", textAlign: "center" }}>
						<ListAltIcon style={{ fontSize: "2.2rem" }} /> Assign Extra Work
					</h5>
				</Card>
			</div>

			<Formik
				initialValues={{
					startDate: "",
					endDate: "",
					startTime: "",
					endTime: "",
					subordinateId: "",
					workDescription: "",
					workMode: "",
					periodOfDay: "",
				}}
				validationSchema={Yup.object({
					startDate: Yup.date().required("Required"),
					subordinateId: Yup.string().required("Subordinate required"),
					// workDescription: Yup.string().required("Work description is required"),
					workMode: Yup.string().required("Required"),
					periodOfDay: Yup.string().when("workMode", {
						is: "halfDay",
						then: Yup.string().required("Required"),
					}),
					endDate: Yup.date().when("workMode", {
						is: (workMode) => workMode === "fullDay",
						then: Yup.date().required("Required"),
					})
				})}
				onSubmit={(values) => {
					console.log("Here");
					var formData = new FormData()
					for (var key in values) {
						if (values["workMode"] === "halfDay") {
							if (values["periodOfDay"] === "firstHalf") {
								values["startTime"] = "09:00"
								values["endTime"] = "13:00"
							}
							if (values["periodOfDay"] === "secondHalf") {
								values["startTime"] = "14:00"
								values["endTime"] = "18:00"
							}
							values["endDate"] = values.startDate
						}
						if (values["workMode"] === "fullDay") {
							values["startTime"] = "09:00"
							values["endTime"] = "18:00"
							values["periodOfDay"] = ""
						}
						formData.append(key, values[key])
					}
					formData.append("workDescription", content)
					for (var pair of formData.entries()) {
						console.log(pair[0] + ", " + pair[1])
					}
					const routePath = "/dashboard/workTab"
					setIsLoading(true);
					axios
						.post("/api/assignExtraWork", formData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						})
						.then((response) => {
							notify("success", "Successfully assigned extra work")
							history.push(routePath)
							if(response.data.mailError !== '')
							{
								notify("error", "Mail cant be sent due to invalid email or sender being not set")
							}
							setIsLoading(false);
						})

						.catch((err) => {
							console.log("Error at past leave apply form : ", err);
							notify("error", err.response.data)
							setIsLoading(false)
						})
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) =>
					isLoading === true ? (
						<Spinner />
					) : (
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-6">
									<label htmlFor="subordinateId">Choose Subordinate</label>
									<select
										name="subordinateId"
										className="custom-select form-control"
										id="subordinateId"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.subordinateId}
									>
										<option value="">
											Please Choose Subordinate . . .
										</option>
										{subordinate.map((so) => (
											<option key={so.id} value={so.id}>
												{so.first_name+" "+so.last_name}
											</option>
										))}
									</select>
									<p className="text-danger">
										{errors.subordinateID && touched.subordinateID && errors.subordinateID}
									</p>
								</div>
							</div>
							<hr/>
							<br />
							<div className="row">
								<div className="col">
									<label htmlFor="workMode">Work Mode:</label>
									<input
										type="radio"
										id="half_day"
										name="workMode"
										value="halfDay"
										checked={values.workMode === "halfDay"}
										onChange={handleChange}
										onBlur={handleBlur}
									/>

									<label htmlFor="half_day">Half Day</label>
									<input
										type="radio"
										id="full_day"
										name="workMode"
										value="fullDay"
										checked={values.workMode === "fullDay"}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<label htmlFor="full_day">Full Day</label>
									<p className="text-danger">
										{errors.workMode && touched.workMode && errors.workMode}
									</p>
								</div>
								{values.workMode === "halfDay" && (
									<div className="col">
										<label htmlFor="period_of_day">Period of Day:</label>
										<input
											type="radio"
											name="periodOfDay"
											value="firstHalf"
											checked={values.periodOfDay === "firstHalf"}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label htmlFor="first_half">First Half</label>
										<input
											type="radio"
											name="periodOfDay"
											value="secondHalf"
											checked={values.periodOfDay === "secondHalf"}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label htmlFor="second_half">Second Half</label>
										<p className="text-danger">
											{errors.periodOfDay &&
												touched.periodOfDay &&
												errors.periodOfDay}
										</p>
									</div>
								)}
							</div>
							<hr/>
							<br />
							<div className="row">
								<div className="row">
									<div className="col-6">
										<label>{values.workMode === "fullDay"?"Start Date":"Work Date" }</label>
										<input
											type="date"
											className="form-control"
											name="startDate"
											id="startDate"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.startDate}
											// min={new Date().toJSON().slice(0,10).replace(/-/g,'-')}
										/>
										<p className="text-danger">
											{errors.startDate &&
												touched.startDate &&
												errors.startDate}
										</p>
									</div>
								</div>

								<div className="row">
									{values.workMode !== "halfDay" && (
										<div className="col-6">
											<label>End Date</label>
											<input
												type="date"
												className="form-control"
												name="endDate"
												id="endDate"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.endDate}
												// min={new Date().toJSON().slice(0,10).replace(/-/g,'-')}
											/>
											<p className="text-danger">
												{errors.endDate &&
													touched.endDate &&
													errors.endDate}
											</p>
										</div>
									)}
								</div>
							</div>
							<hr/>
							<br />
							<div className="row">
								<div className="col-6">
									<label>Work Description</label>
									<br />
									{/* <textarea
										className="form-control"
										name="workDescription"
										id="workDescription"
										cols="40"
										rows="3"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.workDescription}
									/> */}
									<TipTap content={content} setContent={setContent} editable = {true} />
									<p className="text-danger">
										{errors.workDescription &&
											touched.workDescription &&
											errors.workDescription}
									</p>
								</div>
							</div>
							<hr/>
							<div className="row">
								<Button
									type="submit"
									style={{ width: "20%", color: "white" }}
									variant="contained"
									color="primary"
								>
									Assign Work
								</Button>
							</div>
						</form>
					)
				}
			</Formik>
		</div>
	)
}
