import React from "react"
import {
	TableCell,
	TableRow,
	withStyles,
} from "@material-ui/core"

import axios from "../../../utils/axios"
import { notify } from "../../../utils/toast"
import { IconButton } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { Collapse } from "@material-ui/core"
import AdminCollapsePanel from "../AdminCollapsePanel/AdminCollapsePanel"
import ApproverAvatar from "../../../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../../../utils/dateTimeFormat"
import ExtraWorkSplitButton from "../../../SplitButton/ExtraWorkSplitButton"
import ComplementaryChip from "../../../Common/ComplementaryChip/ComplementaryChip"

const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapseRequestsTable({
	data,
	page,
	setIsLoaded,
	isLoaded,
	showHour,
}) {
	// const [process,setProcess] = React.useState(false)
	const [open, setOpen] = React.useState(false)
	const DOL = dateFormat(data.start_date)
	const DOA = dateFormat(data.end_date)
	React.useEffect(() => {
		setOpen(false)
	}, [page])

	// const handleApprove = (id) => {
	// 	setProcess(true)
	// 	axios
	// 		.put(`api/workApprove/${id}`)
	// 		.then((response) => {
	// 			setProcess(false)
	// 			notify("success",response.data)
	// 			setIsLoaded(!isLoaded);
	// 		})
	// 		.catch((error) => {
	// 			notify("error", error.response.data)
	// 			setProcess(false)
	// 		})
	// }

	return (
		<>
			<TableRow key={data.id}>
				<StyledTableCell>
					<IconButton onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>
					{data.first_name + " " + data.last_name}
				</StyledTableCell>
				<StyledTableCell>{DOL}</StyledTableCell>
				<StyledTableCell>{data.start_time}</StyledTableCell>
				<StyledTableCell>{DOA}</StyledTableCell>
				<StyledTableCell>{data.end_time}</StyledTableCell>
				<StyledTableCell>
					{showHour === false ? data.durationDay : data.durationHour}
				</StyledTableCell>
				<StyledTableCell>
					{data.approvers.map((ad,key) => (
						<ApproverAvatar key={key} ad={ad} declineMessage={data.decline_message+(data.complementary_status==="Request Cancelled" && data.remark)} />
					))}
				</StyledTableCell>
				<StyledTableCell>
					<ComplementaryChip
						label={data.complementary_status}
					/>
				</StyledTableCell>
				<StyledTableCell>
					{/* {data.complementary_status === "Request Pending" && (
						!process ?
						<IconButton
							style={{ color: "green" }}
							onClick={() => handleApprove(data.id)}
						>
							<Tooltip title="Approve">
								<ThumbUpIcon />
							</Tooltip>
						</IconButton>
						:
						<CircularProgress	
						/>
					)}
					<RejectWorkModal
						id={data.id}
						setLoggedInId={setLoggedInId}
						setAllWorkData={setAllWorkData}
						setIsLoaded={setIsLoaded}
						isLoaded={isLoaded}
					/> */}
					{(data.complementary_status === "Request Pending" ||
					data.complementary_status === "Request Approved" ||
					data.complementary_status === "Request Rejected" ||
					data.complementary_status === "Request Cancelled")?
					<ExtraWorkSplitButton type="Request" id={data.id} isLoaded={isLoaded} setIsLoaded={setIsLoaded} /> :
					<ExtraWorkSplitButton type="Work" id={data.id} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />}
				</StyledTableCell>
			</TableRow>

			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={10}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<AdminCollapsePanel
							status={data.complementary_status}
							workDescription={data.work_description}
							requestId={data.id}
							remark={data.remark}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
