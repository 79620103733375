import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableFooter from "@material-ui/core/TableFooter"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/styles"
import { Button, makeStyles } from "@material-ui/core"
import TablePaginationActions from "../TablePaginationActions/TablePaginationActions"
import CollapsiableTable from "./CollapsiableTable"
import SwitchDayHour from "../SwitchDayHour/SwitchDayHour"
import { useHistory } from "react-router-dom"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 700,
		padding: "10px",
	},
	tableContainer: {
		width: "90%",
		margin: "auto",
	},
})

export default function WorkTable({
	workDetails,
	setWorkDetails,
	page,
	setPage,
	rowsPerPage,
	setRowsPerPage,
	count,
	setCount,
	isLoaded,
	setIsLoaded,
}) {
	const classes = useStyles()

	const [showHour, setShowHour] = React.useState(false)

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const history = useHistory();

	return (
		<div>
			
			<TableContainer className={classes.tableContainer} component={Paper}>
				<div className="row">
					<div className="col">
						{ <Button 
							variant="contained"
							color="primary"
							className="m-4"
							onClick={() =>
								history.push("/dashboard/work/applyForExtraWork")
							}
							style={{
								color: "white"
							}}
							>
								Request Extra Work
						</Button>}
					</div>
					<div className=" col">
						<h3 className="mt-4"  style={{
							color:"#ff6600",
						}}>My Work Details</h3>
					</div>
					<div
						className="col-2 text-center pt-4"
					>
						<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
					</div>
				</div>

				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow >
							<StyledTableCell></StyledTableCell>

							<StyledTableCell>Start Date</StyledTableCell>
							<StyledTableCell>Start Time</StyledTableCell>
							<StyledTableCell>EndDate</StyledTableCell>
							<StyledTableCell>End Time</StyledTableCell>
							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Leave Status</StyledTableCell>
							<StyledTableCell>Approvers</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{workDetails && workDetails.map((ld,key) => (
							<CollapsiableTable
								key={key}
								ld={ld}
								setWorkDetails={setWorkDetails}
								page={page}
								rowsPerPage={rowsPerPage}
								setCount={setCount}
								showHour={showHour}
								isLoaded={isLoaded}
								setIsLoaded={setIsLoaded}
							/>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={1} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[25, 50, 100]}
								colSpan={9}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	)
}
