import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css" //imported after bootstrap css for overriding
import "bootstrap/dist/js/bootstrap"
import store from "./Components/Redux/store"
import { Provider } from "react-redux"
import { ThemeProvider, createTheme } from "@material-ui/core"
import "react-toastify/dist/ReactToastify.css"

const theme = createTheme({
	typography: {
		button: {
			textTransform: "capitalize",
		},
		fontSize: 13,
		fontFamily: [
			"-apple-system",
			"ubuntu",
			'"Segoe UI"',
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
	palette: {
		primary: {
			main: "#ff6600",
		},
		secondary: {
			main: "#5E5E5E",
		},
	},
})

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
)
