import React from "react"
import { Box } from "@material-ui/core"

export default function AdminCollapsePanel({
	workFromHomeReason,
	status,
	declineMessage,
}) {
	return (
		<>
			<Box
				style={{
					boxShadow:"2px 2px 35px 1px gray",
					marginBottom:"10px", paddingBottom:"20px",
					color:
						(status === "Pending" && "#1769aa")||
						(status === "Approved" && "green") ||
						(status === "Rejected" && "#ba000d") ||
						(status === "Cancelled" && "#ba000d"),
				}}
			>
				<br />
				<h6
					style={{
						marginLeft: "1%",
						textAlign: "left",
					}}
				>
					Work From Home Reason:{" "}
				</h6>
				<p
					style={{
						marginLeft: "3%",
						textAlign: "left",
					}}
				>
					<i>{workFromHomeReason}</i>
				</p>

				{status==="Cancelled" && (
					<h6 style={{ marginLeft: "1%", textAlign: "left" }}>
						Cancelled
					</h6>
				)}
				{declineMessage && (
					<h6 style={{ marginLeft: "1%", textAlign: "left" }}>
						Decline Message
					</h6>
				)}
				{declineMessage && (
					<p style={{ marginLeft: "3%", textAlign: "left" }}>
						<i>{declineMessage}</i>
					</p>
				)}
			</Box>
		</>
	)
}
