import axios from "../../utils/axios";
import { notify } from "../../utils/toast";

export const getServicings = () => async (dispatch) => {
  try {
    const servicings = await axios.get("/api/admin/servicings");
    dispatch({
      type: "GET_SERVICINGS_SUCCESS",
      payload: servicings.data.result,
    });
  } catch (err) {
    dispatch({
      type: "GET_SERVICINGS_ERROR",
      payload: err.response.data.error,
    });
  }
};

export const getInventoryServicings = (id) => async (dispatch) => {
  try {
    const servicings = await axios.get(`/api/inventories/${id}/servicings`);
    dispatch({
      type: "GET_INVENTORY_SERVICINGS_SUCCESS",
      payload: servicings.data.result,
    });
  } catch (err) {
    dispatch({
      type: "GET_INVENTORY_SERVICINGS_ERROR",
      payload: err.response.data.error,
    });
  }
};

export const sendToServicing = (id, values, setLoading) => async (dispatch) => {
  try {
    const data = JSON.stringify(values);
    const servicing = await axios.post(
      `/api/admin/inventories/${id}/servicings`,
      data
    );
    dispatch({
      type: "UPDATE_INVENTORY_SERVICINGS_SUCCESS",
      payload: servicing.data.result,
    });
    notify("success", "success!");
  } catch (err) {
    dispatch({
      type: "UPDATE_INVENTORY_SERVICINGS_ERROR",
      payload: err.response.data.error,
    });
  }
  setLoading(false);
};

export const returnFromServicing = (id, servicing, setLoading) => async (dispatch) => {
  try {
    const data = JSON.stringify(servicing);
    const response = await axios.post(
      `/api/admin/inventories/${id}/servicings/return`,
      data
    );
    dispatch({
      type: "UPDATE_INVENTORY_SERVICINGS_SUCCESS",
      payload: response.data.servicing,
    });
    dispatch({
      type: "CREATE_INVENTORY_SERVICINGS_SUCCESS",
      payload: response.data.newServicing,
    });
    notify("success", "success!");
  } catch (err) {
    dispatch({
      type: "UPDATE_INVENTORY_SERVICINGS_ERROR",
      payload: err.response,
    });
  }
  setLoading(false);
};
