import { Chip } from '@material-ui/core'
import React from 'react'

export default function ComplementaryChip({label}) {
  return (
    <Chip
        label={label}
        style={{
            backgroundColor:
                (label === "Request Pending" && "#1DC0FA") ||
                (label === "Work In Review" && "#0F5E7A")||
                (label === "Request Approved" && "#00aa00")||
                (label === "Work Approved" && "#006600")||
                (label === "Request Rejected" && "#FF1C14") ||
                (label === "Request Cancelled" && "#FF6600")||
                (label === "Work Rejected" && "#B31510"),
            color: "white",
        }}
    />
  )
}
