import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"

import { withStyles } from "@material-ui/styles"
import {
	makeStyles,
	TableFooter,
	TablePagination,
} from "@material-ui/core"
import { Button } from "@material-ui/core"
import axios from "../../utils/axios"
import CollapseRequestsTable from "./CollapsibleTable/CollapseRequestsTable"
import TablePaginationActions from "../../TablePaginationActions/TablePaginationActions"
import { useHistory } from "react-router-dom"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"
import { notify } from "../../utils/toast"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 900,
	},
	tableContainer: {
		width: "100%",
	},
})

export default function AllLeaveRequestTable() {
	const classes = useStyles()
	const history = useHistory()
	const [page, setPage] = React.useState(0)
	const [allLeaveData, setAllLeaveData] = React.useState()
	const [rowsPerPage, setRowsPerPage] = React.useState(5)
	const [count, setCount] = React.useState()
	const [loggedInId, setLoggedInId] = React.useState()
	const [showHour, setShowHour] = React.useState(false)
	const [isLoading,setIsLoading] = React.useState(false)
	React.useEffect(() => {
		axios
			.get(
				`api/allLeave/pendingRequests?page=${page}&rowsPerPage=${rowsPerPage}`
			)
			.then((res) => {
				setAllLeaveData(res.data.result)
				setCount(res.data.count)
				setLoggedInId(res.data.userId)
			})
			.catch((err) => notify('error',err.response.data))
	}, [page, setPage, rowsPerPage, isLoading])

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<div>
			<div className="d-flex justify-content-between">
				<h4 style={{ color: "#ff6600" }}>Employee's Leave Requests</h4>
				<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
			</div>
			<TableContainer className={classes.tableContainer}>
				<Button
					variant="contained"
					color="primary"
					style={{
						float: "right",
						marginLeft: "1%",
						marginRight: "1%",
						marginBottom: "1%",
						color: "white",
					}}
					onClick={() => history.push("/dashboard/leaveTab/leaveTypes")}
				>
					Leave Types
				</Button>

				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Employee</StyledTableCell>
							<StyledTableCell>LeaveType</StyledTableCell>
							<StyledTableCell>From</StyledTableCell>
							<StyledTableCell>Leave Time</StyledTableCell>
							<StyledTableCell>To</StyledTableCell>
							<StyledTableCell>Arrival Time</StyledTableCell>
							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Approvers</StyledTableCell>
							<StyledTableCell>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{allLeaveData &&
							allLeaveData.map(
								(data,key) =>
									data.name === "Pending" && (
										<CollapseRequestsTable
											isLoading={isLoading}
											setIsLoading={setIsLoading}
											key={key}
											data={data}
											loggedInId={loggedInId}
											setCount={setCount}
											page={page}
											rowsPerPage={rowsPerPage}
											setLoggedInId={setLoggedInId}
											setAllLeaveData={setAllLeaveData}
											showHour={showHour}
										/>
									)
							)}

						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={1} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								colSpan={8}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	)
}
