import React from "react";
import "./topbar.css";
import { Link } from "react-router-dom";
import NavLinks from "../NavLinks/NavLinks";
import { Typography } from "@material-ui/core";

export default function Topbar() {
  return (
    <div className="topbar shadow mb-4 bg-white rounded px-2">
      <div className="topbarWrapper">
        <div className="topLeft">
          <Link className="logo" to="/">
            <Typography variant="h6" noWrap color="primary" className=" d-sm-none" component={"h6"}>
              OMS
            </Typography>
            <Typography variant="h6" noWrap color="primary" className="d-none d-sm-inline" component={"h6"}>
              Office Management System
            </Typography>
          </Link>
        </div>
        <NavLinks />
      </div>
    </div>
  );
}
