import React from "react"

import TableContainer from "@material-ui/core/TableContainer"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import { withStyles, makeStyles } from "@material-ui/styles"
import TableCell from "@material-ui/core/TableCell"
import { Button } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import axios from "../../utils/axios"
import LeaveTypeEdit from "./LeaveTypeEdit"
import AdminLeaveTypeModal from "../AddLeaveTypeModal/AddLeaveTypeModal"
import { notify } from "../../utils/toast"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)
const useStyles = makeStyles({
	table: {
		minWidth: 900,
	},
	tableContainer: {
		width: "100%",
		marginTop: "1%",
	},
})

export default function LeaveTypeTable() {
	const classes = useStyles()
	const history = useHistory()
	const [leaveTypes, setLeaveTypes] = React.useState([])

	React.useEffect(() => {
		axios
			.get("api/leaveTypes")
			.then((res) => setLeaveTypes(res.data))
			.catch((err) => 
			notify('error','Something went wrong !')
			)
	}, [])

	return (
		<>
			<Button
				variant="outlined"
				color="primary"
				style={{ width: "10%" }}
				onClick={() => {
					history.push("/dashboard/leaveTab")
				}}
			>
				Back
			</Button>
			<AdminLeaveTypeModal setLeaveTypes={setLeaveTypes}/>
			<br />
			<TableContainer className={classes.tableContainer}>
				<h4 style={{ color: "#ff6600" }}>Type of Leaves</h4>

				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>LeaveType Name</StyledTableCell>
							<StyledTableCell>Total Days per Year</StyledTableCell>
							<StyledTableCell>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{leaveTypes.map((lt) => (
							<LeaveTypeEdit lt={lt} setValue={setLeaveTypes} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
