import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Badge from "@material-ui/core/Badge"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"

const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: "#1769AA",
		color: "blue",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		width: 15,
		height: 15,
		borderRadius: 7.5,

		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}))(Badge)

export default function ApproverAvatar({ ad, declineMessage }) {
	return (
		<Tooltip title={ad.first_name + " " + ad.last_name}>
			{declineMessage ? (
				<Badge
					overlap="circular"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					badgeContent={<CancelIcon style={{ width: 20, height: 20 }} />}
					style={{ marginLeft: "5%", color: "red" }}
				>
					<Avatar
						alt={ad.first_name}
						src={process.env.REACT_APP_API_URL + ad.pp_image_path}
					/>
				</Badge>
			) : parseInt(ad.is_approved) === 1 ? (
				<Badge
					overlap="circular"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					badgeContent={<CheckCircleIcon style={{ width: 20, height: 20 }} />}
					style={{ marginLeft: "5%", color: "green" }}
				>
					<Avatar
						alt={ad.first_name}
						src={process.env.REACT_APP_API_URL + ad.pp_image_path}
					/>
				</Badge>
			) : (
				<StyledBadge
					overlap="circular"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					style={{ marginLeft: "5%" }}
					variant="dot"
				>
					<Avatar
						alt={ad.first_name}
						src={process.env.REACT_APP_API_URL + ad.pp_image_path}
					/>
				</StyledBadge>
			)}
		</Tooltip>
	)
}
