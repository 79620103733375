import React from "react";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  ArrowDownward,
  Search,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import { useHistory } from "react-router";
import axios from "../utils/axios";
import { Avatar, Chip } from "@material-ui/core";
import { notify } from "../utils/toast";
const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
};

const columns = [
  {
    title: null,
    field: "avatar",
    render: (rowData) => (
      <Avatar alt="user" src={rowData.avatar} variant="square" />
    ),
    filtering: false,
    width: "100",
    export: false,
  },
  {
    field: "inventory",
    title: "Inventory",
    width: 200,
    filtering: false,
  },
  {
    field: "inventoryId",
    title: "Inventory Id",
    width: 200,
    filtering: false,
  },
  {
    field: "servicingDate",
    title: "Servicing date",
    width: 200,
    filtering: false,
  },
  {
    field: "remainingDays",
    title: "Remaining days",
    width: 200,
    filtering: false,
    render: (rowData) => (
      <Chip
        key={rowData.remainingDays}
        label={rowData.remainingDays}
        style={{
          backgroundColor: rowData.remainingDays < 30 ? "red" : "green",
          fontWeight: "bold",
          color: "white",
          width: "90px",
          opacity: rowData.remainingDays < 30 ? "1" : "0.7",
        }}
      />
    ),
  },
  {
    field: "duration",
    title: "Servicing Interval (Month)",
    width: 200,
    filtering: false,
  },
];

export default function ServicingList() {
  const tableRef = React.createRef();
  const history = useHistory();
  const titleStyle = {
    color: "#ff6600",
    margin: "10px",
    padding: "2px",
  };

  return (
    <div data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        title={<h6 style={titleStyle}>Servicings list</h6>}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = "/api/admin/servicings?";
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            axios
              .get(url)
              .then((res) => {
                let servicings = res.data.result;
                let rows = servicings.map((serv) => {
                  const remainingDays = Math.round(
                    (new Date(serv.serviceAt) - new Date()) /
                      (1000 * 60 * 60 * 24)
                  );
                  return {
                    avatar: serv.inventory.imagePath
                      ? process.env.REACT_APP_API_URL + serv.inventory.imagePath
                      : null,
                    inventory: serv.inventory.name,
                    inventoryId: serv.inventory.inventoryId,
                    servicingDate: serv.serviceAt,
                    remainingDays: remainingDays,
                    duration: serv.durationInMonth,
                  };
                });
                resolve({
                  data: rows,
                  page: res.data.page - 1,
                  totalCount: res.data.totalCount,
                });
              })
              .catch((err) => notify('error','Something went wrong !')
              );
          })
        }
        options={{
          search: false,
          sorting: true,
          pageSize: 10,
          headerStyle: {
            backgroundColor: "#ff6600",
            color: "whitesmoke",
            fontWeight: "bold",
            lineHeight: "1rem",
          },
          rowStyle: {
            color: "#111111",
            fontSize: "0.94rem",
            fontFamily: "Poppins, sans-serif",
          },
        }}
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => {
              tableRef.current && tableRef.current.onQueryChange();
            },
          },
        ]}
        onRowClick={(event, rowData) => {
          history.push("/dashboard/inventories/" + rowData.inventoryId);
        }}
      />
    </div>
  );
}
