import React, {useState } from "react"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import AllLeaveRequestTable from "./AdminLeaveTables/AllLeaveRequestTable"
import LeaveHistoryTable from "./AdminLeaveTables/LeaveHistoryTable"
import CurrentLeaveTable from "./AdminLeaveTables/CurrentLeaveTable"
import AllLeaveStatistics from "./AllLeaveStatistics/AllLeaveStatistics"
import { useSelector } from "react-redux"
import UserSideLeaveRequest from "../UserSideLeaveRequest/UserSideLeaveRequest"

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{/* <Typography> */}
						<Paper>{children}</Paper>
					{/* </Typography> */}
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: "98%",
		marginLeft: "1%",
	},
}))

export function AdminLeaveTab() {
	const classes = useStyles()
	const theme = useTheme()
	const [value, setValue] = useState(0)
	const permissions = useSelector((store)=> store.authReducer.permissions);
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const handleChangeIndex = (index) => {
		setValue(index)
	}

	return (
		<div className={classes.root} data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
				>
					{
						(permissions?.includes("view_all_leaves") || permissions?.includes("HR_leave_approval"))?
						<Tab label="All Leave Requests" {...a11yProps(0)} /> //for only users
						:
						<Tab label="Leave Requests" {...a11yProps(0)} /> //for only with view_all_leaves permission
					}
					
					<Tab label="Today's Leave" {...a11yProps(1)} />
					{
						(permissions?.includes("view_leave_history")) &&
						<Tab label="Leave History" {...a11yProps(2)} />
					}
					{
						(permissions?.includes("view_leave_history")) &&
						<Tab label="Leave Statistics" {...a11yProps(3)} />
					}
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={value}
				onChangeIndex={handleChangeIndex}
			>
				{
					(permissions?.includes("view_all_leaves") || permissions?.includes("HR_leave_approval"))?
					<TabPanel value={value} index={0} dir={theme.direction}>
						<AllLeaveRequestTable />
					</TabPanel>:
					<TabPanel value={value} index={0} dir={theme.direction}>
						<UserSideLeaveRequest/>
					</TabPanel>
				}
				<TabPanel value={value} index={1} dir={theme.direction}>
					<CurrentLeaveTable />
				</TabPanel>
				{
					(permissions?.includes("view_leave_history")) &&
					<TabPanel value={value} index={2} dir={theme.direction}>
						<LeaveHistoryTable />
					</TabPanel>
				}
				{
					(permissions?.includes("view_leave_history")) &&
					<TabPanel value={value} index={3} dir={theme.direction}>
						<AllLeaveStatistics />
					</TabPanel>
				}
			</SwipeableViews>
		</div>
	)
}
