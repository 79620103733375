import styled from "styled-components"

export const PasswordInputWrapper = styled.div`
	div.input-wrapper {
		position: relative;
	}
	#toggler {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translate(0, -50%);
		cursor: pointer;

		&::after {
			transition: 0.3s all;
			position: absolute;
			width: 150px;
			bottom: -0%;
			left: -200%;
			color: #7f7f7f;
			opacity: 0;
		}
	}

	#toggler:hover::after {
		opacity: 1;
		bottom: -120%;
	}
	.icon {
		color: #ff5600;
	}
`
