import React, { useEffect, useState } from "react"
import { Card, Container, Button } from "@material-ui/core"
import { useHistory, useLocation } from "react-router"
import axios from "../../utils/axios"
import { useParams } from "react-router-dom"
import { notify } from "../../utils/toast"
export default function EditLeaveForm() {
	const { id } = useParams()
	const [leaveDetails, setLeaveDetails] = useState({
		arrivalTime: "",
		dateOfArrival: "",
		dateOfLeave: "",
		leaveDescription: "",
		leaveMode: "",
		leaveTime: "",
		leaveTypeName: "",
		leaveTypeNameII: "",
		periodOfDay: "",
		joinedAt: "",
		editMode: "",
		midDate: "",
	})

	const [leaveTypeOptions, setLeaveTypeOptions] = useState([])
	const [nonPaidLeaveOptions, setNonPaidLeaveOptions] = useState([])

	const [checkDecline, setCheckDecline] = useState(false)
	const [leaveStatus, setLeaveStatus] = useState()
	const [isPayable, setIsPayable] = useState()
	const [duration, setDuration] = useState(0)
	const history = useHistory()
	const path = useLocation()
	let pathName = path.pathname

	pathName = pathName.substring(0, pathName.indexOf("/edit"))
	useEffect(() => {
		axios
			.get(`api/leaveTab/edit/${id}`)
			.then((res) => {
				const processedDescription = res.data.data.leave_description.split("***")[0].trim();
				setLeaveDetails({
					arrivalTime: res.data.data.arrival_time,
					leaveTime: res.data.data.leave_time,
					dateOfArrival: res.data.data.date_of_arrival,
					dateOfLeave: res.data.data.date_of_leave,
					declineMessage: res.data.data.decline_message,
					leaveDescription: processedDescription,
					leaveMode: res.data.data.leave_mode,
					joinedAt: res.data.data.joined_at,
					probationEndAt: res.data.data.probation_end_at,
					leaveTypeName: res.data.data.leave_type_name,
					periodOfDay: res.data.data.period_of_day,
					userId: res.data.data.userId,
					editMode: "",
				})
				res.data.data.decline_message && setCheckDecline(true)
				setDuration(res.data.duration)
				setLeaveStatus(res.data.data.name)
				setIsPayable(res.data.data.is_payable)

				return axios.get(`api/leave/type?userId=${res.data.data.userId}`)
			})
			.then((res) => {
				var newArray = [];
				axios.post("api/leaveStat",res.data.data)
				.then((response) =>{
					let userLeaveType = response.data
					newArray = userLeaveType.filter(el => el.remainingDay > 0 && el.remainingHour > 0);

					if (Object.keys(newArray).length === 0) {
					  newArray = userLeaveType.filter(el => el.leaveTypeName == 'Unpaid Leave');
					}

					setLeaveTypeOptions(newArray)
				})

				return axios.get("api/getNonPaidLeave")
			})
			.then((res) => {
				setNonPaidLeaveOptions(res.data)
			})
			.catch((err) => notify("error", err.response.data))
	}, [])

	const handleChange = (e) => {
		setLeaveDetails({ ...leaveDetails, [e.target.name]: e.target.value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		for (var key in leaveDetails) {
			if (leaveDetails["leaveMode"] === "halfDay") {
				if (leaveDetails["periodOfDay"] === "firstHalf") {
					leaveDetails["leaveTime"] = "09:00"
					leaveDetails["arrivalTime"] = "13:00"
				}
				if (leaveDetails["periodOfDay"] === "secondHalf") {
					leaveDetails["leaveTime"] = "14:00"
					leaveDetails["arrivalTime"] = "18:00"
				}
				leaveDetails["dateOfArrival"] = leaveDetails.dateOfLeave
			}
			if (leaveDetails["leaveMode"] === "fullDay") {
				leaveDetails["leaveTime"] = "09:00"
				leaveDetails["arrivalTime"] = "18:00"
			}
			// if (leaveDetails["leaveMode"] === "custom") {
			// 	leaveDetails["periodOfDay"] = ""
			// }
			// if (leaveDetails["editMode"] === "") {
			// 	leaveDetails["editMode"] = "normalEdit"
			// }
			// if (leaveDetails["editMode"] === "normalEdit") {
			// 	delete leaveDetails["midDate"]
			// 	delete leaveDetails["leaveTypeNameII"]
			// }
		}

		axios
			.put(`api/edit/leaveRequest/${id}`, leaveDetails)
			.then((res) => {
				console.log(res.data)
				notify("success", "successfully Edited")
				history.push(pathName)
			})
			.catch((err) => notify("error", err.response.data))
	}

	return (
		<Container style={{ background: "#fafafa", border: "1px solid #dee2e6", padding: "2%", width: "100%" }}>
			<div className="leaveEditFormHeader">
				<Card style={{ background: "#ff6600", height: "50px" }}>
					<h5 style={{ color: "white", marginTop: "1%", textAlign: "center" }}>
						{/* <BorderColorIcon style={{ fontSize: "2.2rem" }} /> */}
						Edit Leave Details
					</h5>
				</Card>
			</div>
			<br />
			<form onSubmit={handleSubmit}>
				{/* {isPayable !== "0" && leaveStatus === "Approved" && duration > 1 && (
					<div className="row">
						<div className="col">
							<label htmlFor="editMode">Edit Mode:</label>
							<input
								type="radio"
								name="editMode"
								value="normalEdit"
								onChange={handleChange}
								defaultChecked
							/>
							<label>Normal Edit</label>
							<input
								type="radio"
								name="editMode"
								value="divideLeave"
								onChange={handleChange}
							/>
							<label>Divide Into Multiple Leave Types</label>
						</div>
					</div>
				)} */}
				<div className="row">
					<div className="col">
						<label htmlFor="leaveType">Choose Leave Type</label>
						<select
							name="leaveTypeName"
							className="form-control custom-select"
							id="leaveType"
							value={leaveDetails.leaveTypeName}
							required
							onChange={handleChange}
						>
							{leaveTypeOptions.map((lto) => (
								<option value={lto.leaveTypeName}>{`${lto.leaveTypeName} (${lto.remainingDay})`}</option>
							))}
						</select>
					</div>
					<div className="col"></div>
				</div>
				<br />
				{/* {leaveDetails.editMode === "divideLeave" && (
					<div className="row">
						<div className="col-6">
							<label htmlFor="leaveTypeII">
								Choose Leave Type II{" "}
								<i style={{ color: "#ff6600" }}>(For Another Interval Dates)</i>
							</label>
							<select
								name="leaveTypeNameII"
								className="custom-select"
								value={leaveDetails.leaveTypeNameII}
								required
								onChange={handleChange}
							>
								{" "}
								<option value="">Please Choose Leave Type</option>
								{nonPaidLeaveOptions.map((nplo) => (
									<option value={nplo.id}>{nplo.leaveTypeName}</option>
								))}
							</select>
						</div>
					</div>
				)} */}
				<br />
				<div className="row">
					<div className="col">
						<label htmlFor="leaveMode">Leave Mode:</label>
						<input
							type="radio"
							id="half_day"
							name="leaveMode"
							value="halfDay"
							required
							checked={leaveDetails.leaveMode === "halfDay"}
							onChange={handleChange}
						/>
						<label htmlFor="half_day">Half Day</label>
						<input
							type="radio"
							id="full_day"
							name="leaveMode"
							value="fullDay"
							checked={leaveDetails.leaveMode === "fullDay"}
							onChange={handleChange}
						/>
						<label htmlFor="full_day">Full Day</label>
						{/* <input
							type="radio"
							id="custom"
							name="leaveMode"
							value="custom"
							checked={leaveDetails.leaveMode === "custom"}
							onChange={handleChange}
						/>
						<label htmlFor="custom">Custom</label> */}
					</div>

					{leaveDetails.leaveMode === "halfDay" && (
						<div className="col">
							<label htmlFor="period_of_day">Period of Day:</label>
							<input
								type="radio"
								name="periodOfDay"
								value="firstHalf"
								required
								checked={leaveDetails.periodOfDay === "firstHalf"}
								onChange={handleChange}
							/>
							<label htmlFor="first_half">First Half</label>
							<input
								type="radio"
								name="periodOfDay"
								value="secondHalf"
								checked={leaveDetails.periodOfDay === "secondHalf"}
								onChange={handleChange}
							/>
							<label htmlFor="second_half">Second Half</label>
						</div>
					)}
				</div>
				<br />
				<div className="row">
					<div className="row">
						<div className="col-6">
							<label>From</label>
							<input
								type="date"
								className="form-control"
								name="dateOfLeave"
								id="dateOfLeave"
								value={leaveDetails.dateOfLeave}
								required
								onChange={handleChange}
							/>
						</div>

						{/* {leaveDetails.leaveMode === "custom" && (
							<div className="col-6">
								<label htmlFor="leaveTime">Leave Time</label>
								<input
									className="form-control"
									type="time"
									name="leaveTime"
									id="leaveTime"
									required
									value={leaveDetails.leaveTime}
									min="09:00"
									max="18:00"
									onChange={handleChange}
								/>
							</div>
						)} */}
					</div>

					{/* {leaveDetails.editMode === "divideLeave" && (
						<div className="col-6">
							<label>Mid Date</label>
							<input
								type="date"
								className="form-control"
								name="midDate"
								required
								value={leaveDetails.midDate}
								onChange={handleChange}
							/>
						</div>
					)} */}

					<div className="row">
						{leaveDetails.leaveMode !== "halfDay" && (
							<div className="col-6">
								<label>To</label>
								<input
									type="date"
									className="form-control"
									name="dateOfArrival"
									id="dateOfArrival"
									value={leaveDetails.dateOfArrival}
									required
									onChange={handleChange}
								/>
							</div>
						)}
						{/* {leaveDetails.leaveMode === "custom" && (
							<div className="col-6">
								<label htmlFor="arrivalTime">Arrival Time</label>
								<input
									className="form-control"
									type="time"
									name="arrivalTime"
									id="arrivalTime"
									value={leaveDetails.arrivalTime}
									required
									onChange={handleChange}
								/>
							</div>
						)} */}
					</div>
				</div>
				<br />
				<div className="row">
					<div className="col-6">
						<label>Leave Description</label>
						<br />
						<textarea
							name="leaveDescription"
							id="leaveDescription"
							className="form-control"
							cols="35"
							rows="3"
							value={leaveDetails.leaveDescription}
							required
							onChange={handleChange}
						/>
					</div>
					{checkDecline === true && (
						<div className="col-6">
							<label>Decline Message</label>
							<br />
							<textarea
								className="form-control"
								name="declineMessage"
								id="declineMessage"
								cols="35"
								rows="3"
								value={leaveDetails.declineMessage}
								required
								onChange={handleChange}
							/>
						</div>
					)}
				</div>
				<br />
				<div className="row">
					<div className="col">
						<Button
							type="submit"
							style={{ width: "40%", color: "white" }}
							variant="contained"
							color="primary"
						>
							Edit
						</Button>
					</div>
					<div className="col">
						<Button
							onClick={() => history.push(pathName)}
							style={{
								width: "40%",
								float: "right",
								color: "white",
							}}
							variant="contained"
							color="secondary"
						>
							Cancel
						</Button>
					</div>
				</div>
			</form>
		</Container>
	)
}
