import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import axios from "../utils/axios";
import CancelIcon from "@material-ui/icons/Cancel";
import { notify } from "../utils/toast";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
}));

export default function RejectModal({ id, action, tableRef }) {
  const classes = useStyles();
  const messageRef = useRef();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    const message = { message: messageRef.current.value };
    axios
      .put(`/api/admin/requests/${id}/${action}`, JSON.stringify(message))
      .then((res) => {
        setTimeout(() => {
          tableRef.current && tableRef.current.onQueryChange();
        }, 400);
        setOpen(false);
        notify('error', 'Request rejected!')
      })
      .catch((err) => 
				notify('error','Something went wrong !')
      );
  }
  return (
    <div>
      <Button
        style={{ color: "red", border:"2px solid red", marginRight: '15px'}}
        variant="outlined"
        startIcon={<CancelIcon />}
        onClick={handleOpen}
      >
        Reject
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p id="transition-modal-description">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Leave message"
                    name="remarks"
                    required
                    ref={messageRef}
                  />
                </Form.Group>

                <Button
                  color="primary"
                  variant="outlined"
                  type="submit"
                  disableElevation
                >
                  Submit
                </Button>
              </Form>
            </p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
