import React, { useState, useEffect, useRef } from "react"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import axios from "../utils/axios"
import { notify } from "../utils/toast"
import { useHistory } from "react-router"
import { Card, Box, Chip } from "@material-ui/core"
import ListAltIcon from "@material-ui/icons/ListAlt"
import { Formik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { fetchCurrentUser } from "../Redux/actions/authActions"
import Spinner from "../Spinner/Spinner"
import ConfirmULModal from "./ConfirmULModal/ConfirmULModal"
import moment from "moment"

export default function LeaveApplyForm() {
	const [leaveDetails, setLeaveDetails] = useState({
	
	})
	const [isLoading, setIsLoading] = useState(false)
	const [optionLeaveType, setOptionLeaveType] = useState([])
	// const [joinDate, setJoinDate] = useState()
	// const [userName, setUserName] = useState()
	const history = useHistory()
	const dispatch = useDispatch()
	const [showUnpaidOption,setShowUnpaidOption] = useState(false); 
	// const [extra,setExtra] = useState(0); //for message purpose
	const [duration,setDuration] = useState(0); //for decision of displaying warning or not
	const [settlable,setSettelable] = useState(true); // CL purpose
	// const [leaveStat,setLeaveStat] = useState({});
	const [emptyLeave,setEmptyLeave] = useState(false);
	const { user } = useSelector((store) => store.authReducer)
	const supervisors = useSelector((store) => store.authReducer.supervisors)
	const routePath ="/dashboard/myLeaveDetails"
	var approvers = []
	const [data,setData] = useState(new FormData())
	const [currentLeaveTypeID, setcurrentLeaveTypeID] = useState(null)
	
	const check_leaveCompliance = (duration) => {
		let daysToGo = moment(Object.fromEntries(data?.entries()).dateOfLeave).diff(moment(),'days') +1
		let message = "You are violating Leave Compliance! "
		if((duration >= 5) && (daysToGo < 30)){
			message += "To apply leave for more than 5 days, you must apply before 30 days!"
		}else if((duration >= 2) && (daysToGo < 7)){
			message += "To apply leave for 2 to 4 days, you must apply before 7 days!"
		}else if((duration  >= 0) && (daysToGo < 4)){
			message += "To apply leave for 1 day or half day, you must apply before 4 days!"
		}else{
			return {
				"err":false,
			};
		}
		return {
			"err":true,
			"msg":message
		};
	}
	const filterApplicableLeave = (userLeaveType, userLeaveUsed) =>{
		// Update Dropdown leave type to have remaining days
		let newLeaveType = userLeaveType.map((item) => {
			userLeaveUsed.forEach((element) => {
			  if (item.leave_type_name === element.leaveTypeName) {
				item["remainingDay"] =
				  element.remainingDay > 0 ? element.remainingDay : 0;
			  }
			});
			return item;
		});

		// Show complimentary leave only if its available to user
		let res = []
		for (let i = 0; i < newLeaveType.length; i++) {
			if(newLeaveType[i].leave_type_name ==="Complementary Leave" 
			&& newLeaveType[i].leave_type_total_days === "0"){
				continue;
			}
			res.push(newLeaveType[i])
		}
		
		// Finally show unpaid only if anual leave is 0 
		for (let i = 0; i 	< res.length; i++) {
			if(res[i].leave_type_name ==="Annual Leave" 
			&& res[i].remainingDay > 0){
				res = res.filter(item => item.leave_type_name !== 'Unpaid Leave')
			}
			
		}
		setOptionLeaveType(res);
	}

	useEffect(() => {
		axios
			.get("api/leave/type")
			.then((response) => {
				let userLeaveType = response.data.data
				// setJoinDate(response.data.joinedAt.date)
				// setUserName(response.data.firstName + " " + response.data.lastName)
				axios.post("api/leaveStat",response.data.data)
				.then((response) =>{
					let userLeaveUsed = response.data
					filterApplicableLeave(userLeaveType, userLeaveUsed)
					// setLeaveStat(response.data);
				})
			})
			.catch((error) => console.log(error))
	}, [dispatch])

	useEffect(()=>{
		supervisors?.map((e)=>{
			approvers.push(e.employee_id);
		})
		setLeaveDetails({...leaveDetails, ['approvers']: approvers})
	},[supervisors])

	const imageRef = useRef()


	const applyUL = () => {
		setIsLoading(true);
		let split = [];
		let allow = 0;
		const formData = Object.fromEntries(data.entries());
		
		let filterRemainingDays = optionLeaveType.filter(item => parseInt(item.id) === parseInt(formData.leaveType))
		allow = filterRemainingDays[0].remainingDay;

		// extra info to split leaves
		// setExtra(Math.abs(duration - allow));
		data.append('adjust',settlable);

		if(emptyLeave){
			split = [allow,duration - allow];
			data.append('split',split);
		}
		data.append('remarks',"*** Additional "+ (Math.abs(duration - allow)) +" unpaid leaves applied at once through system on consent of requester ***");
						
		axios
			.post("api/leave/request", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},

			})
			.then((response) => {
				notify("success", "successfully applied mails sending in progress.")
				let mails = response.data;
				mails = mails.map((e)=> {
					let data = {
						type : "LeaveRequest"
					};
					let req = axios.post("api/mail/send/"+e,data)
					return req;
				})


				setIsLoading(false)
	
				// for mail
				Promise
				.all(mails)
				.then((responses) => {
					notify("success","Emails sent succesfully")
				})
				.catch(errors => {
					notify("error","could not send mail due to some errors.")
				})

				history.push(routePath)
			
			})
			.catch((err) => {
				console.log(err);
				notify("error", err.response.data)
				setIsLoading(false)
			})
	}

	const getLeaveTypeName = () =>{
		if (currentLeaveTypeID === null || currentLeaveTypeID === undefined) {
			return "";
		}
		let leaveTypeObj = optionLeaveType.find((item) => {if(parseInt(item.id) === parseInt(currentLeaveTypeID)){return item}});
		return leaveTypeObj?.leave_type_name
	}

	const warningComponent = (
	<div className="p-3">
		<h3 className="text-danger text-center">!! Warning !!</h3>
		<div>
			{/* <div>{JSON.stringify(optionLeaveType)}</div>
			<div>{currentLeaveTypeID}</div> */}
			<div className="text-center">
				You have <strong>insufficient {getLeaveTypeName()}</strong>.</div> 
			<br/>

			Remaining Leaves :-
			<ul>
				{optionLeaveType.map((item,key )=>(
				<li key={key}><strong>{item.leave_type_name} : {item?.remainingDay}</strong></li>
				))}
			</ul>
			Applied Duration :-
			<ul>
				<li><strong>{getLeaveTypeName()} : {duration}</strong></li>
			</ul>
			Press <strong>Cancel</strong> to apply Leave seperately 
			{/* <div className="text-center">OR</div>
			{
				!emptyLeave ? 
				<div>
					Apply <strong>
					{
						(Object.fromEntries(data.entries()).leaveType == 3) ?
						" Sick Leave for " + ( leaveStat[1]?.remainingDay ):
						" Annual Leave for " + (leaveStat[2]?.remainingDay )
					} days </strong> and <strong>Unpaid Leave</strong> for {
						(Object.fromEntries(data.entries()).leaveType == 3) ? 
						( duration - leaveStat[1]?.remainingDay ): 
						( duration - leaveStat[2]?.remainingDay )
					} days  ?.
				</div>
				: 
				<div>
					Apply <strong>Unpaid Leave</strong> for {(Object.fromEntries(data.entries()).leaveType == 3) ? ( duration - leaveStat[1]?.remainingDay ):(duration - leaveStat[2]?.remainingDay )} days  ?.
				</div>
			}  */}
			
			
		</div>
	</div>
	)
	return (
		<Container style={{ background: "#fafafa", border: "1px solid #dee2e6", padding: "2%" }} data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<div className="leaveApplyFormHeader">
				<Card style={{ background: "#ff6600", height: "50px" }}>
					<h5 style={{ color: "white", marginTop: "1%", textAlign: "center" }}>
						<ListAltIcon style={{ fontSize: "2.2rem" }} /> 
						Leave Application Form
					</h5>
				</Card>
			</div>

			<Formik
				initialValues={{
					dateOfLeave: "",
					dateOfArrival: "",
					leaveTime: "",
					arrivalTime: "",
					leaveType: "",
					leaveDescription: "",
					leaveMode: "",
					periodOfDay: "",
				}}
				validationSchema={Yup.object({
					dateOfLeave: Yup.date().required("Required"),
					leaveType: Yup.string().required("Required"),
					leaveDescription: Yup.string().trim().required("Required"),
					leaveMode: Yup.string().required("Required"),
					periodOfDay: Yup.string().when("leaveMode", {
						is: "halfDay",
						then: Yup.string().required("Required"),
					}),
					dateOfArrival: Yup.date().when("leaveMode", {
						is: (leaveMode) => leaveMode === "fullDay" || leaveMode === "custom",
						then: Yup.date().required("Required"),
					}),
					leaveTime: Yup.string().when("leaveMode", {
						is: "custom",
						then: Yup.string().required("Required"),
					}),
					arrivalTime: Yup.string().when("leaveMode", {
						is: "custom",
						then: Yup.string().required("Required"),
					}),
				})}
				onSubmit={(values, { setSubmitting }) => {
					var formData = new FormData();
					var imageData = imageRef.current.files

					for (var index = 0; index < Object.keys(imageData).length; index++) {
						formData.append(`leaveDocuments${index}`, imageData[index])
					}
					formData.append("filesCount", Object.keys(imageData).length)
					for (var key in values) {
						if (values["leaveMode"] === "halfDay") {
							if (values["periodOfDay"] === "firstHalf") {
								values["leaveTime"] = "09:00"
								values["arrivalTime"] = "13:00"
							}
							if (values["periodOfDay"] === "secondHalf") {
								values["leaveTime"] = "14:00"
								values["arrivalTime"] = "18:00"
							}
							values["dateOfArrival"] = values.dateOfLeave
						}
						if (values["leaveMode"] === "fullDay") {
							values["leaveTime"] = "09:00"
							values["arrivalTime"] = "18:00"							
							values["periodOfDay"] = ""
						}
						if (values["leaveMode"] === "custom") {
							values["periodOfDay"] = ""
						}
						formData.append(key, values[key])
					}
					for (var keyItem in leaveDetails) {
						formData.append(keyItem, leaveDetails[keyItem])
					}

					setData(formData)

					
					axios
						.post("api/leave/duration", formData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						})
						.then((res) => {
							// check if duration is greater than applied
							const duration = res.data.duration;
							setDuration(duration);
							optionLeaveType.map((e)=>{
								if(parseInt(e.id) === parseInt(values.leaveType)){
									setcurrentLeaveTypeID(parseInt(values.leaveType))

									// cases of other leaves allowed not zero
									if(e.leave_type_name !== "Unpaid Leave"){
										setEmptyLeave(true);
									}

									if(e.leave_type_total_days === parseInt(0) && e.leave_type_name !== "Unpaid Leave"){
										setEmptyLeave(true);
									}

									if((e.leave_type_name === "Unpaid Leave" || e.leave_type_name === "Annual Leave") && check_leaveCompliance(duration).err){
										notify("warning", check_leaveCompliance(duration).msg, 8000)
									}
									if(duration > e.remainingDay){
										setShowUnpaidOption(true);
									}else{
										setIsLoading(true);
										axios
											.post("api/leave/request", formData, {
												headers: {
													"Content-Type": "multipart/form-data",
												},
											})
											.then((response) => {
												notify("success", "successfully applied mails sending in progress.")
												let mails = response.data;
												mails = mails.map((e)=> {
													let data = {
														type : "LeaveRequest"
													};
													let req = axios.post("api/mail/send/"+e,data)
													return req;
												})
											
												setIsLoading(false)
									
												// for mail
												Promise
												.all(mails)
												.then((responses) => {
													notify("success","Emails sent succesfully")
												})
												.catch(errors => {
													notify("error","could not send mail due to some errors.")
												})

												history.push(routePath)
											})
											.catch((err) => {
												notify("error", err.response.data)
												setIsLoading(false)
											})
									}
								}
							})
						})
						.catch((err) => {
							notify("error", err.response.data)
							setIsLoading(false)
						})

				
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) =>
					isLoading === true ? (
						<Spinner />
					) : (
						<form onSubmit={handleSubmit}>
							{/* Removed irrelevant info about joined date according to ticket #OMS-006 */}
							{/* <div className="row" style={{ marginTop: "3%" }}>
								<div className="col">
									<Card
										style={{
											boxShadow: "2px 2px 10px 2px #dbc6a4",
											width: "20%",
											float: "right",
										}}
									>
										<h6
											style={{
												color: "#ff6600",
												marginTop: "3%",
												textAlign: "center",
											}}
										>
											Join Date: {dateFormat(joinDate)}
										</h6>
									</Card>
								</div>
							</div> */}
							{/* <div>{JSON.stringify(optionLeaveType)}</div> */}
							<div className="row">
								<div className="col-6">
									<label htmlFor="leaveType">Choose Leave Type</label>
									<select
										name="leaveType"
										className="custom-select form-control"
										id="leaveType"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.leaveType}
									>
										<option defaultValue="">
											Please Choose Leave Type ...
										</option>
										{optionLeaveType.map((olt, key) => (
											<option key={key} value={olt.id} disabled={olt.leave_type_name!=="Unpaid Leave" && !olt.remainingDay}>
												{`${olt.leave_type_name} (${olt.remainingDay})`}
											</option>
											)
										)}
									</select>
									<p className="text-danger">
										{errors.leaveType && touched.leaveType && errors.leaveType}
									</p>
								</div>
								
							</div>
							<hr/>
							<br />
							<div className="row">
								<div className="col">
									<label htmlFor="leaveMode">Leave Mode:</label>
									<input
										type="radio"
										id="half_day"
										name="leaveMode"
										value="halfDay"
										checked={values.leaveMode === "halfDay"}
										onChange={handleChange}
										onBlur={handleBlur}
									/>

									<label htmlFor="half_day">Half Day</label>
									<input
										type="radio"
										id="full_day"
										name="leaveMode"
										value="fullDay"
										checked={values.leaveMode === "fullDay"}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<label htmlFor="full_day">Full Day</label>
									{/* ignored custome leave for now according to discussion with supervisor */}
									{/* <input
										type="radio"
										id="custom"
										name="leaveMode"
										value="custom"
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									<label htmlFor="custom">Custom</label> */}
									<p className="text-danger">
										{errors.leaveMode && touched.leaveMode && errors.leaveMode}
									</p>
								</div>
								{values.leaveMode === "halfDay" && (
									<div className="col">
										<label htmlFor="period_of_day">Period of Day:</label>
										<input
											type="radio"
											name="periodOfDay"
											value="firstHalf"
											checked={values.periodOfDay === "firstHalf"}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label htmlFor="first_half">First Half</label>
										<input
											type="radio"
											name="periodOfDay"
											value="secondHalf"
											checked={values.periodOfDay === "secondHalf"}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<label htmlFor="second_half">Second Half</label>
										<p className="text-danger">
											{errors.periodOfDay &&
												touched.periodOfDay &&
												errors.periodOfDay}
										</p>
									</div>
								)}
							</div>
							<hr/>
							<br />
							<div className="row">
								<div className="row">
									<div className="col-6">
										<label>From</label>
										<input
											type="date"
											className="form-control"
											name="dateOfLeave"
											id="dateOfLeave"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.dateOfLeave}
											// min={new Date().toJSON().slice(0,10).replace(/-/g,'-')}
										/>
										<p className="text-danger">
											{errors.dateOfLeave &&
												touched.dateOfLeave &&
												errors.dateOfLeave}
										</p>
									</div>
									{/* part of custom leave option */}
									{/* {values.leaveMode === "custom" && (
										<div className="col-6">
											<label htmlFor="leaveTime">Leave Time</label>
											<input
												className="form-control"
												type="time"
												name="leaveTime"
												id="leaveTime"
												value={values.leaveTime}
												onChange={handleChange}
												onBlur={handleBlur}
												min="09:00"
												max="18:00"
											/>
											<p className="text-danger">
												{errors.leaveTime &&
													touched.leaveTime &&
													errors.leaveTime}
											</p>
										</div>
									)} */}
								</div>

								<div className="row">
									{values.leaveMode !== "halfDay" && (
										<div className="col-6">
											<label>To</label>
											<input
												type="date"
												className="form-control"
												name="dateOfArrival"
												id="dateOfArrival"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.dateOfArrival}
												// min={new Date().toJSON().slice(0,10).replace(/-/g,'-')}
											/>
											<p className="text-danger">
												{errors.dateOfArrival &&
													touched.dateOfArrival &&
													errors.dateOfArrival}
											</p>
										</div>
									)}
									{values.leaveMode === "custom" && (
										<div className="col-6">
											<label htmlFor="arrivalTime">Arrival Time</label>
											<input
												className="form-control"
												type="time"
												name="arrivalTime"
												id="arrivalTime"
												value={values.arrivalTime}
												onChange={handleChange}
												onBlur={handleBlur}
												min="09:00"
												max="18:00"
											/>
											<p className="text-danger">
												{errors.arrivalTime &&
													touched.arrivalTime &&
													errors.arrivalTime}
											</p>
										</div>
									)}
								</div>
							</div>
							<hr/>
							<br />
							<div className="row">
								{/* remove option to select supervisor by leave requestor */}
								{/* <div className="col-6">
									<MultipleSelectInput
										leaveDetails={leaveDetails}
										setLeaveDetails={setLeaveDetails}
										approversOption={approversOption}
									/>
								</div> */}
								<div className="col-6">
									<p>Supervisors</p>
									<div className="d-flex justify-content-start gap-3">
									{
										 supervisors?.map((element,key)=>(
											<Chip key={key} label={element.first_name + " " + element.last_name} style={{
												color: "#ffffff",
												backgroundColor: "#ff6600"}}/>
										  ))
									}
									</div>
								</div>
								<div className="col">
									<label>Leave Description</label>
									<br />
									<textarea
										name="leaveDescription"
										id="leaveDescription"
										cols="40"
										rows="3"
										className="form-control"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.leaveDescription}
									/>
									<p className="text-danger">
										{errors.leaveDescription &&
											touched.leaveDescription &&
											errors.leaveDescription}
									</p>
								</div>
							</div>
							<hr/>
							<br />
							<div className="row">
								<div className="col">
									<label htmlFor="uploadDocument">
										Choose files or report if any? (Only images) <br />
										<i style={{ color: "#ff6600" }}>
											<b>Note: </b>Ctrl + select for multiple image selection
										</i>
									</label>

									<Box
										style={{
											border: "1px solid #adb5bd",
											borderRadius: "10px",
											background: "whitesmoke",
										}}
									>
										<input
											style={{
												marginTop: "1%",
												marginLeft: "1%",
												marginBottom: "1%",
											}}
											type="file"
											ref={imageRef}
											accept="image/*"
											multiple
										/>
									</Box>
								</div>
								<div className="col"></div>
							</div>
							<br />
							<br />
							<div className="row">
								<input type="number" name="allowed" className="d-none"/>
								<Button
									type="submit"
									style={{ width: "20%", color: "white" }}
									variant="contained"
									color="primary"
								>
									Apply
								</Button>
							</div>
						</form>
					)
				}
			</Formik>
			<ConfirmULModal content={warningComponent} 
						open={showUnpaidOption}  
						setOpen={setShowUnpaidOption} 
						onConfirm={()=>{
											applyUL();
										}} 
						onCancel={()=>{
										setIsLoading(false)
									}}
						set={settlable}
						onSet={setSettelable}
						
			/>
		</Container>
	)
}
