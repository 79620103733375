import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import RejectWorkModal from '../AdminWorkTab/RejectWorkModal/RejectWorkModal';
import RejectComplementaryLeaveModal from '../AdminWorkTab/RejectComplementaryLeaveModal/RejectComplementaryLeaveModal';
import axios from "../utils/axios"
import { notify } from '../utils/toast';
import { CircularProgress } from '@material-ui/core';
import "./ExtraWorkSplitButton.css";

export default function ExtraWorkSplitButton({type, id, isLoaded, setIsLoaded}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const [process,setProcess] = React.useState(false)
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    } 

    setOpen(false);
  };

  const handleWorkRequestApprove = () => {
    setProcess(true)
    setOpen(false)
    if(type === "Request"){
      axios
        .put(`api/workRequestApprove/${id}`)
        .then((response) => {
          setProcess(false)
          notify("success",response.data)
          setIsLoaded(!isLoaded);
        })
        .catch((error) => {
          notify("error", error.response.data)
          setProcess(false)
        })
    }else{
      axios
        .put(`api/CLApprove/${id}`)
        .then((response) => {
          setProcess(false)
          notify("success",response.data)
          setIsLoaded(!isLoaded);
        })
        .catch((error) => {
          notify("error", error.response.data)
          setProcess(false)
        })
    }
	}

  return (
    <Grid container direction="column" alignItems="center">
      {process? <CircularProgress/> :(
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <Button style={{color:"white"}}>{type==="Request"?"Extra Work Request":"Work Done"}</Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon style={{color:"white"}}/>
          </Button>
        </ButtonGroup>
        
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:1000}} placement="bottom-end">
          {({ TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center top',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    
                      <MenuItem
                        onClick={handleWorkRequestApprove}
                      >
                      Approve
                      </MenuItem>
                    {
                      type==="Request"?
                      <MenuItem>
                        <RejectWorkModal id={id} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
                      </MenuItem>:
                      <MenuItem className='reject-button'>
                        <RejectComplementaryLeaveModal id={id} isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
                      </MenuItem>
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      
      </Grid>
      )}
    </Grid>
  );
}
