import React from "react"
import { Box, Modal } from "@material-ui/core"

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "auto",
	height: 700,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
}

export default function ImageModal({ open, handleOpen, leaveDoc }) {
	return (
		<div>
			<Modal open={open} onClose={handleOpen}>
				<Box sx={style}>
					<img
						src={process.env.REACT_APP_API_URL + leaveDoc}
						style={{ width: "100%", height: "100%" }}
						alt=""
					/>
				</Box>
			</Modal>
		</div>
	)
}
