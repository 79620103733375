import React from 'react'
import {notify} from '../utils/toast';
import axios from '../utils/axios';
import { Button } from "@material-ui/core";
import { logoutUser } from "../Redux/actions/authActions"
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import './style.css';
function ChangePassword() {
    const dispatch = useDispatch();
    const [errorPasswordChange,setErrorPaswordChange] = useState('');
    const [oldPwd,setOldPwd] = useState('');
    const [newPwd,setNewPwd] = useState('');
    const [rePwd,setRePwd] = useState('');
    const history = useHistory();

    const resetForm = () => {
      setNewPwd('');
      setOldPwd('');
      setRePwd('');
    }
    useEffect(()=>{
        if(newPwd === rePwd ){
          setErrorPaswordChange('');
        }else{
          setErrorPaswordChange("* Passwords does not match *");
        }
      },[newPwd,rePwd])
    
      const handleChange = (e) => {
        switch(e.target.name){
          case 'old_password':
            setOldPwd(e.target.value || oldPwd);
            break;
          
          case 'new_password':
            setNewPwd(e.target.value || newPwd);
            break;
    
          case 're-entered_password':
            setRePwd(e.target.value || rePwd);
            break;

          default:
            break;
        }
      }

  return (
    <div className='my-3 mx-5 px-3'>
        <Row >
          <h5 className="my-2 ">Change Password</h5>
            <Col md={6}>
              <div className="d-flex flex-row justify-content-start align-items-center my-4">
                <p className='label'>Type old password:</p>
                <input 
                  className="mx-3 form-control form-control-sm"
                  type="password"
                  name="old_password"
                  id="old_password"
                  value={oldPwd}
                  onChange={handleChange}
                  />
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center my-4">
                <p className='label'>Type new password:</p>
                <input 
                  className="mx-3 form-control form-control-sm"
                  type="password"
                  name="new_password"
                  id="new_password"
                  value={newPwd}
                  onChange={handleChange}
                  />
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center my-4">
                <p className='label'>Re-type new password:</p>
                <input 
                  className="mx-3 form-control form-control-sm"
                  type="password"
                  name="re-entered_password"
                  id="re-entered_password"
                  value={rePwd}
                  onChange={handleChange}
                  />
              </div>
              <h6 className="text-danger">{errorPasswordChange}</h6>
            </Col>
        </Row>
        <Button
          variant="contained"
          color="primary"
          disabled={(errorPasswordChange === '')? false : true}
          className='mb-3 text-light'
          onClick={() => {
            // validate the inputs
            if(newPwd.length >= 8 ){
              // send request to change pasword
              axios.post('api/user/changepassword',{
                old_password: oldPwd,
                new_password: newPwd
              }).then((response)=>{
                return response;
              })
              .then((res)=>{
                // console.log(res);
                if(res.data.code === 200){
                  // password change is succesfull
                  notify('success',res.data.message);
                  dispatch(logoutUser());
                }
                else if(res.data.code === 406){
                  // console.log(res.data.errors.password);
                  setErrorPaswordChange(res.data.errors.password);
                  // notify('error',res.data.errors.password);
                  // resetForm();
                }
                else if(res.data.code === 403){
                  // console.log(res.data);
                  setErrorPaswordChange(res.data.errors);
                  notify('error',res.data.errors);
                  resetForm();
                }
                
              })
              .catch((err)=>{
                console.log("error: ",err);
              })
              // close password change fields
            }else{
              setErrorPaswordChange("* Password must be at least 8 characters *");
            }
          
          }}
        >
          Save Changes
        </Button>
        <Button
          variant="default"
          className='mx-3 mb-3'
          onClick={() => {
            // history route back
            history.goBack();
          }}
        >
          cancel
        </Button>
      </div>
  )
}

export default ChangePassword