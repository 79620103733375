export function dateFormat(date) {
	const formatDate = new Date(date)
	return formatDate.toDateString().slice(4)
}

export function getDayFromDate(date) {
	var d = new Date(date)
	var weekday = new Array(7)
	weekday[0] = "Sunday"
	weekday[1] = "Monday"
	weekday[2] = "Tuesday"
	weekday[3] = "Wednesday"
	weekday[4] = "Thrusday"
	weekday[5] = "Friday"
	weekday[6] = "Saturday"

	return weekday[d.getDay()]
}

export function timeFormat(time) {
	const formatTime = new Date(time)
	return formatTime.toLocaleTimeString()
}
