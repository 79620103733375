import React from "react"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"

import { withStyles } from "@material-ui/styles"
import { makeStyles} from "@material-ui/core"
import axios from "../../utils/axios"
import ApproverAvatar from "../../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../../utils/dateTimeFormat"
import { notify } from "../../utils/toast"
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
	body: {
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 900,
	},
	tableContainer: {
		width: "100%",

		marginTop: "1%",
	},
})

export default function CurrentLeaveTable() {
	const classes = useStyles()
	const [todaysLeave, setTodaysLeave] = React.useState([])

	React.useEffect(() => {
		axios
			.get("api/todays/leave")
			.then((res) => setTodaysLeave(res.data))
			.catch((err) => 
				notify('error','Something went wrong !')
			)
	}, [])

	return (
		<div>
			<TableContainer className={classes.tableContainer}>
				<h5 style={{ color: "#ff6600" }} className="text-center mb-5 mt-2">Employees on Leave</h5>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Employee</StyledTableCell>
							<StyledTableCell>From</StyledTableCell>
							<StyledTableCell>LeaveType</StyledTableCell>
							<StyledTableCell>Approvers</StyledTableCell>
							<StyledTableCell>To</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{todaysLeave.map((stl) => (
							<TableRow key={stl.id}>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell>
									{stl.first_name + " " + stl.last_name}
								</StyledTableCell>
								<StyledTableCell>
									{dateFormat(stl.date_of_leave)}
								</StyledTableCell>
								<StyledTableCell>{stl.leave_type_name}</StyledTableCell>
								<StyledTableCell>
									{stl.approvers.map((ad,key) => (
										<ApproverAvatar key={key} ad={ad} />
									))}
									{stl.adminApprover && (
										<ApproverAvatar ad={stl.adminApprover} />
									)}
								</StyledTableCell>
								<StyledTableCell>
									{dateFormat(stl.date_of_arrival)}
								</StyledTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
