import React, {useState } from "react"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import { useSelector } from "react-redux"
import WorkFromHomeHistoryTable from "./AdminWorkFromHomeTables/WorkFromHomeHistoryTable"
import AllWorkFromHomeRequestTable from './AdminWorkFromHomeTables/AllWorkFromHomeRequestTable'
function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
		role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
			> 
			{value === index && (
				<Box p={3}>
					{/* <Typography> */}
						<Paper>{children}</Paper>
					{/* </Typography> */}
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		width: "98%",
		marginLeft: "1%",
	},
}))

export function AdminWorkFromHomeTab() {
	const permissions = useSelector((store)=>store.authReducer.permissions)
	const classes = useStyles()
	const theme = useTheme()
	const [value, setValue] = useState(0)
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const handleChangeIndex = (index) => {
		setValue(index)
	}

	return (
		<div className={classes.root} data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					aria-label="full width tabs example"
				>
					<Tab label="Employees Work From Home Requests" {...a11yProps(0)} />
					{
						permissions?.includes("view_work_from_home_history") &&
						<Tab label="Work From Home History" {...a11yProps(1)} />
					}
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === "rtl" ? "x-reverse" : "x"}
				index={value}
				onChangeIndex={handleChangeIndex}
			>
				<TabPanel value={value} index={0} dir={theme.direction}>
					<AllWorkFromHomeRequestTable/>
				</TabPanel>
				{
					permissions?.includes("view_work_from_home_history") &&
					<TabPanel value={value} index={1} dir={theme.direction}>
						<WorkFromHomeHistoryTable/>
					</TabPanel>
				}
			</SwipeableViews>
		</div>
	)
}
