import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ServicingDetails from "../Servicing/ServicingDetails";
import AllocationDetails from "../Allocation/AllocationDetails";
import Inventory from "../Pages/Inventory/Inventory";
import { useSelector } from "react-redux";
import RepairsTable from "../Repairs.jsx/RepairsTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ScrollableTabsButtonAuto() {
  const [value, setValue] = React.useState(0);

  const isInventoryAllocatable = useSelector(
    (store) =>
      store.inventoryReducer.inventory &&
      store.inventoryReducer.inventory.isAllocatable
  );
  const requiresServicing = useSelector(
    (store) =>
      store.inventoryReducer.inventory &&
      store.inventoryReducer.inventory.requiresServicing
  );
  const permissions = useSelector((store)=>store.authReducer.permissions)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="white">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label="General"
            icon={<i className="fas fa-info-circle"></i>}
            {...a11yProps(0)}
          />
          {isInventoryAllocatable && (
            <Tab
              label="Owners"
              icon={<i className="fas fa-hand-holding-medical"></i>}
              {...a11yProps(1)}
            />
          )}
          {requiresServicing && (
            <Tab
              label="Servicings"
              icon={<i className="fas fa-stopwatch"></i>}
              {...a11yProps(2)}
            />
          )}
          <Tab
            label="Repairs"
            icon={<i className="fas fa-tools"></i>}
            {...a11yProps(3)}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Inventory />
        </TabPanel>
      </AppBar>
      {isInventoryAllocatable && (
        <TabPanel value={value} index={1}>
          <AllocationDetails />
        </TabPanel>
      )}
      {requiresServicing && (
        <TabPanel value={value} index={isInventoryAllocatable ? 2 : 1}>
          <ServicingDetails />
        </TabPanel>
      )}
      {permissions?.includes("view_repair") &&
        <TabPanel
          value={value}
          index={
            isInventoryAllocatable
              ? requiresServicing
                ? 3
                : 2
              : requiresServicing
              ? 2
              : 1
          }
        >
          <RepairsTable />
        </TabPanel>
      }
    </div>
  );
}
