import moment from "moment";
export function generateUserPayload(userSchema) {
  let payload = {
    firstName: userSchema?.firstName ?? "",
    lastName: userSchema?.lastName ?? "",
    email: userSchema?.email ?? "",
    designation: userSchema?.designation ?? "",
    roles: userSchema?.roles ? userSchema.roles : "",
    joinedAt: userSchema?.joinedAt ?? "",
    probationEndAt: userSchema?.probationEndAt ?? "",
    employeeId: userSchema?.employeeId,
    dateOfBirth: userSchema?.dateOfBirth ?? "",
    citizenshipNumber: userSchema?.userInfo.citizenshipNumber ?? "",
    panNumber: userSchema?.userInfo.panNumber ?? "",
    gender: userSchema?.gender ?? "",
    phoneNumber: userSchema?.userInfo.phoneNumber ?? "",
    temporaryAddress: userSchema?.userInfo.temporaryAddress ?? "",
    permanentAddress: userSchema?.userInfo.permanentAddress ?? "",
    department: userSchema?.department ?? "",
    relationName: userSchema?.contactRelation?.name ?? "",
    relation: userSchema?.contactRelation?.relation ?? "",
    relationPhoneNumber: userSchema?.contactRelation?.phoneNumber ?? "",
    notes: userSchema?.userInfo?.notes ?? "",
    accountNumber: userSchema?.userInfo.accountNumber ?? "",
  };
  if(payload.joinedAt)
  {
    payload.joinedAt = moment(payload.joinedAt).format('YYYY-MM-DD');
  }
  if(payload.dateOfBirth)
  {
    payload.dateOfBirth = moment(payload.dateOfBirth).format('YYYY-MM-DD');
  }
  payload.roles = payload.roles[0];
  return payload;
}
