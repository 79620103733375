import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import axios from '../utils/axios'
import {notify} from '../utils/toast'
import EmployeeCard from './EmployeeCard';
import CommonSwitch from '../CommonSwitch/CommonSwitch';

function PhysicalEmployees() {
    const [employees,setEmployees] = useState([]);
    const [isPhysical,setIsPhysical] = useState(false); 
    useEffect(()=>{
        const url = 'api/get/physicalUser';
        axios.get(url)
            .then((res)=>{
                setEmployees(res.data.data);
            })
            .catch((err)=>{
                notify("error",err.response)
            })  
    },[])
    return (
        <div className="container-fluid my-2">
            <div className="d-flex justify-content-between align-items-center">
                <h4 className='text-center my-4' style={{color:"#ff6600"}}>Employees {isPhysical?" in Office ":" Working Remotely "} Today : <span className='counter'>{isPhysical ? employees?.filter((x)=>(x.status==="physical")).length : employees?.filter((x)=>(x.status==="remote")).length }</span></h4>
                <CommonSwitch first="Remote" second="Physical" showFirst={isPhysical} setShowFirst={setIsPhysical}/>
            </div>
            {
                employees?.length > 0 ?
                    <div className="d-flex justifly-content-start gap-2 flex-wrap py-2" style={{height: "400px" , overflowY: "scroll"}}>
                    {
                        employees?.map((element)=>{
                                return ((isPhysical ? element.status === "physical":element.status === "remote") && <EmployeeCard id={element.id} title={element.first_name+" "+element.last_name} src={process.env.REACT_APP_API_URL+element.pp_image_path}/>)
                        })
                    }
                    </div>
                :
                    <div className="py-2" >
                        {
                            <h6 className='text-center'>No one Here Today ! Cause its Holiday </h6>
                        }
                    </div>
            }
        </div>
    )
}

export default PhysicalEmployees