import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./infostyles.css";
import InfoLabel from "./InfoLabel";
import { generateUserPayload } from "../../utils/payloadGenerator";
import { Button } from "@material-ui/core";

const More = ({ user }) => {
  const [editMode, setEditMode] = useState(false);
  let userInfo = generateUserPayload(user);
  const textFields = [
    "citizenshipnumber",
    "pannumber",
    "accountnumber",
    "phonenumber",
  ];
  const relationFields = ["relationname", "relation", "relationphonenumber"];
  const textAreaFields = [
    "temporaryaddress",
    "permanentaddress",
    "notes",
  ];
  const editableFields = [
    "temporaryaddress",
    "permanentaddress",
    "notes",
    "relationname",
    "relation",
    "relationphonenumber",
    "phonenumber"
  ];
  return (
    <div className="mb-3">
      {!editMode && (
        <Button
          variant="contained"
          style={{ marginBottom: "10px", width: "20px" }}
          onClick={() => {
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      )}
      {editMode && (
        <Button
          variant="contained"
          style={{ marginBottom: "10px" }}
          onClick={() => {
            setEditMode(false);
          }}
        >
          Cancel
        </Button>
      )}
      <Row>
        <h5 className="mt-1">Address and Account details</h5>
        <hr />
        <Col md={5}>
          {/* text fields */}
          {Object.keys(userInfo)
            .filter((i) => textFields.includes(i.toLowerCase()))
            .map((k) => (
              <InfoLabel
                key={k}
                label={k}
                value={userInfo[k]}
                user={userInfo}
                editMode={editMode}
                setEditMode={setEditMode}
                editableFields={editableFields}
                inputType="text"
              />
            ))}
        </Col>
        <Col md={7}>
          {/* date fields */}
          {Object.keys(userInfo)
            .filter((i) => textAreaFields.includes(i.toLowerCase()))
            .map((k) => (
              <InfoLabel
                key={k}
                label={k}
                value={userInfo[k]}
                user={userInfo}
                editMode={editMode}
                setEditMode={setEditMode}
                editableFields={editableFields}
                inputType="textarea"
              />
            ))}
        </Col>
      </Row>
      <Row>
        <h5 className="mt-1">Emergency details</h5>
        <hr />
        <Col md={5}>
          {Object.keys(userInfo)
            .filter((i) => relationFields.includes(i.toLowerCase()))
            .map((k) => (
              <InfoLabel
                key={k}
                label={k}
                value={userInfo[k]}
                user={userInfo}
                editMode={editMode}
                setEditMode={setEditMode}
                editableFields={editableFields}
                inputType="text"
              />
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default More;
