import React, { useEffect, useState } from "react"
import axios from "../utils/axios"
import WorkTable from "../WorkTable/WorkTable"
export default function MyExtraWorks() {
	const [isLoaded,setIsLoaded] = useState(false)
	const [workDetails, setWorkDetails] = useState([])
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(25)
	const [count, setCount] = React.useState()



	useEffect(async () => {
		axios
			.get(
				`api/myWorkRequest/all?page=${page}&rowsPerPage=${rowsPerPage}`
			)
			.then((response) => {
				setWorkDetails(response.data.result)
				setCount(response.data.count)
			})
			.catch((error) => console.log("error: ",error))
	}, [page, rowsPerPage, isLoaded])
	
	return (
		<div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<WorkTable
				workDetails={workDetails}
				setWorkDetails={setWorkDetails}
				page={page}
				setPage={setPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				count={count}
				setCount={setCount}
				isLoaded={isLoaded}
				setIsLoaded={setIsLoaded}
			/>
		</div>
	)
}
