const INITIAl_STATE = {
    allocations: [],
    inventoryAllocations: [],
    isLoading: true,
    errors: {}
}

export default function inventoryReducer(state=INITIAl_STATE, action){
    switch(action.type){
        case 'GET_INVENTORY_ALLOCATIONS_SUCCESS':
            return {
                ...state,
                inventoryAllocations: action.payload,
                isLoading: false
            }
        case 'CREATE_INVENTORY_ALLOCATION_SUCCESS':
            return {
                ...state,
                inventoryAllocations: [...state.inventoryAllocations, action.payload],
                isLoading: false
            } 
        case 'GET_ALL_ALLOCATIONS_SUCCESS':
            return {
                ...state,
                allocations: action.payload,
                isLoading: false
            }
        case 'GET_INVENTORY_ALLOCATIONS_ERROR':
        case 'UPDATE_INVENTORY_ALLOCATIONS_STATUS_FAILURE':
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                inventoryAllocations: []
            }    
        case 'UPDATE_PREVIOUS_ALLOCATION_SUCCESS':
        case 'INVENTORY_UNALLOCATION_SUCCESS':    
            return {
                    ...state,
                    inventoryAllocations: state.inventoryAllocations.map(all=> {
                        if(all.user.userId === action.payload.user.userId 
                            && all.isCurrentAllocation === true){
                                return action.payload
                        }
                        return all;
                    }),
                    isLoading: false
                } 
        case 'GET_ALL_ALLOCATIONS_ERROR':
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                allocations: []
            }
        default:
            return state
    }
}