import React, { useRef } from "react"
import { Box, Card, Button, makeStyles } from "@material-ui/core"
import ImageModal from "../../ImageModal/ImageModal"
import AddIcon from "@material-ui/icons/Add"
import axios from "../../utils/axios"
import { notify } from "../../utils/toast"

const useStyles = makeStyles({
	button: {
		height: "20px",
		width: 250,
		color: "white",
		backgroundColor: "#ba000d",
		position: "relative",
		top: "-85%",
		right: "2%",
		"&:hover": {
			backgroundColor: "#690007",
		},
	},
})

export default function CollapsePanel({
	leaveDescription,
	leaveDoc,
	status,
	declineMessage,
	requestId,
}) {
	const imageRef = useRef()
	const [open, setOpen] = React.useState(false)
	const [docPath, setDocPath] = React.useState()
	const handleOpen = (doc) => {
		setOpen(!open)
		setDocPath(doc)
	}
	const classes = useStyles()
	const [addedDoc, setAddedDoc] = React.useState()
	React.useEffect(() => {
		axios
			.get(`api/get/LeaveDoc/${requestId}`)
			.then((res) => setAddedDoc(res.data))
			.catch((err) => notify('error','Something went wrong !'))
	}, [])
	const handleClickAddDoc = () => {
		const imageData = imageRef.current.files[0]
		var formData = new FormData()
		formData.append("leaveDocuments", imageData)
		axios
			.post(`api/add/LeaveDoc/${requestId}`, formData)
			.then((res) => setAddedDoc(res.data))
			.catch((err) => notify('error','Something went wrong !'))

	}
	const handleClickDelete = (id) => {
		axios
			.delete(`api/delete/LeaveDoc/${id}`, { data: requestId })
			.then((res) => setAddedDoc(res.data))
			.catch((err) => notify('error','Something went wrong !'))
	}
	return (
		<>
			<Box
				style={{
					boxShadow:
						(status === "Approved" && "2px 2px 35px 1px green") ||
						(status === "Pending" && "2px 2px 35px 1px #1769aa") ||
						(status === "Rejected" && "2px 2px 35px 1px #ba000d"),
				}}
			>
				<br />
				<h6
					style={{
						marginLeft: "1%",
						color:
							(status === "Approved" && "green") ||
							(status === "Rejected" && "#ba000d") ||
							(status === "Pending" && "#1769aa"),
						textAlign: "left",
					}}
				>
					Leave Description:{" "}
				</h6>
				<p
					style={{
						marginLeft: "3%",
						color:
							(status === "Approved" && "green") ||
							(status === "Rejected" && "#ba000d") ||
							(status === "Pending" && "#1769aa"),
						textAlign: "left",
					}}
				>
					<i>{leaveDescription}</i>
				</p>

				{declineMessage && (
					<h6 style={{ marginLeft: "1%", color: "#ba000d", textAlign: "left" }}>
						Decline Message
					</h6>
				)}
				{declineMessage && (
					<p style={{ marginLeft: "3%", color: "#ba000d", textAlign: "left" }}>
						<i>{declineMessage}</i>
					</p>
				)}
				<h6
					style={{
						marginLeft: "1%",
						color:
							(status === "Approved" && "green") ||
							(status === "Rejected" && "#ba000d") ||
							(status === "Pending" && "#1769aa"),
						textAlign: "left",
					}}
				>
					Leave Documents
				</h6>

				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						rowGap: "2em",
						columnGap: "1%",
					}}
				>
					{addedDoc &&
						addedDoc.map(
							(ldoc) =>
								ldoc.documents_path && (
									<div style={{ marginLeft: "4%" }}>
										<Card
											style={{
												width: 300,
												height: 250,
												boxShadow: "2px 2px 42px 2px gray",
											}}
										>
											<Button
												variant="contained"
												color="primary"
												onClick={() => handleClickDelete(ldoc.id)}
												className={classes.button}
											>
												Delete
											</Button>
											<img
												onClick={() => handleOpen(ldoc.documents_path)}
												src={
													process.env.REACT_APP_API_URL + ldoc.documents_path
												}
												style={{ width: "100%", height: "100%" }}
												alt=""
											/>
										</Card>
									</div>
								)
						)}
					{status !== "Rejected" && (
						<Button
							component="label"
							variant="outlined"
							color="primary"
							style={{
								width: 300,
								height: 250,
								marginLeft: "4%",
							}}
						>
							<AddIcon />
							<input
								type="file"
								hidden
								ref={imageRef}
								onChange={handleClickAddDoc}
							/>
						</Button>
					)}
				</div>

				<br />
			</Box>

			<ImageModal open={open} handleOpen={handleOpen} leaveDoc={docPath} />
		</>
	)
}
