import React from "react"
import { Col } from "react-bootstrap"
import axios from "../utils/axios"
import {
	List,
	ListItem,
	makeStyles,
} from "@material-ui/core"
import CommonSwitch from "../CommonSwitch/CommonSwitch"
import CustomCard from "./CustomCard"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		// maxWidth: "36ch",
	},
	inline: {
		display: "inline",
	},
}))

export default function TodaysLeavePaper() {
	const [leavePast, setLeavePast] = React.useState([])
	const [leaveFuture, setLeaveFuture] = React.useState([])
	const classes = useStyles()
	const [showPastLeaves, setShowPastLeaves] = React.useState(false);
	React.useEffect(() => {
		let isMounted=true;
		axios
			.get("api/past/leave")
			.then((res) => {
				if(isMounted){
					setLeavePast(res.data);
				}
			})
			.catch((err) => console.log(err))
		return ()=>{
			isMounted=false;
		}
	}, [])
	React.useEffect(() => {
		let isMounted=true;
		axios
			.get("api/future/leave")
			.then((res) => {
				if(isMounted){
					setLeaveFuture(res.data);
				}
			})
			.catch((err) => console.log(err))
		return ()=>{
			isMounted=false;
		}
	}, [])
	const calculateDays = (date) => {
		let date_1 = new Date(date);
		let date_2 = new Date();
		let difference = date_1.getTime() - date_2.getTime();
		let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
		return TotalDays;
	}
	return (
		<>
			<div className="d-flex justify-content-between">
				<h4 style={{ color: "#ff6600" }}>Employee's On Leave</h4>
				<CommonSwitch first="Upcoming" second="Past" showFirst={showPastLeaves} setShowFirst={setShowPastLeaves} />

			</div>
			<div style={{ maxHeight: 400, overflow: "scroll", width: "100%" }}>
				<List className={classes.root}>
					{showPastLeaves ? 
						((leavePast && leavePast.length) !==0 ?
							(leavePast.map((lt,key) => (
								<CustomCard key={key} title={lt.first_name + " " + lt.last_name} src={process.env.REACT_APP_API_URL + lt.pp_image_path} date_of_leave={lt.date_of_leave} leave_mode={lt.leave_mode} leave_type_name={lt.leave_type_name} period_of_day={lt.period_of_day} diff_days={calculateDays(lt.date_of_leave)} leave_count={lt.leave_count}/>
							))
							):
							(
								<div
									style={{
										border: "1px solid #f5c2c7",
										borderRadius: "4px",
										backgroundColor: "#f8d7da",
										height: 65,
										width: "100%",
									}}
								>
									<ListItem>
										<h6 style={{ color: "#842029", marginTop: "2%" }}>
											No one was on leave last 30 days!
										</h6>
									</ListItem>
								</div>
							)
						)
					: 
						((leaveFuture && leaveFuture.length !== 0) ?
							(leaveFuture.map((lt,key) => (
								<CustomCard key={key} title={lt.first_name + " " + lt.last_name} src={process.env.REACT_APP_API_URL + lt.pp_image_path} date_of_leave={lt.date_of_leave} leave_mode={lt.leave_mode} leave_type_name={lt.leave_type_name} period_of_day={lt.period_of_day} diff_days={calculateDays(lt.date_of_leave)<0?0:calculateDays(lt.date_of_leave)} leave_count={lt.leave_count}/>
							)))
							:(
								<div
									style={{
										border: "1px solid #f5c2c7",
										borderRadius: "4px",
										backgroundColor: "#f8d7da",
										height: 65,
										width: "100%",
									}}
								>
									<ListItem>
										<h6 style={{ color: "#842029", marginTop: "2%" }}>
											No one is on leave for coming 30 days!
										</h6>
									</ListItem>
								</div>
							)
						)
					}
				</List>
			</div>
		</>
	)
}
