import React, { useEffect } from "react"
import { withStyles, makeStyles } from "@material-ui/core"
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"

import axios from "../../utils/axios"
import LeaveDetails from "./LeaveDetails"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
		width: "15px",
	},
	body: {
		textAlign: "center",
		width: "15px",
	},
}))(TableCell)
const useStyles = makeStyles({
	tableContainer: {
		marginTop: "1%",
	},
})

export default function LeaveSummary({id}) {
	const classes = useStyles()
	// const [leaveTypes, setLeaveTypes] = React.useState()
	var currDate = new Date()
	var currMonth = currDate.getMonth()
	const [searchBy, setSearchBy] = React.useState({})
	const [leaveStat, setLeaveStat] = React.useState()
	const [showHour, setShowHour] = React.useState(false)
	React.useEffect(() => {
		axios
			.get("api/leaveTypes")
			.then((res) => {
				// setLeaveTypes(res.data)
				searchBy['fiscalStartYear'] = parseInt(currMonth < 6 ? new Date().getFullYear() - 1 : new Date().getFullYear())
				searchBy['fiscalEndYear'] = parseInt(currMonth < 6 ? new Date().getFullYear() : new Date().getFullYear() + 1);
				searchBy['leaveType'] = parseInt(res.data[0].id)
                searchBy['userId'] = id
				return axios.post("api/get/leaveStat/aUser", searchBy)
			})
			.then((res) => {
                setLeaveStat(JSON.parse(res.data))
            })

            
	}, [])

	const handleChange = (e) => {
		setSearchBy({ ...searchBy, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		searchBy["fiscalEndYear"] = parseInt(searchBy["fiscalStartYear"]) + 1
		axios
			.post("api/get/leaveStat/aUser", searchBy)
			.then((res) => setLeaveStat(JSON.parse(res.data)))
	},[searchBy])

	return (
		<div>
            <div>
                <div><h3 style={{color:"#ff6600"}}>Leave Summary</h3></div>
                <TableContainer className={classes.tableContainer}>
                    <div className="row" style={{ color: "#ff6600", marginBottom: "2%" }}>
                        <div className="col-3">
                            <SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
                        </div>
                        <div className="col-3">
                            <label htmlFor="FiscalYearStart"> Start Year:</label>
                            <input
                                type="number"
                                min="2010"
                                max="2099"
                                step="1"
                                value={parseInt(searchBy.fiscalStartYear) ||""}
                                onChange={handleChange}
                                name="fiscalStartYear"
                                style={{ float: "right", width: "60%" }}
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="FiscalYearEnd"> End Year:</label>
                            <input
                                type="number"
                                readOnly
                                value={parseInt(searchBy.fiscalStartYear) + 1 || ""}
                                name="fiscalEndYear"
                                style={{ float: "right", width: "60%" }}
                            />
                        </div>
                        {/* <div className="col-2">
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ float: "right", marginRight: "15%", color: "white" }}
                                onClick={handleClickSearch}
                            >
                                <SearchIcon />
                                Search
                            </Button>
                        </div> */}
                    </div>

                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Leave Type</StyledTableCell>
                                <StyledTableCell>Allowed</StyledTableCell>
                                <StyledTableCell>Used</StyledTableCell>
                                <StyledTableCell>Remaining</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leaveStat &&
                                leaveStat.map((ls) => (
                                    <TableRow key={Math.random()}> 
                                        <StyledTableCell>
                                            {ls.leaveTypeName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {showHour ? ls.leaveTotalHours : ls.leaveTotalDays}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {showHour ? ls.usedHour : ls.usedDay}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {showHour ? ls.remainingHour : ls.remainingDay}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{marginTop:"25px"}}>
                <div><h3 style={{color:"#ff6600"}}>Leave Details</h3></div>
                <LeaveDetails fromSummary={true} userId={id}/>
            </div>
		</div>
	)
}
