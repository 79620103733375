import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"

import { withStyles } from "@material-ui/styles"
import {
	makeStyles,
	TableFooter,
	TablePagination,
} from "@material-ui/core"
import axios from "../../utils/axios"
import CollapseRequestsTable from "./CollapsibleTable/CollapseRequestsTable"
import TablePaginationActions from "../../TablePaginationActions/TablePaginationActions"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"
import { notify } from "../../utils/toast"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 900,
	},
	tableContainer: {
		width: "100%",
	},
})

export default function AllWorkFromHomeRequestTable() {
	const classes = useStyles()
	const [page, setPage] = React.useState(0)
	const [allWorkData, setAllWorkData] = React.useState()
	const [isLoaded, setIsLoaded] = React.useState(false)
	const [rowsPerPage, setRowsPerPage] = React.useState(25)
	const [count, setCount] = React.useState()
	const [loggedInId, setLoggedInId] = React.useState()
	const [showHour, setShowHour] = React.useState(false)
	React.useEffect(() => {
		axios
			.get(
				`api/pending/WFHRequest/userProfile?rowsPerPage=${rowsPerPage}&count=${count}`
			)
			.then((res) => {
				setAllWorkData(res.data.result) 
				setCount(res.data.count)
				setLoggedInId(res.data.userId)
			})
			.catch((err) => notify('error',err.response.data))
	}, [page, setPage, rowsPerPage, isLoaded])

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage((event.target.value, 10))
		setPage(0)
	}

	return (
		<div>
			<div className="d-flex justify-content-between">
				<h4 style={{ color: "#ff6600" }}>Work From Home Requests</h4>
				<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
			</div>
			<TableContainer className={classes.tableContainer}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Employee</StyledTableCell>
							<StyledTableCell>Start Date</StyledTableCell>
							<StyledTableCell>Start Time</StyledTableCell>
							<StyledTableCell>End Date</StyledTableCell>
							<StyledTableCell>End Time</StyledTableCell>
							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Approvers</StyledTableCell>
							{/* <StyledTableCell>Status</StyledTableCell> */}
							<StyledTableCell>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{allWorkData &&
							allWorkData.map(
								(data,key) =>
									data.complementary_status !== "Approved" && (
										<CollapseRequestsTable
											key={key}
											data={data}
											loggedInId={loggedInId}
											setIsLoaded={setIsLoaded}	
											isLoaded={isLoaded}										
											setCount={setCount}
											page={page}
											rowsPerPage={rowsPerPage}
											setLoggedInId={setLoggedInId}
											setAllWorkData={setAllWorkData}
											showHour={showHour}
										/>
									)
							)}

						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={1} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[25, 50, 100]}
								colSpan={8}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	)
}
