import React, { useEffect, useState } from "react"
import moment from 'moment'
import {
	Person,
	Email,
	Work,
	Business,
	Home,
	Event,
	AccountBalance,
	AddIcCall,
	AccessibilityNew,
	CardMembership,
} from "@material-ui/icons"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import EditIcon from "@material-ui/icons/Edit"
import { fetchUser, updateUser } from "../Redux/actions/userActions"
import { useParams } from "react-router-dom"
import Spinner from "../Spinner/Spinner"
import { useHistory } from "react-router-dom"
import { Button } from "@material-ui/core"
import {
	FormControlLabel,
	Radio,
	FormLabel,
	FormControl,
	RadioGroup,
} from "@material-ui/core"
import FormInput from "../Common/Input/FormInput"
import { notify } from "../utils/toast"
import ButtonLoader from "../ButtonLoader/ButtonLoader"
import MultipleSelectInput from './MultipleSelectInput/MultipleSelectInput'
import axios from '../utils/axios';

export default function EditUser() {
	const role = useSelector((store) => store.authReducer.user.roles[0]);
	const [loading, setLoading] = useState(false);
	const [supervisorOption, setSupervisorOption] = useState([]);
	const params = useParams()
	const [roles,setRoles] = useState([])

	//userData form state
	const [userData, setUserData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		designation: "",
		roles: "",
		joinedAt: "",
		probationEndAt: "",
		employeeId: "",
		dateOfBirth: "",
		citizenshipNumber: "",
		panNumber: "",
		gender: "",
		phoneNumber: "",
		temporaryAddress: "",
		permanentAddress: "",
		department: "",
		relationName: "",
		relation: "",
		relationPhoneNumber: "",
		notes: "",
		accountNumber: "",
		supervisors: []
	})
	const dispatch = useDispatch()
	const userReducer = useSelector((store) => store.userReducer)
	const supervisors = useSelector((store) => store.userReducer.supervisors)
	let { user: storedUser, isLoading, errors } = userReducer
	let user = JSON.parse(JSON.stringify(storedUser))

	if (user) {
		user.roles = user ? user.roles[0] : ""
		user.citizenshipNumber = user?.userInfo?.citizenshipNumber
		user.panNumber = user?.userInfo?.panNumber
		user.phoneNumber = user?.userInfo?.phoneNumber
		user.temporaryAddress = user?.userInfo?.temporaryAddress
		user.permanentAddress = user?.userInfo?.permanentAddress
		user.relationName = user?.contactRelation?.name
		user.relation = user?.contactRelation?.relation
		user.relationPhoneNumber = user?.contactRelation?.phoneNumber
		user.notes = user?.userInfo?.notes
		user.accountNumber = user?.userInfo?.accountNumber
	}
	const history = useHistory()

	useEffect(()=>{
		// for approvers
		axios
		.get("api/get/allApprovers")
		.then()
		.then((res)=>{
			setSupervisorOption(res.data);
		})

		// for roles option
		axios.get('api/get/role')
        .then((res)=>{
            setRoles(res.data);
        })
        .catch((err)=>{
            notify("error","Something went wrong")
        })
	},[])

	useEffect(() => {
		dispatch(fetchUser(params.id))
	}, [dispatch, params.id])

	useEffect(() => {
		setUserData({
			...userData,
			firstName: user?.firstName ?? "",
			lastName: user?.lastName ?? "",
			email: user?.email ?? "",
			designation: user?.designation ?? "",
			roles: user?.roles ? user.roles : "",
			joinedAt: user?.joinedAt ?? "",
			probationEndAt: user?.probationEndAt ?? "",
			employeeId: user?.employeeId,
			dateOfBirth: user?.dateOfBirth ?? "",
			citizenshipNumber: user?.userInfo.citizenshipNumber ?? "",
			panNumber: user?.userInfo.panNumber ?? "",
			gender: user?.gender ?? "",
			phoneNumber: user?.userInfo.phoneNumber ?? "",
			temporaryAddress: user?.userInfo.temporaryAddress ?? "",
			permanentAddress: user?.userInfo.permanentAddress ?? "",
			department: user?.department ?? "",
			relationName: user?.contactRelation?.name ?? "",
			relation: user?.contactRelation?.relation ?? "",
			relationPhoneNumber: user?.contactRelation?.phoneNumber ?? "",
			notes: user?.userInfo?.notes ?? "",
			accountNumber: user?.userInfo.accountNumber ?? "",
			supervisors: supervisors
		})
		// eslint-disable-next-line
	}, [storedUser,supervisors])

	// user form functions
	function handleChange(e) {
		const { name, value } = e.target
		setUserData({ ...userData, [name]: value })
	}

	function checkUpdated(userData, user) {
		//Check if updated
		let updated = false
		for (let key of Object.keys(userData)) {
			if (user[key] === userData[key] || (!user[key] && userData[key] === "")) {
				updated = false
			} else {
				updated = true
				break
			}
		}
		return updated
	}

	function handleSubmit(e) {
		e.preventDefault();
		const updated = checkUpdated(userData, user)
		if (updated) {
			setLoading(true);
			dispatch(updateUser(params.id, userData, history, setLoading, role))
		} else {
			notify("error", "no changes made!")
		}
	}
	if (isLoading) return <Spinner />
	return (
		<>
			<div className="border container pb-5" >
				<div
					className="card-header text-white text-center mx-2 my-2"
					style={{ backgroundColor: "#ff6600" }}
				>
					<EditIcon /> Edit User
				</div>
				<form  onSubmit={handleSubmit} noValidate>
					<div className="container mx-2 my-2">
						<div  className="row justify-content-center align-items-center">
							<div className="mt-2">
								<h4>Basic Information</h4>
								<hr />
							</div>
							<FormInput
								type="text"
								label="First Name"
								name="firstName"
								value={userData.firstName}
								handleChange={handleChange}
								Icon={<Person />}
								required
								feedback={errors?.firstName}
							/>
							<FormInput
								type="text"
								label="Last Name"
								name="lastName"
								Icon={<Person />}
								value={userData.lastName}
								handleChange={handleChange}
								required
								feedback={errors?.lastName}
							/>
							<div className="col-lg-4">
								<FormControl component="fieldset">
									<FormLabel component="label">Gender</FormLabel>
									<RadioGroup
										row
										aria-label="gender"
										name="gender"
										value={userData.gender}
										onChange={handleChange}
									>
										<FormControlLabel
											value="female"
											control={<Radio />}
											label="Female"
										/>
										<FormControlLabel
											value="male"
											control={<Radio />}
											label="Male"
										/>
									</RadioGroup>
								</FormControl>
							</div>
							<FormInput
								name="email"
								type="email"
								value={userData.email}
								Icon={<Email />}
								label="Email"
								handleChange={handleChange}
								required
								feedback={errors?.email}
							/>

							<FormInput
								type="date"
								label="Date of birth(AD)"
								name="dateOfBirth"
								value={moment(userData.dateOfBirth).format('YYYY-MM-DD')}
								handleChange={handleChange}
								required
								feedback={errors?.dateOfBirth}
							/>

							<FormInput
								type="text"
								label="Phone Number"
								name="phoneNumber"
								value={userData.phoneNumber}
								handleChange={handleChange}
								feedback={errors?.phoneNumber}
								required
							/>
						</div>
						<div className="row">
							<div className="mt-2">
								<h4>Correspondence Address</h4>
								<hr />
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2 ">
									<label>
										<Home />
										Permanent Address<small className="text-danger">*</small>
									</label>
									<textarea
										className="form-control"
										col-lg="10"
										rows="3"
										name="permanentAddress"
										value={userData.permanentAddress}
										onChange={handleChange}
									></textarea>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2 ">
									<label>
										<Home />
										Temporary Address<small className="text-danger">*</small>
									</label>
									<textarea
										className="form-control"
										rows="3"
										label="Temporary Address"
										name="temporaryAddress"
										value={userData.temporaryAddress}
										onChange={handleChange}
									></textarea>
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="mt-2">
								<h4>Official Details</h4>
								<hr />
							</div>
							<FormInput
								type="text"
								label="Citizenship Number"
								name="citizenshipNumber"
								value={userData.citizenshipNumber}
								handleChange={handleChange}
								Icon={<CardMembership />}
								min={0}
							/>
							<FormInput
								type="text"
								label="PAN Number"
								name="panNumber"
								value={userData.panNumber}
								handleChange={handleChange}
								Icon={<CardMembership />}
								min={0}
							/>
							<FormInput
								type="text"
								label="Bank A/C Number"
								name="accountNumber"
								value={userData.accountNumber}
								Icon={<AccountBalance />}
								min={0}
								handleChange={handleChange}
							/>
							<FormInput
								type="text"
								className="form-control"
								label="Employee ID"
								name="employeeId"
								value={userData.employeeId}
								handleChange={handleChange}
								Icon={<Person />}
								required
								feedback={errors?.employeeId}
							/>
							<div className="col-lg-4">
								<div className="form-group my-2 ">
									<label>
										<Work />
										Designation<small className="text-danger">*</small>
									</label>
									<select
										className="form-control"
										id="inputState"
										name="designation"
										value={userData.designation}
										onChange={handleChange}
									>
										<option value="Engineer">Engineer</option>
										<option value="CTO">CTO</option>
										<option value="HR">HR</option>
										<option value="Intern">Intern</option>
										<option value="Employee">Employee</option>
									</select>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group my-2 ">
									<label>
										<Business />
										Department<small className="text-danger">*</small>
									</label>
									<select
										className="form-control"
										id="inputState"
										name="department"
										value={userData.department}
										onChange={handleChange}
										required
									>
										<option>IT</option>
										<option>Admin</option>
										<option>HR</option>
										<option>Engineering</option>
										<option>Operation</option>
									</select>
								</div>
							</div>

							<FormInput
								type="date"
								label="Joined At"
								Icon={<Event />}
								name="joinedAt"
								value={moment(userData.joinedAt).format('YYYY-MM-DD')}
								handleChange={handleChange}
								required
								feedback={errors?.joinedAt}
							/>
							<FormInput
								type="date"
								name="probationEndAt"
								label="Probation End At"
								Icon={<Event />}
								value={userData.probationEndAt}
								handleChange={handleChange}
							/>
						</div>
						<div className="row mt-4">
							<div className="mt-2">
								<h4>Emergency Details</h4>
								<hr />
							</div>
							<FormInput
								type="text"
								label="Full Name"
								name="relationName"
								value={userData.relationName}
								Icon={<AccessibilityNew />}
								handleChange={handleChange}
							/>
							<FormInput
								type="text"
								className="form-control"
								label="Relation"
								name="relation"
								value={userData.relation}
								Icon={<AccessibilityNew />}
								handleChange={handleChange}
							/>
							<FormInput
								type="text"
								label="Emergency Contact Number"
								className="form-control"
								name="relationPhoneNumber"
								value={userData.relationPhoneNumber}
								handleChange={handleChange}
								Icon={<AddIcCall />}
								feedback={errors?.relationPhoneNumber}
							/>
						</div>
						<div className="row">
								<h4>Authority</h4>
								<hr />
								<div className="form-group my-2 col-6">
									<label>
										Role<small className="text-danger">*</small>
									</label>
									<select
										type="select"
										name="roles"
										value={userData.roles}
										onChange={handleChange}
										className="form-control"
									>
										{/* <option value="ROLE_USER">User</option>
										<option value="ROLE_ADMIN">Admin</option> */}
										{
											roles.map((role)=>
												<option value={role.name}>{role.name}</option>
											)
										}
									</select>
								</div>
								<div className="form-group my-2 col-6">
									<MultipleSelectInput
										supervisorDetails={userData}
										setSupervisorDetails={setUserData}
										supervisorOption={supervisorOption}
									/>
								</div>
						</div>
						<hr />
						<div className="form-group my-2">
							<label>Notes:</label>
							<textarea
								className="form-control"
								col-lg="10"
								rows="3"
								name="notes"
								value={userData.notes}
								onChange={handleChange}
							></textarea>
						</div>
						<div className="form-group my-4">
							<Button type="submit" color="primary" variant="outlined">
							{ loading ? <ButtonLoader color="orange"/> : "Edit"}
							</Button>
							<Link
								to={`/dashboard/users/${params.id}`}
								style={{ textDecoration: "none", margin: "8px" }}
							>
								<Button color="secondary" variant="outlined">
									Cancel
								</Button>
							</Link>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}
