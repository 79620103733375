import { Avatar } from '@material-ui/core'
import React from 'react'
function EmployeeCard({id,title,src}) {
    return (
        <div key={id} className='d-flex gap-1 flex-row border rounded-pill align-items-center' style={{color:"#888888",height: "3rem"}}>
        {console.log("src:",src)}
        <div className="avatar">
            <Avatar
                alt={title}
                src={src}
                style={{height: "3rem",width: "3rem"}}
            />
        </div>
        <h6 className='m-2'>{title}</h6>
    </div>
  )
}

export default EmployeeCard