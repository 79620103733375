import React, { useEffect } from "react"
import { withStyles, makeStyles } from "@material-ui/core"
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core"
import { Avatar } from "@material-ui/core"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"

import axios from "../../utils/axios"
import { notify } from "../../utils/toast"
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
		width: "15px",
	},
	body: {
		textAlign: "center",
		width: "15px",
	},
}))(TableCell)
const useStyles = makeStyles({
	tableContainer: {
		marginTop: "1%",
	},
})

export default function AllLeaveStatistics() {
	const classes = useStyles()

	const [leaveTypes, setLeaveTypes] = React.useState()
	var currDate = new Date()
	var currMonth = currDate.getMonth()
	const [searchBy, setSearchBy] = React.useState({})
	const [leaveStat, setLeaveStat] = React.useState()
	const [showHour, setShowHour] = React.useState(false)
	React.useEffect(() => {
		axios
			.get("api/leaveTypes")
			.then((res) => {
				setLeaveTypes(res.data)
				searchBy['fiscalStartYear'] = parseInt(currMonth < 6 ? new Date().getFullYear() - 1 : new Date().getFullYear())
				searchBy['fiscalEndYear'] = parseInt(currMonth < 6 ? new Date().getFullYear() : new Date().getFullYear() + 1);
				searchBy['leaveType'] = parseInt(res.data[0].id)
				return axios.post("api/get/leaveStat/allUser", searchBy)
			})
			.then((res) => setLeaveStat(res.data))
			.catch((err) => 
			notify('error',err.response.data))
	}, [])

	const handleChange = (e) => {
		setSearchBy({ ...searchBy, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		searchBy["fiscalEndYear"] = parseInt(searchBy["fiscalStartYear"]) + 1
		axios
			.post("api/get/leaveStat/allUser", searchBy)
			.then((res) => setLeaveStat(res.data))
			.catch((err) => notify('error',err.response.data))
	},[searchBy])

	return (
		<div>{console.log("here")}
			<div className="d-flex justify-content-between">
				<h4 style={{ color: "#ff6600" }}>Leave Statistics of Employees</h4>
				<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
			</div>

			<TableContainer className={classes.tableContainer}>
				<div className="row" style={{ color: "#ff6600", marginBottom: "2%" }}>
					<div className="col-4">
						<label htmlFor="LeaveTypes">Leave Types:</label>

						<select
							name="leaveType"
							className="form-control"
							style={{ width: "60%", float: "right" }}
							value={searchBy.leaveType}
							onChange={handleChange}
						>
							{/* <option value="" selected>
								Choose Leave Type
							</option> */}
							{leaveTypes &&
								leaveTypes.map((lt) => (
									<option  key={lt.id} value={lt.id}>{lt.leaveTypeName}</option>
								))}
						</select>
					</div>
					<div className="col-3">
						<label htmlFor="FiscalYearStart"> Start Year:</label>
						<input
							type="number"
							min="2010"
							max="2099"
							step="1"
							className="form-control"
							value={parseInt(searchBy.fiscalStartYear) ||""}
							onChange={handleChange}
							name="fiscalStartYear"
							style={{ float: "right", width: "60%" }}
						/>
					</div>
					<div className="col-3">
						<label htmlFor="FiscalYearEnd"> End Year:</label>
						<input
							type="number"
							readOnly
							className="form-control"
							value={parseInt(searchBy.fiscalStartYear) + 1 || ""}
							name="fiscalEndYear"
							style={{ float: "right", width: "60%" }}
						/>
					</div>
					{/* <div className="col-2">
						<Button
							color="primary"
							variant="contained"
							style={{ float: "right", marginRight: "15%", color: "white" }}
							onClick={handleClickSearch}
						>
							<SearchIcon />
							Search
						</Button>
					</div> */}
				</div>

				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Employee</StyledTableCell>
							<StyledTableCell>Allowed</StyledTableCell>
							<StyledTableCell>Used</StyledTableCell>
							<StyledTableCell>Remaining</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{leaveStat &&
							leaveStat.map((ls) => (
								<TableRow key={Math.random()}>
									<StyledTableCell>
										<div style={{ display: "flex", marginLeft: "30%" }}>
											<Avatar
												src={process.env.REACT_APP_API_URL + ls.profileP}
												alt={ls.userName}
												style={{ marginRight: "5%" }}
											/>
											<span style={{ marginTop: "2.5%" }}>{ls.userName}</span>
										</div>
									</StyledTableCell>
									<StyledTableCell>
										{showHour ? ls.leaveTotalHours : ls.leaveTotalDays}
									</StyledTableCell>
									<StyledTableCell>
										{showHour ? ls.usedHour : ls.usedDay}
									</StyledTableCell>
									<StyledTableCell>
										{showHour ? ls.remainingHour : ls.remainingDay}
									</StyledTableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
