import React from "react";
import "./inventoryCard.css";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import UnAllocate from "../Allocation/UnAllocate";

const InventoryCard = ({ inventory }) => {
  const permissions = useSelector((store)=> store.authReducer.permissions);
  return (
    <>
      <div class="folded mt-5 me-4" >
        <h2 className='sticker-label'>{inventory.inventoryId}</h2>
        <img
          className="inventory-image"
          src={
            inventory.imagePath
              ? process.env.REACT_APP_API_URL + inventory.imagePath
              : "/picture.jpg"
          }
          alt="inventory"
        />
        <div className="details">
          <div className="row">
            <div className="col-5">
              <h6>Name:</h6>
            </div>
            <div className="col">
              <span className="text-info">{inventory.name}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h6>Model:</h6>
            </div>
            <div className="col">
              <span className="text-info">{inventory.model || "-"}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h6>Brand:</h6>
            </div>
            <div className="col">
              <span className="text-info">{inventory.brand || "-"}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <h6>Category:</h6>
            </div>
            <div className="col">
              <span className="text-info">{inventory.category.name}</span>
            </div>
          </div>
        </div>
        <div className="linkContainer">
          {
            permissions?.includes("view_inventory_details") &&
            <Link
              to={
                `/dashboard/inventories/${inventory.inventoryId}`
              }
              style={{ textDecoration: "none" }}
            >
              <Button color="primary" variant="outlined" type="submit">
                View more
              </Button>
            </Link>
          }
          {(permissions?.includes("allocate_inventory")) && (
            <UnAllocate id={inventory.inventoryId} />
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryCard;
