import React from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useState } from "react";
import { useEffect } from "react";

const AddRoleModal = (props) => {
	const { open, setOpen, onCancel, onConfirm } = props;
	const [role,setRole] = useState(props.defRole.name ?? "");
	const [roleId,setRoleId] = useState(props.defRole.id ?? "");
	const [action,setAction] = useState("");

	useEffect(()=>{
		// console.log("role:",role);
		// console.log("roleId:",roleId);
		(role === "") ? setAction("add"):setAction("edit");
		// setRole();
		// setRoleId();
	},[props]);
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="dialog-title"
			className="p-5"
		>
			<h2 className="text-center my-3" style={{color: '#ff6600'}}>{action === "edit" ? "Edit Role" : "Add New Role"}</h2>
			<form className="m-5">
				<input type="text" name="role" className="form-control" defaultValue={role} onChange={(e)=>{
					setRole(e.target.value)
				}}/>
				<label htmlFor="role">Please enter in format ROLE_Label.</label>
			</form>
			<DialogActions>
				<Button autoFocus onClick={() => {setOpen(false);onCancel();}} color="primary">
					Cancel
				</Button>
				<Button
					onClick={() => {
						setOpen(false);
						onConfirm(action,role,roleId);
					}}
					color="primary"
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
		// </div>
	);
};

export default AddRoleModal;
