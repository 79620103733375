import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sendToServicing } from "../Redux/actions/servicingAction";
import { Formik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
}));
const SendServicingModal = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inventoryReducer = useSelector((store) => store.inventoryReducer);
  const { inventory } = inventoryReducer;

  return (
    <div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpen}
          style={{ margin: "5px" }}
        >
          {children}
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h4
                id="transition-modal-title"
                className="text-center"
                style={{ color: "#ff6600" }}
              >
                Servicing details
              </h4>
              <p id="transition-modal-description">
                <Formik
                  initialValues={{ sentAt: "" }}
                  validationSchema={Yup.object({
                    sentAt: Yup.date().required("Required").max(new Date(), "Date cannot be future").min(moment(inventory.procurredAt).format('YYYY-MM-DD'),"Servicing date cannot be before procurement date:"+moment(inventory.procurredAt).format('YYYY-MM-DD')),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setLoading(true);
                    dispatch(sendToServicing(params.id, values, setLoading));
                    handleClose();
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3">
                        <Form.Label>Sent date</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter date here"
                          name="sentAt"
                          value={values.sentAt}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <p className="text-danger">
                          {errors.sentAt && touched.sentAt && errors.sentAt}
                        </p>
                      </Form.Group>

                      <Button
                        color="primary"
                        variant="outlined"
                        type="submit"
                        disableElevation
                      >
                        { loading ? <ButtonLoader color="orange"/> : "Submit"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </p>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default SendServicingModal;
