import React, { useEffect, useRef, useState } from "react"
import {
	Store,
	Person,
	BusinessCenter,
	CropOriginal,
	Filter9Plus,
	Note,
} from "@material-ui/icons"
import PaymentIcon from "@material-ui/icons/Payment"
import CategoryIcon from "@material-ui/icons/Category"
import { addInventory } from "../Redux/actions/inventoryactions"
import { useDispatch, useSelector } from "react-redux"
import BuildIcon from "@material-ui/icons/Build"
import { Link, useHistory } from "react-router-dom"
import { Button } from "@material-ui/core"
import axios from "../utils/axios"
import AddCategoryModal from "../AddCategoryModal/AddCategoryModal"
import PhotoIcon from "@material-ui/icons/Photo"
import { validateFile } from "../utils/validateFile"
import { notify } from "../utils/toast"
import ButtonLoader from "../ButtonLoader/ButtonLoader"

const AddInventoryForm = () => {
	const [loading, setLoading] = useState(false);
	const [costError, setCostError] = useState(null);
	const imageRef = useRef()
	//user form state
	const [inventory, setInventory] = useState({
		name: "",
		brand: "",
		model: "",
		category: "",
		inventoryId: "",
		servicingDuration: 6,
		cost: "",
		procurredAt: "",
		notes: "",
		isAllocatable: true,
		requiresServicing: false,
	})
	const [categories, setCategories] = useState([])
	useEffect(() => {
		axios
			.get("/api/categories")
			.then((res) => {
				setCategories(res.data.result)
				setInventory({ ...inventory, category: res.data.result[0].name })
			})
			.catch((err) => notify('error',"Something went wrong !"))
		// eslint-disable-next-line
	}, [])

	const dispatch = useDispatch()
	const history = useHistory()
	const errors = useSelector((store) => store.inventoryReducer.errors) || {}

	// user form functions
	function handleChange(e) {
		const { name, value } = e.target
		if(name === "cost" && value < 0){
			setCostError("Value Cannot be negative");  
		  } else {
			setCostError(null);
		  }
		setInventory({ ...inventory, [name]: value })
	}

	function handleSubmit(e) {
		e.preventDefault();
		// check cost is negative
		if(costError){
			return;
		}
		setLoading(true);
		var formData = new FormData()
		var imagedata = imageRef.current.files[0]
		if (imagedata) {
			let error = validateFile(imagedata)
			if (Object.keys(error).length !== 0) {
				notify("error", (error.type || "") + " " + (error.size || ""), 4000)
				return
			}
		}
		formData.append("image", imagedata)
		for (var key in inventory) {
			formData.append(key, inventory[key])
		}
		dispatch(addInventory(formData, history, setLoading))
	}
	function handleSelectChange(e) {
		const { name } = e.target
		if (name === "isAllocatable")
			setInventory({ ...inventory, isAllocatable: !inventory.isAllocatable })
		else if (name === "requiresServicing") {
			setInventory({
				...inventory,
				requiresServicing: !inventory.requiresServicing,
			})
		}
	}
	return (
		<>
			<div className="border container mb-5" data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
				<div
					className="card-header text-white text-center mx-2 my-2"
					style={{ backgroundColor: "#ff6600" }}
				>
					<Store /> Add New Inventory
				</div>
				<form onSubmit={handleSubmit} noValidate>
					<div className="container mx-2 my-2">
						<div className="row">
							<div className="form-group my-2 col-lg-4">
								<label>
									<Person />
									Name <small className="text-danger">*</small>
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Inventory Name"
									name="name"
									value={inventory.value}
									onChange={handleChange}
								></input>
								{errors?.name ? (
									<small className="text-danger">{errors.name}</small>
								) : null}
							</div>
							<div className="form-group my-2 col-lg-4">
								<label>
									<BusinessCenter />
									Brand
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Brand Name"
									name="brand"
									value={inventory.brand}
									onChange={handleChange}
								></input>
							</div>
							<div className="form-group my-2 col-lg-4">
								<label>
									<CropOriginal />
									Model
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Model Name"
									name="model"
									value={inventory.model}
									onChange={handleChange}
								></input>
							</div>
						</div>
						<div className="row">
							<div className="row">
								<div className="col-lg-6 input-group my-2">
										<label>
											<CategoryIcon />
											Category <small className="text-danger">*</small>
										</label>
									<select
										className="form-control"
										name="category"
										value={inventory.category}
										onChange={handleChange}
									>
										{categories.length
											? categories.map((cat) => (
													<option key={cat.id} value={cat.name}>
														{cat.name}
													</option>
											  ))
											: null}
									</select>
									<AddCategoryModal
										categories={categories}
										setCategories={setCategories}
										inventory={inventory}
										setInventory={setInventory}
									/>
								</div>
							</div>
							<div className="form-group my-2 col-lg-6">
								<label>
									<Filter9Plus />
									Inventory Id <small className="text-danger">*</small>
								</label>
								<input
									type="text"
									className="form-control"
									placeholder="Inventory Id"
									name="inventoryId"
									value={inventory.inventoryId}
									onChange={handleChange}
								></input>
								{errors?.inventoryId ? (
									<small className="text-danger">{errors.inventoryId}</small>
								) : null}
							</div>
						</div>
						<div className="input-group my-2">
							<div className="form-group d-flex justify-content-start align-items-center">
								<label>Requires servicing ?</label>
								<input
									className="form-check-input border border-primary"
									type="checkbox"
									name="requiresServicing"
									value={inventory.requiresServicing}
									checked={inventory.requiresServicing}
									onChange={handleSelectChange}
								/>
							</div>
							{inventory.requiresServicing &&
							 <>
							 <div>
								<label>
									<BuildIcon />
									Servicing Duration <small className="text-danger">*</small>
								</label>
							</div>
							<select
								className="form-control"
								name="servicingDuration"
								value={inventory.servicingDuration}
								onChange={handleChange}
								disabled={!inventory.requiresServicing}
							>
								<option value={6}>6 months</option>
								<option value={9}>9 months</option>
								<option value={12}>12 months</option>
								<option value={15}>15 months</option>
								<option value={24}>24 months</option>
							</select>
							</>}
						</div>
						<div className="row">
							<div className="form-group my-3 col-lg-4">
								<label>
									<PaymentIcon />
									Cost(Nrs.)
								</label>
								<input
									type="number"
									className="form-control"
									min={0}
									rows="3"
									name="cost"
									value={inventory.cost}
									onChange={handleChange}
								/>
								{costError && <small className="form-text text-danger">
									{costError}
								</small>}
							</div>
							<div className="form-group my-3 col-lg-4">
								<label>
									<Note />
									Procurred At
								</label>
								<input
									type="date"
									className="form-control"
									rows="3"
									name="procurredAt"
									value={inventory.procurredAt}
									onChange={handleChange}
								></input>
								<small className="form-text text-muted">
									Leave field empty if procurred recently
								</small>
							</div>
						</div>
						<div className="form-group d-flex justify-content-start align-items-center">
							<label>Allocatable ?</label>
							<input
								className="form-check-input"
								type="checkbox"
								name="isAllocatable"
								value={inventory.isAllocatable}
								checked={inventory.isAllocatable}
								onChange={handleSelectChange}
							/>
						</div>
						<div className="form-group my-2">
							<div>
								<PhotoIcon />
								<label className="my-3">Image</label>
							</div>
							<input type="file" ref={imageRef} accept="image/*" />
						</div>
						{errors?.mimeType ? (
							<small className="text-danger">{errors.mimeType}</small>
						) : null}
						<div className="form-group my-3 w-50">
							<label>
								<Note />
								Notes <small className="text-danger">*</small>
							</label>
							<textarea
								className="form-control"
								rows="3"
								name="notes"
								value={inventory.notes}
								onChange={handleChange}
							></textarea>
							{errors?.notes ? (
								<small className="text-danger">{errors.notes}</small>
							) : null}
						</div>
						<div className="form-group my-3">
							<Button variant="outlined" color="primary" type="submit">
								{loading ? <ButtonLoader color="orange"/> : "Add"}
							</Button>
							<Link
								to="/dashboard/inventories"
								style={{ textDecoration: "none", margin: "8px" }}
							>
								<Button variant="outlined" color="secondary">
									Cancel
								</Button>
							</Link>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default AddInventoryForm
