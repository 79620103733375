import React from "react"
import { Box } from "@material-ui/core"
import TipTap from "../../../TipTap/TipTap"

export default function AdminCollapsePanel({
	workDescription,
	status,
	declineMessage,
	remarkMessage,
	requestId,
	isLoaded,
	setIsLoaded,
	setOpen,
}) {
	return (
		<>
			<Box
				style={{
					boxShadow:"2px 2px 35px 1px gray",
					marginBottom:"10px", 
					paddingBottom:"20px", 
					paddingRight:"20px", 
					paddingLeft:"20px",
					color:
						(status === "Request Pending" && "#1769aa")||
						(status === "Request Approved" && "green") ||
						(status === "Request Rejected" && "#ba000d") ||
						(status === "Request Cancelled" && "#ba000d") ||
						(status === "Work In Review" && "#1769aa")||
						(status === "Work Approved" && "green") ||
						(status === "Work Rejected" && "#ba000d"),
				}}
			>
				<br />
				<h6
					style={{
						
						textAlign: "left",
					}}
				>
					Work Description:{" "}
				</h6>
				<TipTap content={workDescription} editable={false} />

				{status==="Request Cancelled" && (
					<h6 style={{  textAlign: "left" }}>
						Remark (Cancelled)
					</h6>
				)}
				{status==="Request Cancelled" && (
					<TipTap content={remarkMessage} editable={false} />
				)}
				{(status==="Work In Review"|| status==="Work Approved" || status==="Work Rejected") && (
					<h6 style={{  textAlign: "left" }}>
						Remark (Extra work done and sent for review)
					</h6>
				)}
				{(status==="Work In Review"|| status==="Work Approved" || status==="Work Rejected") && (
					<TipTap content={remarkMessage} editable={false} />
				)}
				{declineMessage && (
					<h6 style={{  textAlign: "left" }}>
						Decline Message
					</h6>
				)}
				{declineMessage && (
					<TipTap content={declineMessage} editable={false} />
				)}
			</Box>
		</>
	)
}
