import React from 'react'
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../Redux/actions/userActions";
import {  useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import Spinner from "../Spinner/Spinner";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChangePassword from './ChangePassword';
function Setting() {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
      userReducer: { user, isLoading },
    } = useSelector((store) => ({
      role: store.authReducer.user.roles[0],
      userReducer: store.userReducer,
    }));
  
    const { id } = useParams();
  
    useEffect(() => {
      dispatch(fetchUser(id));
    }, [id]);
  
    if (isLoading || !user) return <Spinner />;

  return (
    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" >
      {user.hasLeft && (
        <div className="container mb-5">
          <h3 className="text-danger text-center">
            This user has left the company
          </h3>
          <h5 className="text-center">You can see setting here</h5>
        </div>
      )}
        <div>

            <Button
                color="secondary"
                variant="outlined"
                style={{ fontSize: "13px",marginLeft: "5%" }}
                onClick={history.goBack}
            >
                <ArrowBackIosIcon style={{fontSize: "12px"}}/>
                <span>Back</span>
            </Button>

        </div>
        <h3 className="text-center mb-4" style={{ color: "#ff6600" }}>
            Account Setting
        </h3>
        <ChangePassword />
    </div>
  )
}

export default Setting