import React, { useState, useRef, useEffect } from "react";
import { Button } from "@material-ui/core";
import ButtonLoader from "../../ButtonLoader/ButtonLoader";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../Redux/actions/userActions";

const InfoLabel = ({
  label,
  value,
  user,
  editMode,
  setEditMode,
  editableFields,
  inputType,
}) => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [onFocus, setOnFocus] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const errors = useSelector((store) => store.userReducer.errors);

  useEffect(() => {
    inputRef.current.value = value;
  }, [user]);

  const history = useHistory();

  const role = useSelector((store) => store.authReducer.user.roles[0]);

  function handleChange(e) {
    // validate if input field id changed
    if (
      e.target.value.toLowerCase() === value.toLowerCase()) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    // create updated user
    const updatedUser = { ...user };
    updatedUser[label] = inputRef.current.value;
    dispatch(updateUser(user?.employeeId, updatedUser, history, setLoading, role));
  }
  var result = label.replace( /([A-Z])/g, " $1" );
  var formatttedLabel = result.charAt(0).toUpperCase() + result.slice(1);
  return (
    <div className="mb-3" data-aos="fade-up"
    data-aos-duration="300">
      <div style={{ textTransform: "capitalize" }}>
        <p className="label mb-0">{formatttedLabel}</p>
      </div>
      <div>
        {inputType !== "textarea" && (
          <input
            style={{fontSize: "14px"}}
            type={inputType}
            className="value form-control"
            ref={inputRef}
            onFocus={() => setOnFocus(true)}
            onBlur={() => {
              setTimeout(() => {
                setOnFocus(false);
              }, 100);
            }}
            onChange={handleChange}
            disabled={
              !editMode || !editableFields.includes(label.toLowerCase())
            }
          />
        )}
        {inputType === "textarea" && (
          <textarea
            className="form-control"
            rows="3"
            ref={inputRef}
            onFocus={() => setOnFocus(true)}
            onBlur={() => {
              setTimeout(() => {
                setOnFocus(false);
              }, 100);
            }}
            onChange={handleChange}
            disabled={
              !editMode || !editableFields.includes(label.toLowerCase())
            }
          ></textarea>
        )}
        {errors[label] && (
          <div>
            <small className="text-danger">{errors[label]}</small>
          </div>
        )}
        {editableFields.includes(label.toLowerCase()) && editMode && onFocus && (
          <span className="py-3">
            <Button
              color="primary"
              variant="contained"
              style={{ margin: "10px 10px 0 0", color: '#fff' }}
              disabled={!isChanged}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="contained"
              style={{ margin: "10px 10px 0 0"}}
              onClick={(e) => {
                e.preventDefault();
                inputRef.current.value = value;
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
          </span>
        )}
        {loading && (
          <Button>
            <ButtonLoader />
          </Button>
        )}
      </div>
    </div>
  );
};

export default InfoLabel;
