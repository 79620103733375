import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableFooter from "@material-ui/core/TableFooter"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/styles"
import { makeStyles } from "@material-ui/core"
import TablePaginationActions from "../../TablePaginationActions/TablePaginationActions"
import CollapsiableTable from "../../LeaveTable/CollapsiableTable"
import SwitchDayHour from "../../SwitchDayHour/SwitchDayHour"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 700,
		padding: "10px",
	},
	tableContainer: {
		margin: "auto",
	},
})

export default function LeaveDetails({fromSummary = false, userId}) {
	const classes = useStyles() 
	var currDate = new Date()
	var currMonth = currDate.getMonth()
	const [leaveDetails, setLeaveDetails] = useState([])
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(5)
	const [count, setCount] = React.useState()
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)
	const [findBy, setFindBy] = React.useState({
		startDate: "",
		endDate: "",
	})
	const [showHour, setShowHour] = React.useState(false)
	

	const handleChangeFind = (e) => {
		setFindBy({ ...findBy, [e.target.name]: e.target.value })
	}

	useEffect(async () => {
		const responseData = await axios
			.get(
				`/api/leave/requests/specific?page=${page}&userId=${userId}&rowsPerPage=${rowsPerPage}&findBy=${JSON.stringify(
					findBy
				)}`
			)
			.then((response) => {
				return response.data
			})
			.catch((error) => console.log(error))
		setLeaveDetails(responseData.result)
		setCount(responseData.count)
	}, [page, rowsPerPage, findBy])

	useEffect(()=>{
		setFindBy({
			...findBy, 
			["startDate"]: parseInt(currMonth < 6 ? (new Date().getFullYear() -1) : (new Date().getFullYear()))  + "-07-01",
			["endDate"]: parseInt(currMonth < 6 ? (new Date().getFullYear()) : (new Date().getFullYear()+1)) + "-06-30",
		})
	},[])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
        <div className="container-fluid">
			<TableContainer className={classes.tableContainer} component={Paper}>
				<div className="row">
					<div
						className="col"
						style={{
							marginBottom: "1%",
							marginLeft: "1%",
							marginTop: "2%",
							textAlign: "center",
						}}
					>
						<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
					</div>
					<div
						className="col-5"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="startDate" style={{ color: "#ff6600" }}>
							From
						</label>
						<input
							type="date"
							name="startDate"
							onChange={handleChangeFind}
							value={findBy.startDate}
						/>
					</div>
					<div
						className="col-5"
						style={{ marginBottom: "1%", marginTop: "2%", textAlign: "center" }}
					>
						<label htmlFor="endDate" style={{ color: "#ff6600" }}>
							To
						</label>
						<input
							type="date"
							name="endDate"
							onChange={handleChangeFind}
							value={findBy.endDate}
						/>
					</div>
				</div>

				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow >
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Leave Type</StyledTableCell>

							<StyledTableCell>From</StyledTableCell>
							<StyledTableCell>Leave Time</StyledTableCell>
							<StyledTableCell>To</StyledTableCell>
							<StyledTableCell>Arrival Time</StyledTableCell>
							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Leave Status</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{leaveDetails.map((ld,key) => (
							<CollapsiableTable
								key={key}
								ld={ld}
								setLeaveDetails={setLeaveDetails}
								page={page}
								rowsPerPage={rowsPerPage}
								setCount={setCount}
								findBy={findBy}
								showHour={showHour}
								fromSummary={fromSummary}
							/>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={1} />
							</TableRow>
						)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25]}
								colSpan={9}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	)
}
