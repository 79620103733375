import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import "react-toastify/dist/ReactToastify.css";
import InventoryDetails from "./InventoryDetails";
import InventoryProfile from "./InventoryProfile";
import { Grid } from "@material-ui/core";
import axios from "../../utils/axios";
import { getInventory } from "../../Redux/actions/inventoryactions";
import { useDispatch } from "react-redux";

export default function Inventory() {
  const dispatch = useDispatch();
  const [inventory, setInventory] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const id = params.id;
  async function fetchInventory(){
    const response = await axios.get(`/api/inventories/${id}`);
    const inventory = response.data.result;
    setInventory(inventory);
    setIsLoading(false)
  }
  useEffect(() => {
    dispatch(getInventory(id));
    setIsLoading(true);
    fetchInventory();
  }, [dispatch, id]);
  if (isLoading || !inventory) return <Spinner />;
  return (
    <>
      <div className="p-4">
        <Grid container spacing={3} justifyContent="center">
          <Grid item lg={3} md={5} xs={12}>
            <InventoryProfile inventory={inventory} />
          </Grid>
          <Grid item lg={9} md={7} xs={12}>
            <InventoryDetails inventoryItem={inventory} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
