import * as React from "react";
import Spinner from "../Spinner/Spinner";
import AllocationActions from "./AllocationActions";
//Material table
import { forwardRef } from "react";
import MaterialTable from "material-table";
import {
  ArrowDownward,
  Search,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import { useHistory } from "react-router";
import {Alert} from 'react-bootstrap'
import { useSelector } from "react-redux";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

export default function AdminInventories({ allocationReducer, isDisposed }) {
  const { inventoryAllocations, isLoading } = allocationReducer;
  const history = useHistory();
  const permissions = useSelector((store)=>store.authReducer.permissions)
  const columns = [
    {
      field: "user",
      title: "User",
      width: 200,
      filtering: false,
    },
    {
      field: "employeeId",
      title: "Employee Id",
      width: 200,
      filtering: false,
    },
    {
      field: "allocatedAt",
      title: "Allocated Date",
      filtering: false,
    },
    {
      field: "unallocatedAt",
      title: "Unallocated Date",
      width: 200,
      filtering: false,
    },
    {
      field: "duration",
      title: "Duration(days)",
      width: 200,
      filtering: false,
    },
    {
      field: "isCurrentAllocation",
      title: "Ia current allocation",
      width: 200,
      filtering: false,
      hidden: true,
    },
    {
      field: "notes",
      title: "Notes",
      width: 200,
      filtering: false,
      hidden: true,
    },
  ];
  const rows =
    inventoryAllocations?.length &&
    inventoryAllocations
      .map((all) => {
        const duration = all.unallocatedAt
          ? Math.abs(new Date(all.unallocatedAt) - new Date(all.allocatedAt)) /
            (1000 * 3600 * 24)
          : Math.round(
              Math.abs(new Date(all.allocatedAt) - new Date()) /
                (1000 * 3600 * 24)
            );
        return {
          user: `${all.user.firstName} ${all.user.lastName}`,
          employeeId: all.user.employeeId,
          allocatedAt: all.allocatedAt,
          unallocatedAt: all.unallocatedAt
            ? all.unallocatedAt
            : "Current allocation",
          duration: duration,
          isCurrentAllocation: all.isCurrentAllocation,
          notes: all.notes
        };
      })
      .reverse();

  if (isLoading ) return <Spinner />;
  else if (!inventoryAllocations?.length && permissions?.includes("allocate_inventory")) {
    return <AllocationActions />;
  }

  return (
    <div className="rounded my-3 bg-light" data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
      <MaterialTable
        icons={tableIcons}
        title="Owners list"
        data={rows}
        columns={columns}
        options={{
          pageSize: 10,
          headerStyle: {
            fontSize: "0.85rem",
            backgroundColor: "#ff6600",
            color: "whitesmoke",
          },
          actionsColumnIndex: -1,
          rowStyle: (rowData) => {
            if (rowData.isCurrentAllocation === true) {
              return {
                backgroundColor: "#C8E8B9",
              };
            }
          },
        }}
        actions={[
          !isDisposed && {
            icon: () => <AllocationActions />,
            isFreeAction: true,
            onClick: (event) => <AllocationActions />,
          },
        ]}
        onRowClick={(event, rowData) => {
          history.push("/dashboard/users/" + rowData.employeeId);
        }}
        detailPanel={(rowData) => {
          return (
            <Alert variant="info m-0" style={{ paddingLeft: "8%" }}>
              <p>
                <span>Remarks:</span>
                <p style={{ fontStyle: "italic" }}>{rowData.notes}</p>
              </p>
            </Alert>
          );
        }}
      />
    </div>
  );
}
