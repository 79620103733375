import { useState, useEffect } from "react";
import { Box, Button, Card, CardHeader, Divider } from "@material-ui/core";
import { Filter, Note } from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";
import { updateInventory } from "../../Redux/actions/inventoryactions";
import FormInput from "../../Common/Input/FormInput";
import BuildIcon from "@material-ui/icons/Build";
import { Row, Col } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import axios from "../../utils/axios";
import moment from "moment";
import ButtonLoader from "../../ButtonLoader/ButtonLoader";
import Checkbox from "@material-ui/core/Checkbox";
import { notify } from "../../utils/toast";
const InventoryDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams();
  const { inventoryItem: storedInventory } = props;
  let inventoryItem = JSON.parse(JSON.stringify(storedInventory));
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const [inventory, setInventory] = useState({
    name: "",
    brand: "",
    model: "",
    cost: 0,
    procurredAt: "",
    category: "",
    inventoryId: "",
    description: "",
    notes: "",
    requiresServicing: false,
    servicingDuration: "",
    isAllocatable: false,
  });
  const errors = useSelector((store) => store.inventoryReducer.errors) || {};
  const [costError, setCostError] = useState(null);
  useEffect(() => {
    axios
      .get("/api/categories")
      .then((res) => {
        setCategories(res.data.result);
      })
      .catch((err) => notify('error','Something went wrong !'));
  }, []);
  useEffect(() => {
    setInventory({
      name: inventoryItem?.name || "",
      brand: inventoryItem?.brand || "",
      model: inventoryItem?.model || "",
      cost: inventoryItem?.cost || 0,
      procurredAt: inventoryItem?.procurredAt || "",
      category: inventoryItem?.category?.name || "",
      inventoryId: inventoryItem?.inventoryId || "",
      notes: inventoryItem?.notes || "",
      requiresServicing: inventoryItem?.requiresServicing,
      servicingDuration: inventoryItem?.servicings?.find(
        (s) => s.isCurrentServicing === true
      )?.durationInMonth,
      isAllocatable: inventoryItem?.isAllocatable,
    });
    // eslint-disable-next-line
  }, []);
  function handleChange(e) {
    const { name, value } = e.target;
    if(name === "cost" && value < 0){
      setCostError("Value Cannot be negative");  
    } else {
      setCostError(null);
    }
    setInventory({ ...inventory, [name]: value });
  }
  function handleSubmit(e) {
    e.preventDefault();
    // check cost is negative
    if(costError){
      return;
    }
    setLoading(true);
    let data;
    data = {...inventory};
    if(inventory.requiresServicing && !inventory.servicingDuration)
    {
      data['servicingDuration'] = 6;
    }
    dispatch(updateInventory(params.id, data, history, setLoading));
  }
  if (!inventoryItem) return <Spinner />;
  return (
    <div data-aos="zoom-in">
      {inventoryItem.isDisposed && (
        <Alert variant="danger">
          <h4>Inventory is disposed</h4>
        </Alert>
      )}
      <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
        <fieldset disabled={inventoryItem.isDisposed}>
          <Card style={{ boxShadow: "none", padding: "0 10px" }}>
            <CardHeader
              subheader="The information can be edited"
              title="Inventory Profile"
              style={{ padding: "0" }}
            />
            <Divider />
            <Row>
              <FormInput
                type="text"
                label="Inventory Name"
                name="name"
                value={inventory.name}
                handleChange={handleChange}
                feedback={errors?.name || null}
              />
              <FormInput
                type="text"
                label="Brand Name"
                name="brand"
                value={inventory.brand}
                handleChange={handleChange}
              />
            </Row>
            <Row>
              <FormInput
                label="Model Name"
                type="text"
                name="model"
                value={inventory.model}
                handleChange={handleChange}
              />
              <FormInput
                label="Inventory Id"
                type="text"
                name="inventoryId"
                value={inventory.inventoryId}
                handleChange={handleChange}
                required
                feedback={errors?.inventoryId || null}
              />
            </Row>
            <label>Allocatable ?</label>
            <Checkbox
              checked={inventory.isAllocatable}
              inputProps={{ "aria-label": "primary checkbox" }}
              onClick={(e) => {
                setInventory({
                  ...inventory,
                  isAllocatable: !inventory.isAllocatable,
                });
              }}
            />
            <label>
              <Filter />
              Category <small className="text-danger">*</small>
            </label>
            <select
              className="form-control"
              style={{ width: "140px", margin: "20px 0", display: "inline" }}
              id="device"
              name="category"
              value={inventory.category}
              onChange={handleChange}
              required
            >
              {categories.map((cat) => (
                <option key={cat.id} value={cat.name}>
                  {cat.name}
                </option>
              ))}
            </select>
            <div className="mt-3">
              <h5>Procurement details</h5>
            </div>
            <Row>
              <FormInput
                type="number"
                label="Procurement cost (Rs.)"
                name="cost"
                value={inventory.cost}
                handleChange={handleChange}
                feedback={costError}
              />
              <FormInput
                type="date"
                label="Procurement Date"
                name="procurredAt"
                value={moment(inventory.procurredAt).format("YYYY-MM-DD")}
                handleChange={handleChange}
              />
            </Row>
            <div>
              <h5 className="mt-3">Other details</h5>
            </div>
            <div>
              <label>Requires Servicing ?</label>
              <Checkbox
                checked={inventory.requiresServicing}
                inputProps={{ "aria-label": "primary checkbox" }}
                onClick={(e) => {
                  setInventory({
                    ...inventory,
                    requiresServicing: !inventory.requiresServicing,
                  });
                }}
              />
              {inventory.requiresServicing && (
                <>
                  <label>
                    <BuildIcon />
                    Servicing Duration <small className="text-danger">*</small>
                  </label>
                  <select
                    className="form-control d-inline"
                    style={{ width: "140px", margin: "20px 0" }}
                    name="servicingDuration"
                    value={inventory.servicingDuration}
                    onChange={handleChange}
                    required
                    disabled={!inventory.requiresServicing}
                  >
                    <option value={6}>6 months</option>
                    <option value={9}>9 months</option>
                    <option value={12}>12 months</option>
                    <option value={15}>15 months</option>
                    <option value={24}>24 months</option>
                  </select>
                </>
              )}
            </div>
            <Row>
              <Col xl={5}>
                <div className="form-group my-2">
                  <label>
                    <Note />
                    Notes <small className="text-danger">*</small>
                  </label>
                  <textarea
                    className="form-control w-100"
                    rows="3"
                    name="notes"
                    value={inventory.notes}
                    onChange={handleChange}
                  ></textarea>
                  {errors?.notes ? (
                    <small className="text-danger">{errors.notes}</small>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                disabled={inventoryItem.isDisposed}
              >
                {loading ? <ButtonLoader color="orange" /> : "Save"}
              </Button>
              <Link
                to="/dashboard/inventories"
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="secondary"
                  variant="outlined"
                  style={{ marginLeft: "8px" }}
                >
                  Back
                </Button>
              </Link>
            </Box>
          </Card>
        </fieldset>
      </Box>
    </div>
  );
};

export default InventoryDetails;
