import * as React from "react";
import Spinner from "../Spinner/Spinner";
//Material table
import { forwardRef } from "react";
import MaterialTable from "material-table";
import {
  ArrowDownward,
  Search,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import ServicingActions from "./ServicingActions";
import { Alert } from "react-bootstrap";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

export default function ServicingTable({ servicingReducer, isDisposed }) {
  const { inventoryServicings, isLoading } = servicingReducer;
  const columns = [
    {
      field: "serviceAt",
      title: "Servicing date",
      width: 200,
      filtering: false,
    },
    {
      field: "sentAt",
      title: "Sent Date",
      filtering: false,
    },
    {
      field: "returnedAt",
      title: "Returned Date",
      width: 200,
      filtering: false,
    },
    {
      field: "cost",
      title: "Cost",
      width: 200,
      filtering: false,
    },
    {
      field: "remarks",
      title: "Remarks",
      width: 200,
      filtering: false,
      hidden: true
    },
    {
      field: "isCurrentServicing",
      title: "Is current servicing",
      width: 200,
      filtering: false,
      hidden: true,
    },
  ];
  let rows =
    inventoryServicings.length &&
    inventoryServicings
      .map((serv) => {
        return {
          serviceAt: serv?.serviceAt || "-",
          sentAt: serv.sentAt ? serv.sentAt : "-",
          returnedAt: serv.returnedAt ? serv.returnedAt : "-",
          cost: serv.cost ? "Rs. " + serv.cost : "-",
          remarks: serv.remarks ? serv.remarks : "-",
          isCurrentServicing: serv.isCurrentServicing,
        };
      });
  if (isLoading) return <Spinner />;
  return (
    <div className="rounded my-3 bg-light" data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
      <MaterialTable
        icons={tableIcons}
        title="Servicings list"
        data={rows}
        columns={columns}
        options={{
          pageSize: 10,
          headerStyle: {
            fontSize: "1rem",
            backgroundColor: "#ff6600",
            opacity: "0.7",
            color: "whitesmoke",
          },
          actionsColumnIndex: -1,
          rowStyle: (rowData) => {
            if (rowData.isCurrentServicing === true) {
              return {
                backgroundColor: "#C8E8B9",
              };
            }
          },
        }}
        actions={[
          !isDisposed && {
            icon: () => <ServicingActions />,
            isFreeAction: true,
            onClick: (event) => <ServicingActions />,
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(rowData) => {
          return (
            <Alert variant="info m-0" style={{ paddingLeft: "8%" }}>
              <p>
                <span>Remarks:</span>
                <p style={{ fontStyle: "italic" }}>{rowData.remarks}</p>
              </p>
            </Alert>
          );
        }}
      />
    </div>
  );
}
