import React from "react";
import "./Landing.css";
import { Link } from "react-router-dom";
import Redirector from "../Redirector/Redirector";
import {Button} from '@material-ui/core'

export default function LandingPage() {
  return (
    <>
      <Redirector />
      <div className="landingContainer h-100" data-aos="zoom-in-down">
        <div>
          <h1 className="title text-uppercase">
            Welcome To Wolfmatrix Office Management System
          </h1>
        </div>
        <div className="container mt-2">
          <div className="row">
            <div className="col-sm-6 mt-2">
              <img
                className="mt-5 "
                src="https://wolfmatrix.com/wp-content/themes/wolfmatrix/images/mainLogo@2x.jpg"
                alt=""
                width={"200px"}
              />

              <p className="lead py-3 intro">
                WOMS keeps track of inventories assigined to our employees.
                Keeps employees info. Notifies inventories servicing.
              </p>
              <Link to="/login" className="m-2 text-decoration-none">
                <Button color='primary' variant='contained'>
                  <span className='text-light'>Get started</span>
                </Button>
              </Link>
            </div>
            <div className="col-sm-6 mt-2">
              <img
                className="img-fluid"
                src="https://wolfmatrix.com/wp-content/uploads/2020/03/driven_by_values.png"
                alt=""
                srcSet=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
