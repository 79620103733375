import React, { useState } from "react"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Chip from "@material-ui/core/Chip"
import Avatar from "@material-ui/core/Avatar"

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 1,
		backgroundColor: "#f4511e",
		color: "white",
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}))

const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: "20%",
		},
	},
}

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	}
}

export default function MultipleSelectInput({
	supervisorDetails,
	setSupervisorDetails,
	supervisorOption,
}) {
	const classes = useStyles()
	const theme = useTheme()
	const [personName, setPersonName] = useState([])

	const handleChange = (_, val) => {
		const value = val.props.obj

		let assignedSupervisors = [...supervisorDetails.supervisors]
		let employeeId = supervisorDetails.supervisors.map((e)=>e.employee_id)
		
		if(employeeId.includes(value.employee_id)){
			// remove here
			assignedSupervisors.splice(employeeId.indexOf(value.employee_id),1)
		}else{
			// add here
			assignedSupervisors.push(value)
		}
		setSupervisorDetails({...supervisorDetails,supervisors: assignedSupervisors} )
	
	}
	return (
		<div>
			<label>Select Supervisor</label>
			<FormControl
				style={{
					width: "100%",
					background: "whitesmoke",
				}}
			>
				<Select
					id="multiple-name"
					multiple
					onChange={handleChange}
					required
					input={<OutlinedInput />}
					MenuProps={MenuProps}
					value={supervisorDetails?.supervisors?.map((element)=>`${element.first_name} ${element.last_name}`) || []}

					renderValue={(selected) => {
						// console.log("selected:",selected)						
						return (
							<div className={classes.chips}>
								{
									(selected?.map((value) => (
										<Chip
											key={value}
											label={value}
											className={classes.chip} />
									))) 
								
									}
							</div>
						)
					}}
				>
					{supervisorOption.map((name) => (
						<MenuItem
							key={name.id}
							value={name.first_name+" "+name.last_name}
							style={getStyles(name, personName, theme)}
							obj={name}
						>
							<div className="row">
								<div className="col">
									{name.pp_image_path ? (
										<Avatar
											alt={name.first_name}
											src={process.env.REACT_APP_API_URL + name.pp_image_path}
										/>
									) : (
										<Avatar alt={name.first_name} />
									)}
								</div>
								<div className="col" style={{ marginTop: "3%" }}>
									{name.first_name + " " + name.last_name}
								</div>
							</div>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	)
}
