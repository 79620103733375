import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActionArea,
} from "@material-ui/core";
import FileUploader from "../../FileUploader/FileUploader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  title: 
    {
      background: "#ff6600",
      backgroundImage: "radial-gradient(transparent 30%, rgba(0, 0, 0, 0.2))",
      border: "0 solid rgba(0,0,0,0.2)",
      color: "#fff",
      fontSize: "15px",
      fontWeight: "bold",
      textTransform: "uppercase",
      position: "relative",
      textShadow: "-1px -1px 1px rgba(0,0,0,0.2)",
      letterSpacing: "0.01rem",
      lineHeight: "1.2",
      padding: "20px"
  }
});

const InventoryProfile = (props) => {
  const [imagePath, setImagePath] = useState("");
  useEffect(() => {
    setImagePath(props.inventory.imagePath);
  }, [props.inventory]);
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Inventory Image"
          width="400"
          image={
            imagePath
              ? process.env.REACT_APP_API_URL + imagePath
              : "/picture.jpg"
          }
          title="Inventory Image"
        />
        <FileUploader type="inventoryImage" setImagePath={setImagePath} />
        <CardContent style={{padding: "16px 0"}}>
          <Typography gutterBottom variant="h5" component="h3" className={classes.title}>
            {props.inventory.name} ({props.inventory.inventoryId})
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.inventory.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default InventoryProfile;
