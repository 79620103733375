import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
//Material table
import { forwardRef } from "react";
import MaterialTable from "material-table";
import {
  ArrowDownward,
  Search,
  Clear,
  FilterList,
  FirstPage,
  Add,
  LastPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import axios from "../utils/axios";
import { Button } from "@material-ui/core";
import RejectModal from "./RejectModal";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import CancelIcon from "@material-ui/icons/Cancel";
import { Alert } from "react-bootstrap";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip from "@material-ui/core/Chip";
import { notify } from "../utils/toast";
import { useSelector } from "react-redux";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
};

export default function RequestTable() {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [currentRowId, setCurrentRowId] = React.useState(null);
  const history = useHistory();
  const permissions = useSelector((store)=>store.authReducer.permissions)
  const tableRef = useRef();
  const titleStyle = {
    color: "#ff6600",
    margin: "10px",
    padding: "2px",
  };

  function respondRequest(id, action, inventoryId) {
    axios
      .put(
        `/api/admin/requests/${id}/${action}`,
        JSON.stringify({ inventoryId })
      )
      .then((res) => {
        setTimeout(() => {
          console.log(tableRef);
          tableRef.current?.onQueryChange();
        }, 400);
        notify("success", "Request accepted!");
      })
      .catch((err) => 
      notify('error','Something went wrong !')
      );
  }
  function cancelRequest(id) {
    axios
      .delete(`/api/requests/${id}`)
      .then((res) => {
        setTimeout(() => {
          tableRef.current && tableRef.current.onQueryChange();
        }, 400);
        notify("success", "Request cancelled!");
      })
      .catch((err) => notify('error','Something went wrong !')
      );
  }
  const columns = [
    {
      field: "id",
      title: "Id",
      hidden: true,
    },
    {
      field: "inventory",
      title: "Inventory",
      filtering: false,
      width: 100,
    },
    {
      field: "user",
      title: "Request by",
      filtering: true,
      hidden: !permissions?.includes("view_all_requests"),
    },
    {
      field: "type",
      title: "Request for",
      filtering: true,
    },
    {
      field: "message",
      title: "Message",
      filtering: false,
      hidden: true,
    },
    {
      field: "status",
      title: "Status",
      filtering: false,
      render: (rowData) => {
        if (rowData.status === "accepted") {
          return (
            <Chip
              key={rowData.status}
              label={rowData.status}
              style={{
                backgroundColor: "green",
                color: "white",
                width: "100px",
                fontWeight: "bold",
                opacity: "0.8",
              }}
            />
          );
        } else if (rowData.status === "rejected") {
          return (
            <Chip
              key={rowData.status}
              label={rowData.status}
              style={{
                backgroundColor: "red",
                color: "white",
                width: "100px",
                fontWeight: "bold",
              }}
            />
          );
        } else if (rowData.status === "pending") {
          return (
            <Chip
              key={rowData.status}
              label={rowData.status}
              style={{
                backgroundColor: "#0dcaf0",
                color: "white",
                width: "100px",
                fontWeight: "bold",
                opacity: "0.8",
              }}
            />
          );
        }
      },
    },
    {
      field: "response",
      title: "Response",
      filtering: false,
      hidden: true,
    },
    {
      field: "subject",
      title: "Subject",
      filtering: false,
      hidden: true,
    },
    {
      field: "isUrgent",
      title: "Is urgent",
      filtering: false,
      hidden: true,
    },
  ];
  return (
    <div data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        title={
          <h6 style={titleStyle}>
            { permissions?.includes("view_all_requests") ? "Requests list" : "Your Requests list"}
          </h6>
        }
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = "/api/requests?";
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            let filters = null;
            if (query.filters.length) {
              filters = query.filters
                .map((f) => f.column.field + f.operator + f.value)
                .join("&");
              url += "&" + filters;
            }
            axios
              .get(url)
              .then((res) => {
                let requests = res.data.result;
                let rows = requests.map((req) => {
                  return {
                    id: req.id,
                    inventory: req.inventory?.name || "-",
                    inventoryId: req.inventory?.inventoryId || "-",
                    user: req.user
                      ? req.user.firstName + " " + req.user.lastName
                      : "-",
                    type: req.type ? req.type : "-",
                    message: req.message ? req.message : "-",
                    status: req.status ? req.status : "-",
                    response: req.response ? req.response : null,
                    subject: req.subject ? req.subject : null,
                    isUrgent: req.isUrgent ? req.isUrgent : null,
                  };
                });
                resolve({
                  data: rows,
                  page: res.data.page - 1,
                  totalCount: res.data.totalCount,
                });
              })
              .catch((err) => 
                notify('error','Something went wrong !')
              );
          })
        }
        columns={columns}
        options={{
          search: false,
          pageSize: 10,
          headerStyle: {
            backgroundColor: "#ff6600",
            color: "whitesmoke",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
            lineHeight: "1rem",
            paddingRight: "1rem",
          },
          filtering: permissions?.includes("add_inventory"),
          actionsColumnIndex: -1,
          rowStyle: {
            color: "#111111",
            fontSize: "0.94rem",
            fontFamily: "Poppins, sans-serif"
          },
        }}
        actions={[
          {
            icon: <Button>
                    <Add style={{ fontWeight: "bolder" }}></Add>
                  </Button>,
            tooltip: "Add request",
            isFreeAction: true,
            onClick: (event) => history.push("requests/add"),
          },
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   // disabled: rowData.birthYear < 2000
          // }),
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   // disabled: rowData.birthYear < 2000
          // }),
          // rowData => ({
          //   icon: 'delete',
          //   tooltip: 'Delete User',
          //   onClick: (event, rowData) => confirm("You want to delete " + rowData.name),
          //   // disabled: rowData.birthYear < 2000
          // }),
          rowData => ({
            icon: <CancelIcon />,
            tooltip: 'Cancel Request',
            onClick: (event, rowData) => cancelRequest(rowData.id),
            // disabled: rowData.birthYear < 2000
          })
        ]}
        detailPanel={(rowData) => {
          return (
            <Alert
              variant={rowData.isUrgent ? "danger" : "info"}
              style={{ margin: "0", paddingLeft: "70px" }}
            >
              <p>
                <span
                  className={rowData.isUrgent ? "text-danger" : "text-info"}
                >
                  Subject:
                </span>
                <p style={{ fontStyle: "italic" }}>{rowData.subject}</p>
              </p>
              <p>
                <span
                  className={rowData.isUrgent ? "text-danger" : "text-info"}
                >
                  Request Message:
                </span>
                <p style={{ fontStyle: "italic" }}>{rowData.message}</p>
              </p>
              {rowData.response && (
                <p>
                  <span
                    className={rowData.isUrgent ? "text-danger" : "text-info"}
                  >
                    Reponse message:
                  </span>
                  <p style={{ fontStyle: "italic", color: "red" }}>
                    {rowData.response}
                  </p>
                </p>
              )}
            </Alert>
          );
        }}
        components={{
          Action: (props) => {
            if (props.action.isFreeAction === true) {
              return (
                <>
                  <Button>
                    <RefreshIcon
                      onClick={() =>
                        tableRef.current && tableRef.current.onQueryChange()
                      }
                    />
                  </Button>
                  {permissions?.includes("add_inventory") && (
                    <Button onClick={(event) => history.push("requests/add")}>
                      <Add style={{ fontWeight: "bolder" }}></Add>
                    </Button>
                  )}
                </>
              );
            } else if (permissions?.includes("add_inventory") && props.data.status === "pending")
              return (
                <>
                  {currentRowId === props.data.id ? (
                    <ConfirmModal
                      content={`Are you sure you want to ${
                        props.data.type === "repair"
                          ? "send to repair"
                          : props.data.type === "upgrade"
                          ? "provide upgrades"
                          : "provide new inventory"
                      } ?`}
                      open={showConfirmation}
                      setOpen={setShowConfirmation}
                      onConfirm={() => {
                        respondRequest(
                          props.data.id,
                          "accept",
                          props.data.inventoryId
                        );
                      }}
                    />
                  ) : null}
                  <Button
                    style={{ color: "green", marginRight: "3px", border:"2px solid green" }}
                    variant="outlined"
                    startIcon={<CheckCircleIcon />}
                    onClick={() => {
                      setCurrentRowId(props.data.id);
                      setShowConfirmation(true);
                    }}
                  >
                    Accept
                  </Button>
                  <RejectModal
                    id={props.data.id}
                    action="reject"
                    tableRef={tableRef}
                  />
                </>
              );
            else if (!permissions?.includes("view_all_requests") && props.data.status === "pending") {
              return (
                <Button
                  style={{ color: "red" }}
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={() => cancelRequest(props.data.id)}
                >
                  Cancel
                </Button>
              );
            } else {
              return <p>-</p>;
            }
          },
        }}
      />
    </div>
  );
}
