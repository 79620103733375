import React from "react";
import { Container } from "react-bootstrap";
import "./DocumentImages.css";
import AddDocumentModal from "./AddDocumentModal";
import Gallery from "../../ImageViewer/Gallery";

const DocumentImages = ({ hasLeft, documents }) => {
  let data = documents.map((d) => ({
    id: d.id,
    name: d.category.category,
    image: process.env.REACT_APP_API_URL + d.imagePath,
  }));
  return (
    <>
      <Container>
        <div>
          {
            !hasLeft && <AddDocumentModal />
          }
        </div>
        <div>
          <Gallery data={data} />
        </div>
      </Container>
    </>
  );
};

export default DocumentImages;
