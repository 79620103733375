import React, { Fragment } from 'react'
import MenuItem from './MenuItem'
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';
import CodeIcon from '@material-ui/icons/Code';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';

export default function MenuBar({ editor }){
  const items = [
    {
      icon: <FormatBoldIcon />,
      title: 'Bold',
      action: (ev) => {ev.preventDefault();editor.chain().focus().toggleBold().run()},
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: <FormatItalicIcon />,
      title: 'Italic',
      action: (ev) => {ev.preventDefault();editor.chain().focus().toggleItalic().run()},
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: <StrikethroughSIcon />,
      title: 'Strike',
      action: (ev) => {ev.preventDefault();editor.chain().focus().toggleStrike().run()},
      isActive: () => editor.isActive('strike'),
    },
    {
      icon: <CodeIcon />,
      title: 'Code',
      action: (ev) => {ev.preventDefault();editor.chain().focus().toggleCode().run()},
      isActive: () => editor.isActive('code'),
    },
    {
      icon: <FormatListBulletedIcon />,
      title: 'Bullet List',
      action: (ev) => {ev.preventDefault();editor.chain().focus().toggleBulletList().run()},
      isActive: () => editor.isActive('bulletList'),
    },
    {
      icon: <FormatListNumberedIcon />,
      title: 'Ordered List',
      action: (ev) => {ev.preventDefault();editor.chain().focus().toggleOrderedList().run()},
      isActive: () => editor.isActive('orderedList'),
    },
    {
      icon: <LinearScaleIcon />,
      title: 'Horizontal Rule',
      action: (ev) => {ev.preventDefault();editor.chain().focus().setHorizontalRule().run()},
    },
    {
      icon: <FormatLineSpacingIcon />,
      title: 'Hard Break',
      action: (ev) => {ev.preventDefault();editor.chain().focus().setHardBreak().run()},
    },
  ]

  return (
    <div className="editor__header mb-2">
      {items.map((item, index) => (
        <Fragment key={index}>
          <MenuItem {...item} />
        </Fragment>
      ))}
    </div>
  )
}