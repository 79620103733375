import React from "react"
import { Row, Col, Alert, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import Cake from "./Cake"
import "./BirthdayAlert.css"
import { Paper } from "@material-ui/core"

const BirthdayAlert = ({ birthdays }) => {
	return (
		<>
			<h5 style={{ color: "#ff6600" }} className="mb-3">
				Upcoming Birthdays
			</h5>
			{birthdays && birthdays.length ? (
				birthdays.map((b) => {
					const remainingDays =
						new Date(b.dateOfBirth).getDate() - new Date().getDate()
					return (
						<Paper
							key={b.employeeId}
							className={`${
								remainingDays === 0
									? "birthday-container"
									: "alert alert-warning"
							} my-3`}
						>
							<Row className="align-items-center">
								<Col>
									<Link
										to={`/dashboard/users/${b.employeeId}`}
										className="link d-flex flex-column align-items-center"
									>
										{b.image && (
											<img
												src={process.env.REACT_APP_API_URL + b.image}
												width="80px"
												alt="user"
												className="user-image"
											/>
										)}
										<h5 className="text-muted text-capitalize">{b.name}</h5>
									</Link>
								</Col>
								{remainingDays === 0 ? (
									<Col style={{ marginRight: "10%" }}>
										<Cake name={b.name} />
									</Col>
								) : (
									<Col>
										<Container>
											{remainingDays !== 0 && (
												<Col>
													<strong>DOB:</strong> {b.dateOfBirth}
												</Col>
											)}
										</Container>
										<Container>
											<Col className="text-muted font-italic mt-1">
												{remainingDays} days to go
											</Col>
										</Container>
									</Col>
								)}
							</Row>
						</Paper>
					)
				})
			) : (
				<Alert variant="danger">
					<h6>
						No birthdays this month! <span className="h5">🙁</span>
					</h6>
				</Alert>
			)}
		</>
	)
}

export default BirthdayAlert
