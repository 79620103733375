import axios from "../../utils/axios";
import { notify } from "../../utils/toast";

export const getInventories = () => async (dispatch) => {
  try {
    const response = await axios.get("/api/admin/inventories");
    if (response.data.error) {
      dispatch({
        type: "GET_INVENTORIES_ERROR",
        payload: response.data.error,
      });
    } else {
      const inventories = response.data.result;
      dispatch({
        type: "GET_ADMIN_INVENTORIES_SUCCESS",
        payload: inventories,
      });
    }
  } catch (error) {
    dispatch({
      type: "GET_INVENTORIES_ERROR",
      payload: error.response,
    });
  }
};

export const getUnallocatedInventories = () => async (dispatch) => {
  try {
    const response = await axios.get("/api/admin/inventories?unallocated=true");
    if (response.data.error) {
      dispatch({
        type: "GET_INVENTORIES_ERROR",
        payload: response.data.error,
      });
    } else {
      const inventories = response.data.result;
      dispatch({
        type: "GET_UNALLOCATED_INVENTORIES_SUCCESS",
        payload: inventories,
      });
    }
  } catch (error) {
    dispatch({
      type: "GET_INVENTORIES_ERROR",
      payload: error.response,
    });
  }
};


export const addInventory = (formData, history, setLoading) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await axios.post(
      "/api/admin/inventories",
      formData,
      config
    );
    dispatch({
      type: "ADD_INVENTORY_SUCCESS",
      payload: response.data.result,
    });
    history.push("/dashboard/inventories/" + response.data.result.inventoryId);
    notify("success", "Successfully added!");
  } catch (error) {
    dispatch({
      type: "ADD_INVENTORY_ERROR",
      payload: error.response.data.errors,
    });
  }
  setLoading(false);
};

export const updateInventory = (id, formData, history, setLoading) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const response = await axios.put(
      "/api/admin/inventories/" + id,
      formData,
      config
    );
    const updatedInventory = response.data.result;
    dispatch({
      type: "UPDATE_INVENTORY_SUCCESS",
      payload: updatedInventory,
    });
    notify("success", "Update success!");
    history.push("/dashboard/inventories/" + updatedInventory.inventoryId);
  } catch (error) {
    dispatch({
      type: "UPDATE_INVENTORY_ERROR",
      payload: error.response.data.errors,
    });
  }
  setLoading(false);
};

export const getInventory = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/inventories/${id}`);
    const inventories = response.data.result;
    dispatch({
      type: "GET_INVENTORY_SUCCESS",
      payload: inventories,
    });
  } catch (error) {
    dispatch({
      type: "GET_INVENTORY_ERROR",
      payload: error.response.data.errors,
    });
  }
};

export const disposeInventory = (id) => async (dispatch) => {
  try {
    const response = await axios.put(
      `/api/admin/inventories/${id}/dispose`,
      null
    );
    const inventory = response.data.result;
    dispatch({
      type: "DISPOSE_INVENTORY_SUCCESS",
      payload: inventory,
    });
    notify("success", "Dispose successfull!");
  } catch (error) {
    dispatch({
      type: "DISPOSE_INVENTORY_ERROR",
      payload: error.response,
    });
  }
};
export const reviveInventory = (id) => async (dispatch) => {
  try {
    const response = await axios.put(
      `/api/admin/inventories/${id}/revive`,
      null
    );
    const inventory = response.data.result;
    dispatch({
      type: "REVIVE_INVENTORY_SUCCESS",
      payload: inventory,
    });
    notify("success", "Restore successfull!");
  } catch (error) {
    dispatch({
      type: "REVIVE_INVENTORY_ERROR",
      payload: error.reponse,
    });
  }
};
