import React, { useRef } from "react";
import { useParams } from "react-router";
import axios from "../utils/axios";
import { notify } from "../utils/toast";
import { validateFile } from "../utils/validateFile";

const FileUploader = ({ type, setImagePath }) => {
  const imageRef = useRef();
  const params = useParams();

  function handleChange(e) {
    var formData = new FormData();
    var image = imageRef.current.files[0];
    // Validate image
    let error = validateFile(image);
    if (Object.keys(error).length !== 0) {
      notify("error", (error.type || "") + " " + (error.size || ""), 4000);
      imageRef.current.value = "";
      return;
    }
    // Validated
    formData.append("image", image);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    if (type === "ppImage") {
      axios
        .post(`/api/admin/users/${params.id}/upload`, formData, config)
        .then((res) => {
          setImagePath(res.data.result.imagePath);
          notify("success", "upload success!");
        })
        .catch((err) => notify('error','Something went wrong !'));
      return;
    }
    if (type === "inventoryImage") {
      axios
        .post(
          `/api/admin/images/inventories/${params.id}/upload`,
          formData,
          config
        )
        .then((res) => {
          setImagePath(res.data.result.imagePath);
          notify("success", "upload success!");
        })
        .catch((err) => notify('error','Something went wrong !'));
    }
  }
  return (
    <div>
      <div>
        <p>Change Photo</p>
        <input
          type="file"
          name="file"
          ref={imageRef}
          onChange={handleChange}
          accept="image/*"
        />
      </div>
    </div>
  );
};

export default FileUploader;
