import React, { useEffect } from "react";
import InventoryCard from "../inventoryCard/InventoryCard";
import { fetchUserInventories } from "../Redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const UserInventories = () => {
  const dispatch = useDispatch();
  const me = useSelector((store) => store.authReducer)
  const inventories = useSelector((store) => store.userReducer.userInventories);
  const permissions = useSelector((store) => store.authReducer.permissions )
  useEffect(() => {
    dispatch(fetchUserInventories(me?.user.employeeId));
  }, []);
  return (
    <div>
			<h4 style={{ color: "#ff6600" }}>Inventories</h4>
      {inventories.length ? (
        <div className="container">
          <div className="flex-wrap d-flex justify-content-center justify-content-lg-start align-items-center">
            {inventories.map((inventory) => (
              <InventoryCard key={inventory.id} inventory={inventory} />
            ))}
          </div>
          <div className="row my-4"  style={ !(permissions?.includes("request_inventory"))? {display:"none"}:{} } >
            <Link
            to={"requests/add"}
            style={{ textDecoration: "none"}}
            >
              <Button
                  color="primary"
                  variant="outlined"
                  style={{ fontSize: "13px" }}
              >
                  <span>Request New Inventory</span>
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="container alert alert-warning">
              <h5 className="m-2">No inventories to display</h5>
            </div>
          </div>
          <div className="row my-4" style={ !(permissions?.includes("request_inventory"))? {display:"none"}:{} }>
            <Link
            to={"requests/add"}
            style={{ textDecoration: "none" }}
            >
              <Button
                  color="primary"
                  variant="outlined"
                  style={{ fontSize: "13px" }}
              >
                  <span>Request New Inventory</span>
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInventories;
