import React from "react"
import {
	TableCell,
	TableRow,
	Tooltip,
	withStyles,
	CircularProgress,
} from "@material-ui/core"
import RejectLeaveModal from "../../RejectLeaveModal/RejectLeaveModal"

import axios from "../../../utils/axios"
import { notify } from "../../../utils/toast"
import { IconButton } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { Collapse } from "@material-ui/core"
import AdminCollapsePanel from "../AdminCollapsePanel/AdminCollapsePanel"
import ApproverAvatar from "../../../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../../../utils/dateTimeFormat"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import { useSelector } from "react-redux"

const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapseRequestsTable({
	isLoading,
	setIsLoading,
	data,
	page,
	setLoggedInId,
	showHour,
}) {
	const [process,setProcess] = React.useState(false)
	const [open, setOpen] = React.useState(false)
	const DOL = dateFormat(data.date_of_leave)
	const DOA = dateFormat(data.date_of_arrival)
	const permissions = useSelector((store)=>store.authReducer.permissions)
	const user = useSelector((store)=>store.authReducer.user)
	React.useEffect(() => {
		setOpen(false)
	}, [page])

	const handleApprove = (id) => {
		setProcess(true)
		axios
			.put(`api/leaveApprove/${id}`)
			.then((response) => {
				setProcess(false)
				notify("success", "leave has been approved")
				setIsLoading(!isLoading);
				setLoggedInId(response.data.userId)
			})
			.catch((error) => {
				notify("error", error.response.data)
				setProcess(false)
			})
	}

	return (
		<>
			<TableRow key={data.id}>
				<StyledTableCell>
					<IconButton onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>
					{data.first_name + " " + data.last_name}
				</StyledTableCell>
				<StyledTableCell>{data.leave_type_name}</StyledTableCell>
				<StyledTableCell>{DOL}</StyledTableCell>
				<StyledTableCell>{data.leave_time}</StyledTableCell>
				<StyledTableCell>{DOA}</StyledTableCell>
				<StyledTableCell>{data.arrival_time}</StyledTableCell>
				<StyledTableCell>
					{showHour === false ? data.durationDay : data.durationHour}
				</StyledTableCell>
				<StyledTableCell>
					{data.approvers.map((ad,key) => (
						<ApproverAvatar key={key} ad={ad} declineMessage={data.decline_message} />
					))}
				</StyledTableCell>

				<StyledTableCell>
				{(permissions?.includes("HR_leave_approval") ||data.approvers.map((e)=>e.supervisorId).includes((String)(user?.id))) &&
					(!process ?
					<IconButton
						style={{ color: "green" }}
						onClick={() => handleApprove(data.id)}
					>
						<Tooltip title="Approve">
							<ThumbUpIcon />
						</Tooltip>
					</IconButton>
					:
					<CircularProgress	
					/>)
					}
					{
						(permissions?.includes("HR_leave_approval") || data.approvers.map((e)=>e.supervisorId).includes((String)(user?.id))) &&
						<RejectLeaveModal
							isLoading={isLoading}
							setIsLoading={setIsLoading}
							id={data.id}
						/>
					}
				</StyledTableCell>
			</TableRow>

			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={10}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<AdminCollapsePanel
							status={data.name}
							leaveDescription={data.leave_description}
							leaveDoc={data.leave_documents}
							requestId={data.id}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
