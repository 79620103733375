import axios from '../../utils/axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/styles"
import { makeStyles } from "@material-ui/core"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {notify} from '../../utils/toast';
import moment from 'moment';

function SettleUL() {
  const [data,setData] = useState([])
  const [logData,setLogData] = useState([])
  const [month,setMonth] = useState(moment().format("YYYY-MM"))
  const [showSettlement,setShowSettlement] = useState(true)

  // for first trigger
  useEffect(()=>{

    axios.get('/api/leave/unsetteled_unpaid_leaves')
      .then((response)=>{
        setData(response.data);
      })
      .catch((err)=>{
        console.log(err);
      })

    axios.get('/api/leave_settlement_log/'+moment().format("YYYY-MM"))
      .then((response)=>{
        setLogData(response.data.data);
      })
      .catch((err)=>{
        console.log(err);
      })

  },[])

  useEffect(()=>{
    // trigger api here
    if(showSettlement){
      axios.get('/api/leave/unsetteled_unpaid_leaves/'+month)
      .then((response)=>{
        setData(response.data);
      })
      .catch((err)=>{
        console.log(err);
      })
    }else{
      axios.get('/api/leave_settlement_log/'+month)
      .then((response)=>{
        setLogData(response.data.data);
      })
      .catch((err)=>{
        console.log(err);
      })
    }
  },[month])

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#ff6600",
      color: theme.palette.common.white,
      textAlign: "center",
    },
  }))(TableCell)
  
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
      padding: "10px",
    },
    tableContainer: {
      width: "100%",
      margin: "auto",
    },
  })
  
  const classes = useStyles()

  return (
    <div>
        <h4 className='text-center my-4' style={{color: "#ff6600"}}>
          {showSettlement ? "Leave Settlement" : "Leave Settlement History" }
        </h4>
        <form className='my-3 row'>
            <div className="col-2">
              <input type="month" id='month' className='form-control' defaultValue={moment().format("YYYY-MM")} name="date" onChange={(e)=>{
              setMonth(e.target.value);
              }}/>
            </div>
            <div className="col-7">
              
            </div>
            <div className="col-3">
              <button className='btn' style={{backgroundColor: "#ff6600",color: "#ffff"}} onClick={(e)=>{
                e.preventDefault();
                // also trigger api
                if(!showSettlement){
                  axios.get('/api/leave/unsetteled_unpaid_leaves/'+month)
                  .then((response)=>{
                    setData(response.data);
                  })
                  .catch((err)=>{
                    console.log(err);
                  })
                }else{
                  axios.get('/api/leave_settlement_log/'+month)
                  .then((response)=>{
                    setLogData(response.data.data);
                  })
                  .catch((err)=>{
                    console.log(err);
                  })
                }
                // toggle between history and settlement
                setShowSettlement(!showSettlement);

              }}>{showSettlement ? "Settlement History":"Unpaid Leaves Settlement"}</button>
            </div>
        </form>
      {
        showSettlement ?
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow >
                <StyledTableCell>Employee Id</StyledTableCell>
                <StyledTableCell>Employee Name</StyledTableCell>
                <StyledTableCell>Settelable Unpaid Leave Count</StyledTableCell>
                <StyledTableCell>Unsettelable Unpaid Leave Count</StyledTableCell>
                <StyledTableCell>Complementary Leaves</StyledTableCell>
                <StyledTableCell>Deductable from salary</StyledTableCell>
                <StyledTableCell>Adjust</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {
                  data.length !== 0 ?
                  (data?.map((row,key)=>(
                    <TableRow key={key}>
                      <TableCell align='center' >{row.employee_id}</TableCell>
                      <TableCell align='center' >{row.employee_name}</TableCell>
                      <TableCell align='center' >{row.UL_count_settleable}</TableCell>
                      <TableCell align='center' >{row.UL_count_unsettleable}</TableCell>
                      <TableCell align='center' >{row.complementary_leaves}</TableCell>
                      <TableCell align='center' >{
                                  row.UL_count_settleable > 0 ? ( ((row.UL_count_settleable - row.complementary_leaves>0?(row.UL_count_settleable - row.complementary_leaves):0)) + row.UL_count_unsettleable) : row.UL_count_unsettleable
                      }</TableCell>
                      <TableCell align='center' >
                        <button className='py-1 px-2' style={{border: "1px solid green",borderRadius:"5px",backgroundColor: "transparent",color:"green"}} onClick={()=>{
                          let d = row;
                          d.deductable = row.UL_count_settleable > 0 ? ( ((row.UL_count_settleable - row.complementary_leaves>0?(row.UL_count_settleable - row.complementary_leaves):0)) + row.UL_count_unsettleable) : row.UL_count_unsettleable;
                          d.date = month;
                          axios.post('api/leave/settlement',d)
                          .then((response)=>{
                            if(response.data.code === 200){
                              setData(data.filter((a)=>{
                                return a.employee_id !== row.employee_id;
                              }));
                              notify("success",response.data.message);
                            }else{
                              notify("error",response.data.error);
                            }
                            
                          })
                          .catch((err)=>{
                            notify("error",err);
                          })
                        }}>
                          <CheckCircleIcon style={{color: "green"}}/> Adjust
                        </button>
                      </TableCell>
                    </TableRow>
                  )))
                  :
                  <TableRow>
                    <TableCell colSpan={7}>
                      <h6 className='text-center'>No Unpaid Leaves Here to settle.</h6>
                    </TableCell>
                  </TableRow>
                }
                
            </TableBody>
          </Table>
			  </TableContainer>
        :
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow >
                <StyledTableCell>S.N.</StyledTableCell>
                <StyledTableCell>Employee Name</StyledTableCell>
                <StyledTableCell>Settelable Unpaid Leave Count</StyledTableCell>
                <StyledTableCell>Unsettelable Unpaid Leave Count</StyledTableCell>
                <StyledTableCell>Complementary Leaves</StyledTableCell>
                <StyledTableCell>Deductable from salary</StyledTableCell>
                <StyledTableCell>Adjusted at</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {
                  logData.length !== 0 
                  ?
                  (logData?.map((row,key)=>(
                    <TableRow key={key}>
                      <TableCell align='center' >{key + 1}</TableCell>
                      <TableCell align='center' >{row.employee}</TableCell>
                      <TableCell align='center' >{row.settleable_leave_count}</TableCell>
                      <TableCell align='center' >{row.unsettleable_leave_count}</TableCell>
                      <TableCell align='center' >{row.complementary_leave}</TableCell>
                      <TableCell align='center' >{row.deductable}</TableCell>
                      <TableCell align='center' >{row.settled_at}</TableCell>
                    </TableRow>
                  )))
                  :
                  <TableRow>
                    <TableCell colSpan={7}>
                      <h6 className='text-center'>No Unpaid Leaves settlement log</h6>
                    </TableCell>
                  </TableRow>
                }
            </TableBody>
          </Table>
        </TableContainer>
        }
    </div>
  )
}

export default SettleUL