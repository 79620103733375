import React, { useEffect } from "react";
import { useParams } from "react-router";
import AllocationModal from "./AllocationModal";
import { useDispatch, useSelector } from "react-redux";
import { getInventory } from "../Redux/actions/inventoryactions";
import UnAllocate from "./UnAllocate";

const AllocationActions = () => {
  const dispatch = useDispatch();
  const inventoryReducer = useSelector((store) => store.inventoryReducer);
  const { inventory } = inventoryReducer;
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    dispatch(getInventory(id));
  }, [dispatch, id]);
  return (
    <div>
      {inventory.isAllocated ? (
        <UnAllocate id={id} />
      ) : (
        <AllocationModal className="ms-auto" />
      )}
    </div>
  );
};

export default AllocationActions;
