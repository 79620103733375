import React from "react";
import Spinner from "../Spinner/Spinner";
//Material table
import { forwardRef } from "react";
import MaterialTable from "material-table";
import {
  ArrowDownward,
  Search,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import { Alert } from "react-bootstrap";
import axios from "../utils/axios";
import { useParams } from "react-router";
import ReturnFromRepair from "./ReturnFromRepair";
import { useSelector } from "react-redux";
import SendRepairModal from "./SendRepairModal";
import { notify } from "../utils/toast";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const RepairsTable = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [repairs, setRepairs] = React.useState([]);
  const sentAt = repairs.find(r => (r.sentAt !== null) && (r.returnedAt === null))?.sentAt;
  const inRepair =
    useSelector((store) => store.inventoryReducer.inventory.inRepair) || false;
  const isDisposed =
    useSelector((store) => store.inventoryReducer.inventory.isDisposed) ||
    false;
  const params = useParams();
  const permissions = useSelector((store)=>store.authReducer.permissions)
  React.useEffect(() => {
    axios
      .get(`/api/admin/inventories/${params.id}/repairs`)
      .then((res) => {
        setRepairs(res.data.result);
        setIsLoading(false);
      })
      .catch((err) => 
        notify('error','Something went wrong !')
      );
  }, [params.id]);
  const columns = [
    {
      field: "sentAt",
      title: "Sent Date",
      filtering: false,
    },
    {
      field: "returnedAt",
      title: "Returned Date",
      filtering: false,
    },
    {
      field: "cost",
      title: "Cost",
      filtering: false,
    },
    {
      field: "remarks",
      title: "Remarks",
      filtering: false,
      hidden: true,
    },
    {
      field: "isCurrentRepair",
      title: "Is current repair",
      filtering: false,
      hidden: true,
    },
  ];
  let rows =
    (repairs.length &&
      repairs
        .map((repair) => {
          return {
            sentAt: repair.sentAt ? repair.sentAt : "-",
            returnedAt: repair.returnedAt ? repair.returnedAt : "In Progress",
            cost: repair.cost ? "Rs. " + repair.cost : "-",
            remarks: repair.remarks ? repair.remarks : "-",
            isCurrentRepair: repair.isCurrentRepair,
          };
        })
        .reverse()) ||
    [];
  if (isLoading) return <Spinner />;
  return (
    <div className="rounded my-3 bg-light" data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
      <MaterialTable
        icons={tableIcons}
        title="Repairs list"
        data={rows}
        columns={columns}
        options={{
          pageSize: 10,
          headerStyle: {
            fontSize: "1rem",
            backgroundColor: "#ff6600",
            color: "whitesmoke",
          },
          actionsColumnIndex: -1,
          rowStyle: (rowData) => {
            if (rowData.isCurrentRepair === true) {
              return {
                backgroundColor: "#C8E8B9",
              };
            }
          },
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(rowData) => {
          return (
            <Alert variant="info m-0" style={{ paddingLeft: "4%" }}>
              <p>
                <span>Remarks:</span>
                <p style={{ fontStyle: "italic" }}>{rowData.remarks}</p>
              </p>
            </Alert>
          );
        }}
        actions={[
          {
            icon: "Add",
            tooltip: "Return",
            isFreeAction: true,
            onClick: (event, rowData) => console.log("hello"),
          },
        ]}
        components={{
          Action: (props) => {
            if (props.action.isFreeAction && inRepair && !isDisposed) {
              return ( permissions?.includes("send_repair") &&
                 <ReturnFromRepair repairs={repairs} setRepairs={setRepairs} sentAt={sentAt}>
                  Return from repair{" "}
                  <i className="fas fa-arrow-alt-circle-left ms-2"></i>
                </ReturnFromRepair>
              );
            } else if (!isDisposed) {
              return ( permissions?.includes("send_repair") &&
                <SendRepairModal repairs={repairs} setRepairs={setRepairs}>
                  Send to repair <i className="fas fa-share-square ms-2"></i>
                </SendRepairModal>
              );
            } else return <></>;
          },
        }}
      />
    </div>
  );
};

export default RepairsTable;
