
import React, { useEffect } from 'react'
import { useState } from 'react'
import { notify } from '../utils/toast.js';
import axios from '../utils/axios'
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory, useParams } from 'react-router-dom';
import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Tooltip from '@material-ui/core/Tooltip';

function RolePermissionPage() {
    const params = useParams();
    const [permissions,setPermissions] = useState([]);
    const [prevPermissions,setPrevPermissions] = useState([]);
    const [changedPermission,setChangedPermission] = useState([]);
    const [currentRole,setCurrentRole] = useState(params.id);
    const [permissionType,setPermissionType] = useState([]);
    const [role,setRole] = useState();
    const history = useHistory();

    useEffect(()=>{
        //fetch role
        currentRole && axios.get('api/get/role/'+currentRole)
            .then((res)=>{
                setRole(res.data);
            })
            .catch((err)=>{
                notify("error","Something went wrong")
            })
        // default permissions fetch
        currentRole && axios.get('api/get/rolePermissions/'+currentRole)
            .then((res)=>{
                let defaultPermissions = res.data;
                setPermissions(defaultPermissions);
                setPrevPermissions(defaultPermissions);
                // create dynamic list for permission types
                let permissionTypes = defaultPermissions.map((element)=> element.type);
                permissionTypes = new Set(permissionTypes); //convert to set for unique elements in array
                permissionTypes = Array.from(permissionTypes); //convert back to array with uniques elements
                setPermissionType(permissionTypes);
            })
            .catch((err)=>{
                notify("error","Something went wrong")
            })
    },[currentRole])

    const handleChange = (e) => {
        let changeData = {
            name: e.target.name,
            id: e.target.id,
            type: e.target.getAttribute('typename'),
            label: e.target.getAttribute('label'),
            description: e.target.getAttribute('description'),
            checked: e.target.checked
        }

        // for maintaining state of permission when checked or unchecked
        let data = permissions.map((element)=>
            {
                if(element.id == changeData.id){
                    return changeData;
                }else{
                    return element;
                }
            }
        )
        setPermissions(data);

        // for removing duplication when changed same permission more than once
        if(changedPermission.length > 0){
            let temp = changedPermission.filter((element)=> element.id !== changeData.id);
            if(temp !== []){
                temp.push(changeData);
                setChangedPermission(temp);
            }else{
                setChangedPermission([...changedPermission,changeData]);
            }
        }else{
            setChangedPermission([changeData]);
        }
        
    }

    const onSave = () => {
        const data = {
            permissions: changedPermission,
            role: currentRole
        }
        // edit permission
        axios.post('api/edit/permission',data)
        .then((res)=>{
            notify("success",res.data);
            setChangedPermission([]);
        })
        .catch((err)=>{
            notify("error",err.response.data);
        })
    }

    const onCancel = () => {
        setPermissions(prevPermissions);
        setChangedPermission([]);
    }
    
    return (
        <div>
            <div className="container-fluid text-center">
                <h2 style={{color: '#ff6600'}} className="mb-3">Manage Permissions : {role?.name}</h2>
                <div className="row my-3">
                    <div className="col-2 align-middle">
                        <Button
                            color="secondary"
                            variant="outlined"
                            style={{ fontSize: "13px" }}
                            onClick={()=> {
                                history.goBack();
                            }}
                            
                            >
                            <ArrowBackIosIcon style={{fontSize: "12px"}}/>
                            <span>Back</span>
                        </Button>
                    </div>
                    <div className="col-10">
                    </div>
                </div>
                <div className="row">
                    <form className='form'>
                        <div className="container my-4">
                            <div className="row pb-5" style={{maxHeight: "60vh",overflowY:"scroll"}}>
                                {
                                    permissionType.map((type,key)=>{
                                        return <div className="col-md-3 mt-3" key={key}>
                                            <h4>{type}</h4>
                                            <hr />
                                            {
                                                permissions.map((e)=>{
                                                    if(e.type === type){
                                                        return (
                                                                <div className="text-start">
                                                                    <Checkbox
                                                                        checked={e.checked}
                                                                        inputProps={{typename:e.type,description:e.description,label:e.label}}
                                                                        onChange={handleChange}
                                                                        name={e.name}
                                                                        id={e.id}
                                                                    />
                                                                    <Tooltip title={e.description} placement="right" arrow >
                                                                        <label htmlFor={e.id}>{e.label}</label>
                                                                    </Tooltip>
                                                                </div>
                                                                )
                                                    }
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </div>
                            {
                            changedPermission.length >0 &&
                                <div className="d-flex justify-content-center gap-3">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        style={{ fontSize: "13px" ,color: "#ffffff"}}
                                        onClick={onSave}
                                        >
                                        <span>Save</span>
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        style={{ fontSize: "13px"}}
                                        onClick={onCancel}
                                        >
                                        <span>Cancel</span>
                                    </Button>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RolePermissionPage
