import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Button } from "@material-ui/core";
// import { Form } from "react-bootstrap";
import axios from "../utils/axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../Common/Form/TextInput/TextInput";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 0 8px #ff6600",
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddCategoryModal({
  categories,
  setCategories,
  inventory,
  setInventory,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <AddCircleIcon
        onClick={handleOpen}
        style={{ fontSize: "32px", margin: "5px", cursor: "pointer" }}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" style={{ color: "#ff6600" }}>
              Add category
            </h2>
            <p id="transition-modal-description">
              <Formik
                initialValues={{ category: "" }}
                validationSchema={Yup.object({
                  category: Yup.string()
                    .max(15, "Must be 15 characters or less")
                    .required("Required"),
                })}
                onSubmit={(values) => {
                  axios
                    .post("/api/categories", JSON.stringify({ category: values.category }))
                    .then((res) => {
                      const updateCategories = [...categories, res.data.result];
                      setCategories(updateCategories);
                      setInventory({
                        ...inventory,
                        category: res.data.result.name,
                      });
                      handleClose();
                    })
                    .catch((res) => console.log(res));
                }}
              >
                <Form>
                  <TextInput
                    label="Category"
                    name="category"
                    type="text"
                    placeholder="Category"
                  />
                  <br />
                  <Button type="submit" variant="outlined" color="primary">
                    Add
                  </Button>
                </Form>
              </Formik>
            </p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
