const routes = [
    {
        path:"/" ,
        permissions:[]
    },
    {
        path:"/login" ,
        permissions:[]
    },
    {
        path:"/oauth" ,
        permissions:[]
    },
    {
        path:"/forgotpassword" ,
        permissions:[]
    },
    {
        path:"/resetpassword/:token" ,
        permissions:[]
    },
    {
        path:"/dashboard" ,
        permissions:[]
    },
    {
        path:"/dashboard/users" ,
        permissions:["view_user"]
    },
    {
        path:"/dashboard/inventories",
        permissions:["view_inventory"]
    },
    {
        path:"/dashboard/inventories/:id",
        permissions:["view_inventory_details"]
    },
    {
        path:"/dashboard/myInventories",
        permissions:[]
    },
    {
        path:"/dashboard/ULsettlement",
        permissions:["settle_leave"]
    },
    {
        path:"/dashboard/requests" ,
        permissions:[]
    },
    {
        path:"/dashboard/requests/add" ,
        permissions:["request_inventory"]
    },
    {
        path:"/dashboard/servicings",
        permissions:["view_servicing"]
    },
    {
        path:"/dashboard/leaveTab" ,
        permissions:[]
    },
    {
        path:"/dashboard/myLeaveDetails",
        permissions:[]
    },
    {
        path:"/dashboard/leaveTab/edit/:id",
        permissions:["edit_leave"]
    },
    {
        path:"/dashboard/myLeaveDetails/edit/:id",
        permissions:[]
    },
    {
        path:"/dashboard/users",
        permissions:["view_user"]
    },
    {
        path:"/dashboard/users/:id",
        permissions:[]
    },
    {
        path:"/dashboard/users/setting/:id" ,
        permissions:[]
    },
    {
        path:"/dashboard/users/edit/:id",
        permissions:["edit_user"]
    },
    {
        path:"/dashboard/users/add",
        permissions:["add_user"]
    },
    {
        path:"/dashboard/inventories/add",
        permissions:["add_inventory"]
    },
    {
        path:"/dashboard/applyLeave",
        permissions:[]
    },
    {
        path:"/dashboard/inventories/edit/:id",
        permissions:["edit_inventory"]
    },
    {
        path:"/dashboard/leaveTab/leaveTypes",
        permissions:[]
    },
    {
        path:"/dashboard/workTab",
        permissions:["view_extra_work_request"]
    },
    {
        path:"/dashboard/assignExtraWork",
        permissions:["assign_extra_work"]
    },
    {
        path:"/dashboard/users/add/pastLeave/:id",
        permissions:["add_past_leave"]
    },
    {
        path:"/dashboard/inventories/:id",
        permissions:["view_inventory"]
    },
    {
        path:"/dashboard/work/applyForExtraWork",
        permissions:["request_extra_work"]
    },
    {
        path:"/dashboard/work/myExtraWorks",
        permissions:[]
    },
    {
        path:"/dashboard/wfhTab",
        permissions:[]
    },
    {
        path:"/dashboard/applyForWorkFromHome",
        permissions:["request_work_from_home"]
    },
    {
        path:"/dashboard/wfh/myWFH",
        permissions:[]
    },
    {
        path:"/dashboard/permission",
        permissions:["manage_permission"]
    },
    {
        path:"/dashboard/permission/:id",
        permissions:["manage_permission"]
    }
    
]
export default routes;