import "./App.css"
import Topbar from "./Components/topbar/Topbar"
import Landing from "./Components/LandingPage/Landing"
import { Route, Switch,useHistory } from "react-router-dom"
import Login from "./Components/Login/Login"
import Footer from "./Components/Footer/Footer"
import { useEffect, useState } from "react"
import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound.jsx"
import ForgotPassword from "./Components/Email/ForgotPassword"
import ResetPassword from "./Components/Email/ResetPassword"
import RequestTable from "./Components/Request/RequestTable"
import UserProfile from "./Components/Users/profile/UserProfile"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Oauth from "./Components/Login/Oauth"
import LeaveApplyForm from "./Components/LeaveApplyForm/LeaveApplyForm"
import axios from "axios"
import Aos from "aos";
import "aos/dist/aos.css";
import Setting from "./Components/setting/Setting"
import { useSelector } from 'react-redux'
import { notify } from "./Components/utils/toast"
import ExtraWorkApplyForm from "./Components/ExtraWork/ExtraWorkApplyForm"
import MyExtraWorks from "./Components/ExtraWork/MyExtraWorks"
import CommonRoute from "./Components/routing/CommonRoute"
import Charts from "./Components/Charts/Charts"
import UserList from "./Components/Pages/user/UserList"
import Inventories from "./Components/Pages/inventories/Inventories"
import SettleUL from "./Components/Pages/SettleUL/SettleUL"
import ServicingList from "./Components/servicings/ServicingList"
import { AdminLeaveTab } from "./Components/AdminLeaveTab/AdminLeaveTab"
import MyLeaveDetailsTable from "./Components/AdminLeaveTab/AdminLeaveTables/MyLeaveDetailsTable"
import EditLeaveForm from "./Components/AdminLeaveTab/LeaveFormAdmin/EditLeaveForm"
import EditUser from "./Components/EditUser/EditUser"
import AddUser from "./Components/Pages/AddUser/AddUser"
import AddInventory from "./Components/AddInventoryForm/AddInventoryForm"
import EditInventory from "./Components/EditInventory"
import LeaveTypeTable from "./Components/AdminLeaveTab/LeaveTypeTable/LeaveTypeTable"
import { AdminWorkTab } from "./Components/AdminWorkTab/AdminWorkTab"
import AssignExtraWork from "./Components/AssignExtraWork/AssignExtraWork"
import AddPastLeave from "./Components/AddPastLeave/AddPastLeave"
import Tabpanel from "./Components/Tabs/Tabpanel"
import SideDrawer from "./Components/sidebar/SideDrawer"
import ManagePermission from "./Components/ManagePermission/ManagePermission"
import RolePermissionPage from "./Components/ManagePermission/RolePermissionPage"
import UserInventories from "./Components/userHomePage/UserInventories"
import RequestForm from "./Components/Pages/RequestForm/RequestForm"
import { AdminWorkFromHomeTab } from "./Components/AdminWorkFromHomeTab/AdminWorkFromHomeTab"
import WorkFromHomeApplyForm from "./Components/WorkFromHome/WorkFromHomeApplyForm"
import MyWFH from "./Components/WorkFromHome/MyWFH"

function App() {
	const { isLoading, isAuthenticated, user, token } = useSelector((store) => store.authReducer);
	let designation = user?.designation || "";
	let queryString = window.location.search;
	let parameters = new URLSearchParams(queryString);
	let type = parameters.get('type');
	const history = useHistory();
	const pathName = history.location.pathname;
	const [isSA,setIsSA] = useState(false);
	useEffect(() => {
		// check for sa_token 
		const sa_token = localStorage.getItem('sa_token');
		if(sa_token == null || (/\/login/.test(pathName))){
			setIsSA(false);
		}else{
			setIsSA(true);
		}
		Aos.init({}); // Initialize animation
		axios
			.post(`${process.env.REACT_APP_API_URL}setLeaveNotify`)
			// .then((res) => console.log(res))
			// .catch((err) => console.log(err))
	}, [pathName])
	// Redirect to docs if query string received
	let docsUsers = ["engineer", "ceo", "cto", "intern"];
	if(!isLoading && isAuthenticated && type === 'docs' && docsUsers.includes(designation?.toLowerCase())) {
		window.location.replace(`https://docs.wolfmatrix.dev?token=${token}`); 
		return null;
	}
	else return (
		<div className="App">
			<div style={isSA? {display: "block", zIndex: 1000, position: "fixed", bottom: "70px", right: "20px"}:{display: "none"}}>
				<div className="btn btn-secondary" onClick={()=>{
					// do all revert stuffs
					const sa_token = localStorage.getItem('sa_token');
					const sa_token_expires_at = localStorage.getItem('sa_token_expires_at');
					localStorage.removeItem('token');
					localStorage.removeItem('token_expires_at');
					localStorage.setItem('token',sa_token);
					localStorage.setItem('token_expires_at',sa_token_expires_at);
					localStorage.removeItem('sa_token');
					localStorage.removeItem('sa_token_expires_at');
					notify('success', "Logged in as SuperAdmin");
					window.location.reload();
				}}>
					Back to SuperAdmin 
				</div>
			</div>
			<div>
				<ToastContainer
					position="top-center"
					autoClose={3000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
			
			<Topbar />
			<div className="container-fluid mb-5">
				
				{!localStorage.getItem("token") ? (
					<>
					<Switch>
					<Route 
						exact 
						path="/" 
						component={Landing} />
					<Route 
						path="/login" 
						exact 
						component={Login} />
					<Route 
						path="/forgotpassword" 
						exact 
						component={ForgotPassword} />
					<Route 
						path="/resetpassword/:token" 
						exact 
						component={ResetPassword} />
					
					<Route 
						path="/oauth" 
						component={Oauth} />
					<Route 
						path="*"
						exact 
						component={PageNotFound} 
					/>	
					</Switch>
					</>) : (
						<SideDrawer>
						<Switch>
							<CommonRoute 
								exact 
								path="/"
								component={Charts} />
							<CommonRoute 
								exact 
								path="/oauth"
								component={Oauth} />
							<CommonRoute 
								exact 
								path="/dashboard"
								component={Charts} />
							<CommonRoute 
								exact 
								path="/dashboard/users" 
								component={UserList} />
							<CommonRoute exact
								path="/dashboard/users/setting/:id" 
								component={Setting} 
							/>
							<CommonRoute exact
								path="/dashboard/permission" 
								component={ManagePermission} 
							/>
							<CommonRoute exact
								path="/dashboard/permission/:id" 
								component={RolePermissionPage} 
							/>
							<CommonRoute exact
								path="/dashboard/ULsettlement" 
								component={SettleUL} 
							/>
							<CommonRoute
								exact
								path="/dashboard/inventories"
								component={Inventories}
							/>
							<CommonRoute
								exact
								path="/dashboard/inventories/add"
								component={AddInventory}
							/>
							<CommonRoute
								exact
								path="/dashboard/servicings"
								component={ServicingList}
							/>
							<CommonRoute
								exact
								path="/dashboard/users/edit/:id"
								component={EditUser}
							/>
							<CommonRoute
								exact
								path="/dashboard/users/add/pastLeave/:id"
								component={AddPastLeave}
							/>
							<CommonRoute 
								exact 
								path="/dashboard/users/add" 
								component={AddUser} />
							<CommonRoute
								exact
								path="/dashboard/users/:id"
								component={UserProfile}
							/>
							<CommonRoute
								exact
								path="/dashboard/inventories/edit/:id"
								component={EditInventory}
							/>
							<CommonRoute
								exact
								path="/dashboard/inventories/:id"
								component={Tabpanel}
							/>
							<CommonRoute
								exact
								path="/dashboard/leaveTab"
								component={AdminLeaveTab}
							/>
							<CommonRoute
								exact
								path="/dashboard/workTab"
								component={AdminWorkTab}
							/>
							<CommonRoute
								exact
								path="/dashboard/assignExtraWork"
								component={AssignExtraWork}
							/>
							<CommonRoute
								exact
								path="/dashboard/wfhTab"
								component={AdminWorkFromHomeTab}
							/>
							<CommonRoute
								exact
								path="/dashboard/applyForWorkFromHome"
								component={WorkFromHomeApplyForm}
							/>
							<CommonRoute
								exact
								path="/dashboard/wfh/myWFH"
								component={MyWFH}
							/>
							<CommonRoute
								exact
								path="/dashboard/myLeaveDetails"
								component={MyLeaveDetailsTable}
							/>
							<CommonRoute
								exact
								path="/dashboard/applyLeave"
								component={LeaveApplyForm}
							/>
							<CommonRoute
								exact
								path="/dashboard/leaveTab/edit/:id"
								component={EditLeaveForm}
							/>
							<CommonRoute
								exact
								path="/dashboard/myLeaveDetails/edit/:id"
								component={EditLeaveForm}
							/>
							<CommonRoute
								exact
								path="/dashboard/leaveTab/leaveTypes"
								component={LeaveTypeTable}
								/>
							<CommonRoute
								exact
								path="/dashboard/requests"
								component={RequestTable}
								/>

							<CommonRoute
								exact
								path="/dashboard/requests/add"
								component={RequestForm}
								/>
							<CommonRoute
								exact
								path="/dashboard/myInventories"
								component={UserInventories}
							/>
							
							<CommonRoute
								exact
								path="/dashboard/work/applyForExtraWork"
								component={ExtraWorkApplyForm}
							/>
							<CommonRoute
								exact
								path="/dashboard/work/myExtraWorks"
								component={MyExtraWorks}
							/>		
							<CommonRoute 
								path="*"
								exact 
								component={PageNotFound} 
							/>				
						</Switch>
						</SideDrawer>
					)}
			</div>
			<Footer />
		</div>
	)
}

export default App
