import React, { useEffect, useState } from "react"
import {
	Store,
	Person,
	BusinessCenter,
	CropOriginal,
	Filter,
	Filter9Plus,
	People,
	Note,
} from "@material-ui/icons"
import { updateInventory } from "./Redux/actions/inventoryactions"
import { useDispatch, useSelector } from "react-redux"
import { fetchUsers } from "./Redux/actions/userActions"
import { getInventory } from "./Redux/actions/inventoryactions"
import BuildIcon from "@material-ui/icons/Build"
import { Link, useParams } from "react-router-dom"
import Spinner from "./Spinner/Spinner"

export default function EditInventory() {
	const params = useParams()
	const [inventory, setInventory] = useState({
		name: "",
		brand: "",
		model: "",
		category: "",
		userEmail: "",
		serialNumber: "",
		addedMonth: 0,
		description: "",
	})
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchUsers())
		dispatch(getInventory(params.id))
		// eslint-disable-next-line
	}, [])
	const inventoryItem = useSelector((store) => store.inventoryReducer.inventory)
	useEffect(() => {
		setInventory({
			...inventory,
			name: inventoryItem ? inventoryItem.name : "",
			brand: inventoryItem ? inventoryItem.brand : "",
			model: inventoryItem ? inventoryItem.model : "",
			category: inventoryItem ? inventoryItem.category : "",
			serialNumber: inventoryItem ? inventoryItem.serialNumber : "",
			description: inventoryItem ? inventoryItem.description : "",
		})
		// eslint-disable-next-line
	}, [inventoryItem])

	const userReducer = useSelector((store) => store.userReducer)
	const { users, isLoading } = userReducer

	function handleChange(e) {
		const { name, value } = e.target
		setInventory({ ...inventory, [name]: value })
	}
	function handleSubmit(e) {
		e.preventDefault()
		const user = document.getElementById("user").value
		const newInventory = { ...inventory, userEmail: user }
		dispatch(updateInventory(params.id, newInventory))
		// history.push("/dashboard/inventories");
	}
	if (isLoading || !inventoryItem || !users) return <Spinner />
	return (
		<div className="container bg-light" style={{ maxWidth: "950px" }}>
			<div className="card-header text-center mx-2 my-3">
				<Store /> Update inventory
			</div>
			<form onSubmit={handleSubmit}>
				<div className="container mx-2 my-3">
					<div className="form-group my-3">
						<label>
							<Person />
							Name
						</label>
						<input
							type="text"
							className="form-control"
							placeholder="Inventory Name"
							name="name"
							value={inventory.name}
							onChange={handleChange}
							required
						></input>
					</div>
					<div className="form-group my-3">
						<label>
							<BusinessCenter />
							Brand
						</label>
						<input
							type="text"
							className="form-control"
							placeholder="Brand Name"
							name="brand"
							value={inventory.brand}
							onChange={handleChange}
						></input>
					</div>
					<div className="form-group my-3">
						<label>
							<CropOriginal />
							Model
						</label>
						<input
							type="text"
							className="form-control"
							placeholder="Model Name"
							name="model"
							value={inventory.model}
							onChange={handleChange}
						></input>
					</div>
					<div className="input-group my-3">
						<div className="input-group-prepend">
							<label className="input-group-text">
								<Filter />
								Category
							</label>
						</div>
						<select
							className="custom-select"
							id="device"
							name="category"
							value={inventory.category}
							onChange={handleChange}
							required
						>
							<option value="Devices">Devices</option>
							<option defaultChecked="Furniture">Furniture</option>
						</select>
					</div>
					<div className="form-group my-3">
						<label>
							<Filter9Plus />
							Serial Number
						</label>
						<input
							type="text"
							className="form-control"
							placeholder="Serial Number"
							name="serialNumber"
							value={inventory.serialNumber}
							onChange={handleChange}
							required
						></input>
					</div>
					<div className="input-group my-3">
						<div className="input-group-prepend">
							<label className="input-group-text">
								<People />
								Allocate To:
							</label>
						</div>
						<select className="custom-select" id="user">
							{users.map((user) => {
								if (user.email === inventoryItem.user.email) {
									return (
										<option selected key={user.id} value={user.email}>
											{user.fullName}
										</option>
									)
								}
								return (
									<option key={user.id} value={user.email}>
										{user.fullName}
									</option>
								)
							})}
						</select>
					</div>
					<div className="input-group my-3">
						<div className="input-group-prepend">
							<label className="input-group-text">
								<BuildIcon />
								Increase servicing date
							</label>
						</div>
						<input
							type="number"
							className="form-control"
							placeholder="Added months"
							name="addedMonth"
							value={inventory.addedMonth}
							onChange={handleChange}
							min={0}
							max={100}
						></input>
					</div>
					<div className="form-group my-3 p-2">
						<label>
							<Note />
							Description
						</label>
						<textarea
							className="form-control"
							rows="3"
							name="description"
							value={inventory.description}
							onChange={handleChange}
						></textarea>
						<button type="submit" className="btn btn-outline-success mt-3">
							Update
						</button>
						<Link
							to="/dashboard/inventories"
							className="btn ms-2 btn-outline-secondary mt-3"
						>
							Cancel
						</Link>
					</div>
				</div>
			</form>
		</div>
	)
}
