import React from "react"
import CollapsePanel from "./CollapsePanel/CollapsePanel"
import Collapse from "@material-ui/core/Collapse"
import TableRow from "@material-ui/core/TableRow"
import { Chip, IconButton, makeStyles } from "@material-ui/core"
import { TableCell } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import ApproverAvatar from "../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../utils/dateTimeFormat"
import { withStyles } from "@material-ui/styles"
import { Cancel } from "@material-ui/icons"
import axios from '../utils/axios'
import { notify } from "../utils/toast"

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
})
const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapsiableTable({
	ld,
	setWorkDetails,
	page,
	rowsPerPage,
	setCount,
	showHour,
	fromSummary,
	isLoaded,
	setIsLoaded,
}) {
	const [open, setOpen] = React.useState()
	React.useEffect(() => {
		setOpen(false)
	}, [page])
	const classes = useRowStyles()
	const SD = dateFormat(ld.start_date)
	const AD = dateFormat(ld.end_date)
	const ST = ld.start_time
	const ET = ld.end_time
	const handleClick = () => {
		setOpen(!open)
	}

	const handleCancel = (id) =>{
		axios
			.get(`api/WFHCancel/${id}`)
			.then((res)=>{
				notify(res.data);
				setIsLoaded(!isLoaded);
			})
			.catch((err)=>{
				notify("error",err.response);
			})

	}

	return (
		<>
			<TableRow className={classes.root}>
				<StyledTableCell>
					<IconButton onClick={() => handleClick()}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>{SD}</StyledTableCell>
				<StyledTableCell>{ST}</StyledTableCell>
				<StyledTableCell>{AD}</StyledTableCell>
				<StyledTableCell>{ET}</StyledTableCell>
				<StyledTableCell>
					{showHour === true ? ld.durationHour : ld.durationDay}
				</StyledTableCell>
				{ld.work_from_home_status === "Approved" && (
					<StyledTableCell>
						<Chip
							label={ld.work_from_home_status}
							style={{ color: "white", backgroundColor: "green" }}
						/>
					</StyledTableCell>
				)}
				{(ld.work_from_home_status === "Rejected" || ld.work_from_home_status === "Cancelled" )&& (
					<StyledTableCell>
						<Chip
							label={ld.work_from_home_status}
							style={{ color: "white", backgroundColor: "red" }}
						/>
					</StyledTableCell>
				)}
				
				{ld.work_from_home_status === "Pending" && (
					<StyledTableCell>
						<Chip
							label={ld.work_from_home_status}
							style={{ color: "white", backgroundColor: "skyblue" }}
						/>
					</StyledTableCell>
				)}
				{!fromSummary && (
				<StyledTableCell >
					{ld.approvers.map((ad,key) => (
						<ApproverAvatar key={key} ad={ad} declineMessage={ld.decline_message+(ld.work_from_home_status==="Cancelled")} />
					))}
				</StyledTableCell>
				)}
				{
					ld.work_from_home_status === "Pending" && (
						<StyledTableCell>
							<IconButton onClick={()=>{handleCancel(ld.id)}}>
								<Cancel style={{ color: "red" }}/>
							</IconButton>
						</StyledTableCell>
					)
				}
			</TableRow>
			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={11}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<CollapsePanel
							ld={ld}
							workFromHomeReason={ld.work_from_home_reason}
							status = {ld.work_from_home_status}
							declineMessage={ld.decline_message}
							requestId={ld.id}
							isLoaded={isLoaded}
							setIsLoaded={setIsLoaded}
							setOpen={setOpen}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
