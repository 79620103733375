import React from "react";
import { Paper } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
    fontFamily: 'Poppins,sans-serif',
    fontSize: "13px",
    '&:hover': {
      cursor: 'pointer'
    }
  },
  paper: {
    padding: "18px",
    height: "80px",
    '&:hover': {
      boxShadow: " 2px 2px 15px 0px rgba(0,0,0,0.75)"
    }
  },
}));
const CountPaper = ({ icon, label, value ,path}) => {
  const classes = useStyles();
	const history = useHistory();
  return (
    <div className={classes.root} onClick={()=>{
      history.push(path)
    }}>
      <Paper elevation={3} className={classes.paper} >
        <Row className="align-items-center">
          <Col xs={4}>{icon}</Col>
          <Col xs={8}>
            <Row>
              <h5>{value || null}</h5>
            </Row>
            <Row>{label || null}</Row>
          </Col>
        </Row>
      </Paper>
    </div>
  );
};

export default CountPaper;
