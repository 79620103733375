import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Basics from "./Basics";
import More from "./More";
import UserInventories from "../../userHomePage/UserInventories";
import DocumentImages from "./DocumentImages";
// import { useSelector } from "react-redux";
import AllocationFromUser from "../../Allocation/AllocationFromUser"
import LeaveSummary from "./LeaveSummary";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 100,
    textTransform: "capitalize",
    border: "none",
    backgroundColor: "#ffff",
    boxShadow: "none",
    padding: "5px",
  },
  wrapper: {
    fontSize: "12px",
    fontWeight: "600",
    width: "100px",
  }
}));

export default function ProfileTabs({user,role}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <div>
      <AppBar
        position="static"
        style={{ backgroundColor: "white", width: "100%", boxShadow: "none" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Basics" icon={<i className="fas fa-info-circle"></i>} {...a11yProps(0)} classes={classes} />
          <Tab label="More" icon={<i className="fas fa-ellipsis-h"></i>} {...a11yProps(1)} classes={classes} />
          <Tab label="Documents" icon={<i className="fas fa-file-image"></i>} {...a11yProps(2)} classes={classes} />
          <Tab label="Inventories" icon={<i className="fas fa-boxes"></i>} {...a11yProps(3)} classes={classes} />
          <Tab label="Leave Summary" icon={<i className="fas fa-boxes"></i>} {...a11yProps(4)} classes={classes} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} style={{overflow: "hidden"}}>
          <Basics user={user} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} style={{overflow: "hidden"}}>
          <More user={user} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          style={{ overflow: "scroll" }}
        >
          <DocumentImages hasLeft={user.hasLeft} documents={user.documents} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <>
            {(role==="ROLE_ADMIN" && !user.hasLeft) && <AllocationFromUser />}
            <UserInventories />
          </>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction} style={{overflow: "hidden"}}>
          <LeaveSummary id={user.id} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
