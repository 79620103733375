import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { useHistory } from "react-router-dom";
//Material table
import { forwardRef } from "react";
import MaterialTable from "material-table";
import {
  disposeInventory,
  reviveInventory,
} from "../../Redux/actions/inventoryactions";
import {
  ArrowDownward,
  Add,
  Search,
  Clear,
  FilterList,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  SaveAlt,
} from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import Chip from "@material-ui/core/Chip";
import axios from "../../utils/axios";
import Avatar from "@material-ui/core/Avatar";
import moment from 'moment';

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
};

export default function Inventories() {
  const history = useHistory();
  const dispatch = useDispatch();
  const tableRef = React.createRef();
  const permissions = useSelector((store)=> store.authReducer.permissions);
  const titleStyle = {
    color: "#ff6600",
    margin: "10px",
    padding: "2px",
  };

  const columns = [
    {
      title: null,
      field: "avatar",
      render: (rowData) => (
        <Avatar alt="user" src={rowData.avatar} variant="square" />
      ),
      filtering: false,
      width: "100",
      export: false,
    },
    {
      field: "inventoryId",
      title: "Id",
      width: 200,
      filtering: false,
      render: (rowData) => (
				<label style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
					{rowData.inventoryId}
				</label>
			),
    },
    {
      field: "name",
      title: "Name",
      filtering: false,
    },
    {
      field: "category",
      title: "Category",
      filtering: true,
    },
    {
      field: "status",
      title: "Status",
      width: 200,
      filtering: false,
      render: (rowData) => {
        return rowData.status === "active" ? (
          <Chip
            key={rowData.status}
            label={rowData.status}
            style={{
              backgroundColor: "green",
              color: "white",
              width: "90px",
              fontWeight: "bold",
              opacity: "0.8",
            }}
          />
        ) : (
          <Chip
            key={rowData.status}
            label={rowData.status}
            style={{
              backgroundColor: "red",
              color: "white",
              width: "90px",
              fontWeight: "bold",
            }}
          />
        );
      },
    },
    {
      field: "owner",
      title: "Current Owner",
      width: 200,
      filtering: true,
    },
    {
      field: "cost",
      title: "Cost(Nrs.)",
      width: 200,
      filtering: false,
    },
  ];
  return (
    <div className="rounded my-3 bg-light" data-aos="fade-up"
    data-aos-anchor-placement="top-bottom">
      <MaterialTable
        tableRef={tableRef}
        icons={tableIcons}
        title={<h6 style={titleStyle}>Inventories List</h6>}
        data={(query) => {
          return new Promise((resolve, reject) => {
            let url = "/api/admin/inventories?";
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            let filters = null;
            if (query.filters.length) {
              filters = query.filters
                .map((f) => f.column.field + f.operator + f.value)
                .join("&");
              url += "&" + filters;
            }
            axios
              .get(url)
              .then((res) => {
                let inventories = res.data.result;
                let rows = inventories.map((inv) => {
                  return {
                    inventoryId: inv.inventoryId,
                    name: inv.name,
                    category: inv.category.name,
                    status: inv.isDisposed ? "disposed" : "active",
                    owner:
                      inv.allocations.find((all) => {
                        return all.isCurrentAllocation === true;
                      })?.user.firstName || "-",
                    cost: inv.cost,
                    avatar: inv.imagePath
                      ? process.env.REACT_APP_API_URL + inv.imagePath
                      : null,
                  };
                });
                resolve({
                  data: rows,
                  page: res.data.page - 1,
                  totalCount: res.data.totalCount,
                });
              })
              .catch((err) => console.log(err));
          });
        }}
        columns={columns}
        options={{
          exportButton: {
            csv: true,
            pdf: true
          },
          exportFileName:`Inventories-${moment().format("YYYYMMDD")}`,
          search: false,
          filtering: true,
          pageSize: 10,
          headerStyle: {
            backgroundColor: "#ff6600",
            color: "whitesmoke",
            height: "30px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 'bold',
            lineHeight: '1rem'
          },
          actionsColumnIndex: -1,
          actionsCellStyle: {
            // display: "flex",
            // justifyContent: "center",
            // padding: "10px",
            // width: "100%",
            // marginBottom: "-1px",
          },
          rowStyle: {
            color: "#111111",
            fontSize: "0.94rem",
            fontFamily: "Poppins, sans-serif",
          },
        }}
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => {
              tableRef.current && tableRef.current.onQueryChange();
            },
          },
          { 
            icon: () => <Add style={{ fontWeight: "bolder" ,display: permissions?.includes("add_inventory")?"block":"none"}} />,
            tooltip: "Add Inventory",
            isFreeAction: true,
            onClick: (event) => history.push("/dashboard/inventories/add"),
          },
          (rowData) => {
            if (rowData.status === "active" && permissions?.includes("edit_inventory")) {
              return {
                icon: () => <EditIcon style={{ color: "#ff6600"}} />,
                tooltip: "Edit Inventory",
                onClick: (event, rowData) => {
                  history.push("/dashboard/inventories/" + rowData.inventoryId);
                },
              };
            }
          },
          (rowData) => {
            if (rowData.status === "active" && permissions?.includes("delete_inventory")) {
              return {
                icon: () => <DeleteIcon style={{ color: "red" }} />,
                tooltip: "Dispose Inventory",
                onClick: (event, rowData) => {
                  dispatch(disposeInventory(rowData.inventoryId));
                  setTimeout(() => {
                    tableRef.current && tableRef.current.onQueryChange();
                  }, 400);
                },
              };
            } else if (rowData.status === "disposed") {
              return {
                icon: () => (
                  <SettingsBackupRestoreIcon style={{ color: "green" }} />
                ),
                tooltip: "Restore Inventory",
                onClick: (event, rowData) => {
                  dispatch(reviveInventory(rowData.inventoryId));
                  setTimeout(() => {
                    tableRef.current && tableRef.current.onQueryChange();
                  }, 400);
                },
              };
            }
          },
        ]}
        onRowClick={(event, rowData) => {
          history.push("/dashboard/inventories/" + rowData.inventoryId);
        }}
      />
    </div>
  );
}
