import React from "react";
import { MDBContainer } from "mdbreact";
import "./footer.css";

const Footer = () => {
	return (
		<div className="footer text-center py-3 text-light w-100 fixed-bottom">
				<MDBContainer fluid>
					&copy; {new Date().getFullYear()} Copyright:{" "}
					<a href=" https://www.wolfmatrix.com/" className="link">
						{" "}
						Wolfmatrix{" "}
					</a>
				</MDBContainer>
			</div>
	);
};

export default Footer;
