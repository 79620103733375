import React from "react"
import {
	TableCell,
	TableRow,
	IconButton,
	Collapse,
	withStyles,
	makeStyles,
} from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import AdminCollapsePanel from "../AdminCollapsePanel/AdminCollapsePanel"
import ApproverAvatar from "../../../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../../../utils/dateTimeFormat"
import ComplementaryChip from "../../../Common/ComplementaryChip/ComplementaryChip"

const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)
const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},
})
export default function CollapseHistoryTable({
	ld,
	setWorkDetails,
	page,
	rowsPerPage,
	setCount,
	showHour,
	fromSummary,
	isLoaded,
	setIsLoaded,
}) {
	const [open, setOpen] = React.useState(false)
	const classes = useRowStyles()
	const SD = dateFormat(ld.start_date)
	const AD = dateFormat(ld.end_date)
	const ST = ld.start_time
	const ET = ld.end_time
	React.useEffect(() => {
		setOpen(false)
	}, [page])
	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<>
			<TableRow className={classes.root}>
				<StyledTableCell>
					<IconButton onClick={() => handleClick()}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>{ld.first_name+" "+ld.last_name}</StyledTableCell>
				<StyledTableCell>{SD}</StyledTableCell>
				<StyledTableCell>{ST}</StyledTableCell>
				<StyledTableCell>{AD}</StyledTableCell>
				<StyledTableCell>{ET}</StyledTableCell>
				<StyledTableCell>
					{showHour === true ? ld.durationHour : ld.durationDay}
				</StyledTableCell>
				<StyledTableCell>
					<ComplementaryChip
						label={ld.complementary_status}
					/>
				</StyledTableCell>
				{!fromSummary && (
				<StyledTableCell >
					{ld.approvers.map((ad,key) => (
						<ApproverAvatar key={key} ad={ad} declineMessage={ld.decline_message+(ld.complementary_status==="Request Cancelled" && ld.remark)} />
					))}
				</StyledTableCell>
				)}
			</TableRow>
			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={11}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<AdminCollapsePanel
							ld={ld}
							workDescription={ld.work_description}
							status = {ld.complementary_status}
							declineMessage={ld.decline_message}
							remarkMessage={ld.remark}
							requestId={ld.id}
							isLoaded={isLoaded}
							setIsLoaded={setIsLoaded}
							setOpen={setOpen}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
