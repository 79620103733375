import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const Redirector = () => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }else{
    return null;
  }
};

export default Redirector;
