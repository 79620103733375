import React from "react"

export default function FormInput({
	label,
	type,
	name,
	value,
	handleChange,
	required,
	Icon,
	pattern,
	feedback,
}) {
	return (
		<div className="col-lg-4">
			<div className="form-group my-2">
				<label>
					{Icon}
					{label}
					{required && <small className="text-danger">*</small>}
				</label>
				<input
					type={type}
					pattern={pattern}
					className="form-control"
					name={name}
					value={value}
					onChange={handleChange}
				></input>
				{feedback && <small className="text-danger">{feedback}</small>}
			</div>
		</div>
	)
}
