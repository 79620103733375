import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExtraWorkAssignForm from "./ExtraWorkAssignForm";
import { useHistory } from "react-router"

export default function AssignExtraWork() {
  const history = useHistory()
  return (
    <div>
      <div className="container-fluid" style={{color:"#ff6600"}}>
        <div className="row">
          <div className="col-md-2">
            <Button
              color="secondary"
              variant="outlined"
              style={{ fontSize: "13px", marginTop:"2%", marginBottom:"5%"}}
              onClick={()=> {
                history.goBack();
              }}
            >
              <ArrowBackIosIcon style={{fontSize: "12px"}}/>
              <span>Back</span>
            </Button>
          </div>
      </div>
      <ExtraWorkAssignForm/>
    </div>
  </div>
  )
}
