import React from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { useSelector } from "react-redux"
const ConfirmULModal = (props) => {
	const { content, open, setOpen, onCancel, onConfirm , set , onSet} = props;
	const { user } = useSelector((store) => store.authReducer)

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="dialog-title"
		>
			<DialogContent>
				<div>{content}</div>
				<div style={ user.designation === "Intern" ?{display: "none"}:{display: "block"}}>
					<label htmlFor="settleUL">Unpaid Leave Settlable from Complementary Leave ? </label>
					<input type="checkbox" style={{appearance:"auto"}} checked={set} name="settleUL" id="settleUL"  
					onClick={()=>{
						onSet(!set)
					}}
					onChange={()=>console.log(`Settle Unpaid Leave :  ${!set}`)} 
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={() => {setOpen(false);onCancel();}} color="primary">
					Cancel
				</Button>
				<Button
					onClick={() => {
						setOpen(false);
						onConfirm();
					}}
					color="primary"
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmULModal;
