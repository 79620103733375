import React from "react";
import { Col, Row } from "react-bootstrap";

const InventoryPreview = ({ inventories, inventoryId }) => {
  const selectedInventory = inventories.find(
    (inv) => inv.inventoryId === inventoryId
  );
  const imagePath = selectedInventory?.imagePath;
  return (
    <>
      {selectedInventory && (
        <Row className="mt-4 p-1">
          <Col>
            <img
              width="200px"
              alt="inventory"
              src={
                imagePath
                  ? process.env.REACT_APP_API_URL + imagePath
                  : "/picture.jpg"
              }
            />
          </Col>
          <Col>
            <span className="h6">
              Brand:{" "}
              <label style={{ color: "#5E5E5E" }}>
                {selectedInventory.brand || -""}
              </label>
            </span>
            <br />
            <span className="h6">
              Model:{" "}
              <label style={{ color: "#5E5E5E" }}>
                {selectedInventory.model || "-"}
              </label>
            </span>
            <br />
            <span className="h6">
              Category:{" "}
              <label style={{ color: "#5E5E5E" }}>
                {selectedInventory.category.name}
              </label>
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default InventoryPreview;
