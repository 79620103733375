import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "./ForgotPassword.css";
import { Button } from "@material-ui/core";
import axios from "../utils/axios";
import { useHistory, useParams, Redirect, Link } from "react-router-dom";
import { notify } from "../utils/toast";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isValidUrl, setIsValidUrl] = useState(true);

  const params = useParams();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const history = useHistory();
  useEffect(() => {
    axios
      .get("/resetpassword/" + params.token)
      .then((res) => {
        setEmail(res.data.result.email);
        setIsValidUrl(true);
      })
      .catch((err) => {
        setIsValidUrl(false)
      });
  }, [params.token]);
  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "/resetpassword/" + params.token,
        JSON.stringify({
          password: passwordRef.current.value,
          confirmPassword: confirmPasswordRef.current.value,
        })
      )
      .then((res) => {
        setLoading(false);
        history.push("/login");
        notify("success", "password reset success please login!");
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err.response?.data?.errors)
      });
  }
  if (!isValidUrl) return <Redirect to="/login" />;
  return (
    <div className="main-wrapper">
      <h6>Reset password</h6>
      <div className="formInput">
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              readOnly
              value={email}
              className="form-control-plaintext p-2"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={passwordRef} />
            <Form.Text className="text-danger">
              {errors?.password || null}
            </Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control type="password" ref={confirmPasswordRef} />
            <Form.Text className="text-danger">
              {errors?.mismatch || null}
            </Form.Text>
          </Form.Group>
          <div className='mt-4'>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
            >
            {
              loading ? <ButtonLoader color="orange" /> : "Reset" 
            }
            </Button>
            <Link to="/login" className="text-decoration-none m-3">
              <Button variant="outlined" color="secondary">
                Cancel
              </Button>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
