import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React from 'react'
import MenuBar from './MenuBar'



export default function TipTap({content="", setContent=()=>{}, editable=true}) {
    const editor = useEditor({
        extensions: [
          StarterKit.configure({
            history: false,
          }),
        ],
        content:content,
        editable: editable,
        editorProps:editable?{
            attributes:{
                class:"form-control",
                style:"width:300px"
            }
        }:{
            attributes:{
                class:"text-start"
            }
        }
    })
    return (
        <div>
            {editor && editable && (<MenuBar editor={editor} />)}
            <EditorContent 
                editor={editor} 
            />
            {editor && setContent(editor.getHTML())}
        </div>
    )
}
