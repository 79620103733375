import React, { useEffect } from "react";
import { getInventoryServicings } from "../Redux/actions/servicingAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Spinner } from "react-bootstrap";
import ServicingTable from "./ServicingTable";
import { Alert } from "react-bootstrap";

const ServicingDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInventoryServicings(params.id));
  }, [dispatch, params.id]);
  const servicingReducer = useSelector((store) => store.servicingReducer);
  const isDisposed = useSelector(
    (store) => store.inventoryReducer.inventory.isDisposed
  );
  const { inventoryServicings, isLoading } = servicingReducer;
  const currentServicing = inventoryServicings.find(
    (is) => is.isCurrentServicing === true
  );
  var remainingDays =
    currentServicing &&
    Math.round(
      (new Date(currentServicing.serviceAt) - new Date()) /
        (1000 * 60 * 60 * 24)
    );
  if (remainingDays < 0) {
    remainingDays = 0;
  }
  if (isLoading || !inventoryServicings.length) return <Spinner />;
  return (
    <>
      {!isDisposed && (
        <Alert variant="info">
          <Alert.Heading className="h5">Servicings Overview</Alert.Heading>
          <hr />
          <div className="d-flex align-items-center">
            <div>
              <h6>Next servicing date : </h6>
              <span className="text-capitalize">
                {currentServicing && currentServicing.serviceAt}
              </span>
            </div>
            <div className="ms-5">
              <h6>Remaining Days:</h6>
              <span>{remainingDays}</span>
            </div>
            <div className="ms-5">
              <h6>Duration:</h6>
              <span>
                {currentServicing
                  ? `${currentServicing.durationInMonth} months`
                  : null}
              </span>
            </div>
          </div>
        </Alert>
      )}
      <ServicingTable
        servicingReducer={servicingReducer}
        isDisposed={isDisposed}
        currentServicing={currentServicing}
      />
    </>
  );
};

export default ServicingDetails;
