import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AllocationTable from "./AllocationsTable";
import { getInventoryAllocations } from "../Redux/actions/allocationActions";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

const AllocationDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const allocationReducer = useSelector((store) => store.allocationReducer);
  const isDisposed = useSelector(
    (store) => store.inventoryReducer.inventory.isDisposed
  );
  React.useEffect(() => {
    dispatch(getInventoryAllocations(params.id));
  }, [dispatch, params.id]);
  const currentAllocation =
    allocationReducer.inventoryAllocations &&
    allocationReducer.inventoryAllocations.find((all) => {
      return all.isCurrentAllocation === true;
    });

  return (
    <>
      {currentAllocation ? (
          <Alert variant="info">
            <Alert.Heading className="h5">Allocations Overview</Alert.Heading>
            <hr />
            <div className="d-flex align-items-center">
              <div>
                <h6>Current Owner: </h6>
                <span className="text-capitalize">{`${currentAllocation.user.firstName} ${currentAllocation.user.lastName} (${currentAllocation.user.employeeId})`}</span>
              </div>
              <div className="ms-5">
                <h6>Allocated At: </h6>
                <span>{currentAllocation.allocatedAt}</span>
              </div>
            </div>
            <span></span>
            <span></span>
          </Alert>
      ) : (
        <Alert variant="warning">
          <Alert.Heading className="h5">
            This inventory is not allocated at the moment
          </Alert.Heading>
        </Alert>
      )}
      <AllocationTable
        allocationReducer={allocationReducer}
        isDisposed={isDisposed}
      />
    </>
  );
};

export default AllocationDetails;
