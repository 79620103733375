import React from "react"
import { Button, Checkbox, FormControlLabel } from "@material-ui/core"
import { Modal } from "@material-ui/core"
import { Box } from "@material-ui/core"
import axios from "../../utils/axios"
import { Card } from "@material-ui/core"
import { notify } from "../../utils/toast"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(() => ({
	label: {
		fontSize: "15px",
	},
}))

export default function AddLeaveTypeModal({setLeaveTypes}) {
	const [open, setOpen] = React.useState(false)
	const [leaveType, setLeaveType] = React.useState({
		leave_type_name: "",
		leave_type_total_days: null,
		is_payable: false,
		is_yearly_renewed: false,
	})
	const classes = useStyles()
	const handleOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "400",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
	}

	const handleChange = (e) => {
		setLeaveType({ ...leaveType, [e.target.name]: e.target.value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setOpen(false)
		console.log(leaveType)
		axios
			.post("api/add/leaveType", leaveType)
			.then((res) => {
				notify("success", "Successfully Added")
				setLeaveTypes(res.data)
			})
			.catch((err) => {notify('error','Something went wrong !')})
	}

	return (
		<>
			<Button
				variant="outlined"
				color="primary"
				style={{ float: "right", marginBottom: "1%" }}
				onClick={handleOpen}
			>
				Add Leave Type
			</Button>
			<Modal open={open} onClose={handleClose}>
				<Box sx={style}>
					<Card style={{ backgroundColor: "#ff6600", height: 40 }}>
						<h5
							style={{ color: "white", marginTop: "2%", textAlign: "center" }}
						>
							Add Leave Types
						</h5>
					</Card>
					<br />

					<form onSubmit={handleSubmit}>
						<div className="row">
							<label htmlFor="leave_type_name">Type Name</label>
							<input
								type="text"
								name="leave_type_name"
								onChange={handleChange}
								value={leaveType.leave_type_name}
								required
							/>
						</div>
						<br />
						<div className="row">
							<label htmlFor="leave_type_total_days">Total Days</label>
							<input
								type="number"
								name="leave_type_total_days"
								onChange={handleChange}
								value={leaveType.leave_type_total_days}
								required
							/>
						</div>
						<br />
						<div className="row">
							<div className="col-5">
								<FormControlLabel
									classes={{ label: classes.label }}
									control={
										<Checkbox
											onChange={() =>
												setLeaveType({
													...leaveType,
													is_payable: !leaveType.is_payable,
												})
											}
										/>
									}
									label="Is Payable"
								/>
							</div>
							<div className="col">
								<FormControlLabel
									classes={{ label: classes.label }}
									control={
										<Checkbox
											onChange={() =>
												setLeaveType({
													...leaveType,
													is_yearly_renewed: !leaveType.is_yearly_renewed,
												})
											}
										/>
									}
									label="Is Yearly Renewed"
								/>
							</div>
						</div>
						<br />
						<Button
							variant="outlined"
							type="submit"
							color="primary"
							style={{
								marginTop: "10%",
								marginLeft: "50%",
								width: "50%",
							}}
						>
							Add
						</Button>
					</form>
				</Box>
			</Modal>
		</>
	)
}
