import axios from "../../utils/axios"
import { notify } from "../../utils/toast"

//Login User
export const loginUser = (userCredentials, setIsLoading, resolve, reject) => async (dispatch) => {
	const body = JSON.stringify(userCredentials)
	try {
		const res = await axios.post("/api/login", body)
		dispatch({
			type: "LOGIN_SUCCESS",
			payload: res.data,
		})
		if (res.status === 200) {
			notify("success", "logged in!")
		} else {
			notify("error", "login failed!")
		}
		resolve("login success")
	} catch (error) {
		dispatch({
			type: "LOGIN_FAILURE",
			payload: error.response && error.response.data.message,
		})
		reject("login rejected")
	}
	setIsLoading(false);
}

export const oAuthLogin = (access_token, history) => async (dispatch) => {
	try {
		const res = await axios.post("/api/oauth", access_token)
		dispatch({
			type: "LOGIN_SUCCESS",
			payload: res.data,
		})
		if (res.status === 200) {
			if(res.data.error)
			{
				notify("error", res.data.error, 4000);
				history.push("/login");
				return;
			}
			notify("success", "logged in!")
		}
	} catch (error) {
		notify("error", "Login Failed!")
		history.push("/login")
		dispatch({
			type: "LOGIN_FAILURE",
			payload: error.response && error.response.data.message,
		})
	}
}

export const fetchCurrentUser = (resolve,reject) => async (dispatch) => {
	try {
		const userResponse = await axios.get("/api/me")
		const user = userResponse.data.user
		const supervisors = userResponse.data.supervisors
		const permissions = userResponse.data.permissions
		dispatch({
			type: "USER_LOADED",
			payload: user,
		})
		dispatch({
			type: "USER_SUPERVISORS_LOADED",
			payload: supervisors,
		})
		dispatch({
			type: "USER_PERMISSIONS_LOADED",
			payload: permissions,
		})
		resolve("Success")
	}
	 catch (error) {
		dispatch({
			type: "AUTH_ERROR",
		})
		reject("Rejected")
	}
}

export const logoutUser = () => async (dispatch) => {
	try {
		//clear inventories, requests
		dispatch({
			type: "LOGOUT_SUCCESS",
		})
		notify("error", "logged out!")
	} catch (error) {
		dispatch({
			type: "LOGOUT_FAILURE",
		})
	}
}
