import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import axios from "../../utils/axios";
import AddCategoryAccordion from "./AddCategoryAccordion";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SelectInput from "../../Common/Form/SelectInput/SelectInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { notify } from "../../utils/toast";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ButtonLoader from '../../ButtonLoader/ButtonLoader'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 0 8px #ff6600",
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AddDocumentModal() {
  const [loading, setLoading] = useState(false);
  const fileRef = useRef();
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    let isMounted = true;
    axios
      .get("/api/users/documents/categories")
      .then((res) => {
        if(isMounted){
          setCategories(res.data.result);
        }
      })
      .catch((err) => console.log(err));
    return(
      isMounted=false
    )
  }, []);

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        {" "}
        Add Documents{" "}
        <AddPhotoAlternateIcon style={{ fontSize: "1.2rem", margin: "5px" }} />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <AddCategoryAccordion
              categories={categories}
              setCategories={setCategories}
            />
            <hr />
            <h2
              id="transition-modal-title"
              style={{ color: "#ff6600", margin: "20px 0" }}
            >
              Add Document
            </h2>
            <Formik
              initialValues={{ category: "" }}
              validationSchema={Yup.object({
                category: Yup.string()
                  .oneOf(
                    categories.map((cat) => cat.category),
                    "Invalid Document Type"
                  )
                  .required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setLoading(true);
                setTimeout(() => {
                  const file = fileRef.current.files[0];
                  const formData = new FormData();
                  formData.append("file", file);
                  formData.append("category", values.category);
                  axios
                    .post(`/api/users/${params.id}/documents`, formData)
                    .then((res) => {
                      setLoading(false);
                      dispatch({
                        type: "UPDATE_USER_SUCCESS",
                        payload: res.data.result,
                      });
                      handleClose();
                      notify("success", "documents updated!");
                    })
                    .catch((err) => {
                      setLoading(false);
                      notify('error','Something went wrong !')
                    });
                  setSubmitting(false);
                }, 400);
              }}
            >
              <Form>
                <SelectInput label="Category" name="category">
                  <option value="">Select a document type</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.category}>
                      {cat.category}
                    </option>
                  ))}
                </SelectInput>
                <div>
                  <label className="text-muted">
                    <span className="text-bold">Note: </span>Existing document
                    will be replaced
                  </label>
                </div>
                <input
                  type="file"
                  ref={fileRef}
                  className="custom-file-input my-4"
                />
                <br />
                <Button type="submit" variant="outlined" color="primary">
                  {
                    loading ? <ButtonLoader color="orange"/>:"Submit"
                  }
                </Button>
              </Form>
            </Formik>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
