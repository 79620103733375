import axios from "axios";
const instance = axios.create({
	// baseURL: "https://127.0.0.1:8000/",
	baseURL: process.env.REACT_APP_API_URL
});
instance.defaults.headers.post["Content-Type"] = "application/json";

instance.interceptors.request.use(
	function (config) {
		// check if token is present
		const token = localStorage.getItem("token");
		if (!!token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	function (config) {
		// check if token is present
		const token = localStorage.getItem("token");
		if (!!token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// const token = localStorage.getItem('token');

// if(token){
//   instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// }

export default instance;
