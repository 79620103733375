import React from "react"
import { Button, CircularProgress } from "@material-ui/core"
import { Modal } from "@material-ui/core"
import { Box } from "@material-ui/core"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import axios from "../utils/axios"
import { notify } from "../utils/toast"
import TipTap from "../TipTap/TipTap"

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "400",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
}

export default function WorkSubmitModel({
	id,
	type,
	setIsLoaded,
	isLoaded,
	setOpen,
}) {
	const [opens, setOpens] = React.useState(false)
	const [remark, setRemark] = React.useState()
	const [process, setProcess] = React.useState(false)

	const handleOpen = () => {
		setOpens(true)
	}
	const handleClose = () => {
		setOpens(false)
	}

	const handleRequest=()=>{
		if (remark && remark.length!==0) {
			setProcess(true)
			if(type === "cancel"){
				axios
					.put(
						`api/workCancel/${id}`,
						remark
					)
					.then((response) => {
						setOpen(false)
						setProcess(false)
						setIsLoaded(!isLoaded)
						notify("success","Successfully cancelled extra work request")
					})
					.catch((error) => console.log(error))
			}else if(type === "done"){
				axios
					.put(
						`api/workSubmit/${id}`,
						remark
					)
					.then((response) => {
						setOpen(false)
						setProcess(false)
						setIsLoaded(!isLoaded)
						notify("success","Successfully submitted work for review")
						let mails = response.data;
						mails = mails.map((e)=> {
							let data = {
								type : "WorkSubmit"
							};
							let req = axios.post("api/mail/send/"+e,data)
							return req;
						})
						
						setIsLoaded(!isLoaded)
			
						// for mail
						Promise
						.all(mails)
						.then((responses) => {
							notify("success","Emails sent succesfully")
						})
						.catch(errors => {
							notify("error","could not send mail due to some errors.")
						})
					})
					.catch((error) => console.log(error))
			}

		} else {
			notify("error", "Message cannot be empty")
		}
		
	}


	return (
		<>
			<Button
				style={{
					color: "white",
					marginLeft:"15px",
					backgroundColor:
						(type==="cancel" && "red") || 
						(type==="done" && "green"), 
				}}
				variant="contained"
				onClick={handleOpen}
			>
				{type === "cancel" ? "Cancel Extra Work Request" : "Extra Work Done and Send for Review"}
			</Button>
			<Modal open={opens} onClose={handleClose}>
				<Box sx={style}>
					<h5 style={{ color: (type==="cancel" && "red") || (type==="done" && "green"), marginBottom: "10%" }}>
					{type === "cancel" ? "Cancel Message" : "Description of Work Done"}
					</h5>
					{/* <TextareaAutosize
						className="form-control"
						aria-label="minimum height"
						minRows={3}
						style={{ width: 300 }}
						name="remark"
						value={remark}
						onChange={(ev)=>{
							setRemark(ev.target.value)
						}}
					/> */}
					<TipTap content={remark} setContent={setRemark} editable={true} />
					<br />
					<Button
						variant="outlined"
						style={{
							color: (type==="cancel" && "red") || (type==="done" && "green"),
							borderColor: (type==="cancel" && "red") || (type==="done" && "green"),
							marginTop: "5%",
							width: "50%",
						}}
						onClick={() => handleRequest(id)}
					>
						{process? <CircularProgress/> :type === "cancel" ? "Cancel Extra Work" : "Send for Review"}
					</Button>
				</Box>
			</Modal>
		</>
	)
}
