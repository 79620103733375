import React, { useEffect, useState } from "react";
import "./userprofile.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../Redux/actions/userActions";
import { Link, useHistory, useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import ProfileTabs from "../tab/ProfileTabs";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FileUploader from "../../FileUploader/FileUploader";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import axios from "../../utils/axios"
import { notify } from "../../utils/toast";
import getUserInfoFromToken from '../../utils/token_expiry';
import moment from "moment"

const UserProfile = () => {
  const [imagePath, setImagePath] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    role,
    userReducer: { user, isLoading },
  } = useSelector((store) => ({
    role: store.authReducer.user.roles[0],
    userReducer: store.userReducer,
  }));
  const permissions = useSelector((store)=>store.authReducer.permissions);

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchUser(id));
  }, [id]);

  useEffect(() => {
    setImagePath(user?.userInfo.ppImagePath);
  }, [user]);

  const avatarImageUrl =
    user && user.gender === "female" ? "/femaleAvatar.png" : "/maleAvatar.png";

  if (isLoading || !user) return <Spinner />;

  return (
    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
      {user.hasLeft && (
        <div className="container mb-5">
          <h3 className="text-danger text-center">
            This user has left the company
          </h3>
          <h5 className="text-center">You can see profile here</h5>
        </div>
      )}
      <div>
        <Button
          color="secondary"
          variant="outlined"
          style={{ fontSize: "13px" ,marginLeft: "5%"}}
          onClick={()=> {
            history.goBack();
          }}
          
        >
          <ArrowBackIosIcon style={{fontSize: "12px"}}/>
          <span>Back</span>
        </Button>
      </div>
      <h3 className="text-center mb-4" style={{ color: "#ff6600" }}>
        Users profile
      </h3>
      <div className="row text-md-start text-center">
        <div className="col-md-5">
          <div className="profile-img">
            <img
              src={
                imagePath
                  ? process.env.REACT_APP_API_URL + imagePath
                  : avatarImageUrl
              }
              style={{ height: "200px", width: "250px", objectFit: "contain" }}
              alt="profile"
              className="profile-img my-2"
            />
            {!user.hasLeft && (
              <FileUploader type="ppImage" setImagePath={setImagePath} />
            )}
          </div>
        </div>
        <div className="col-md-5" style={{ paddingTop: "50px" }}>
          <div className="profile-head">
            <h4 className="display-7">
              {user.gender === "male" ? "Mr. " : "Mrs. "}
              {user.firstName + " " + user.lastName}
            </h4>
            <h5>{user.designation}</h5>
            <p className="proile-rating">
              Employee id :{" "}
              <span style={{ color: "#ff6600", fontSize: "18px" }}>
                {user.employeeId}
              </span>
            </p>
          </div>
        </div>
        {user &&
          (user.roles[0] !== "ROLE_SUPER_ADMIN" ) &&  
          !user.hasLeft && (
            <div className="col-md-2">
              {
                permissions?.includes("edit_user") &&
                <Link to={"/dashboard/users/edit/" + user.employeeId}>
                  <button className="profile-edit-btn mt-3">
                    Edit Profile
                    <EditIcon
                      color="secondary"
                      style={{ fontSize: "1.1rem", margin: "4px" }}
                    />
                  </button>
                </Link>
              }
              {
                permissions?.includes("add_past_leave") &&
                  <Link to={"/dashboard/users/add/pastLeave/"+user.employeeId}>
                    <button className="profile-edit-btn mt-3">
                      Add Past Leaves 
                      <AddIcon/>
                    </button>
                  </Link>
              }
              {
                (permissions?.includes("login_as_user")) &&
                <Link to={"#"} style={{textDecoration: "none"}}>
                  <button className="profile-edit-btn my-3" onClick= {() => {
                    axios.get('/api/sa/loginas/'+user.employeeId)
                    .then()
                    .then((res)=>{
                      const usr_token = res.data.result;
                      const sa_token = localStorage.getItem('token');
                      const sa_token_expires_at = localStorage.getItem('token_expires_at');
                      localStorage.setItem('sa_token',sa_token);
                      localStorage.setItem('token',usr_token);
                      localStorage.setItem('sa_token_expires_at',sa_token_expires_at);
                      console.log("sa_exp_token_at",sa_token_expires_at);
                      localStorage.setItem('token_expires_at', moment.utc((getUserInfoFromToken(usr_token).exp)*1000).format());
                      history.replace("/dashboard");
                      window.location.reload();
                      notify('success', "Logged in as "+user.firstName);
                    })
                    .catch((error)=>{
                      console.log(error);
                    })
                  }} >
                    Login as user
                    <ExitToAppIcon
                      color="secondary"
                      style={{ fontSize: "1.1rem", margin: "4px" }}
                    />
                  </button>
                </Link>
              }
            </div>
          )}
          
      </div>
      <div className="container mt-3 d-flex justify-content-center">
        <div className="w-100 tab-content profile-tab">
          <ProfileTabs user={user} role={role}/>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
