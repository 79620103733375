import React, { useState } from "react"
import { PasswordInputWrapper } from "./PasswordInput.style"

const PasswordInput = ({ value, handleChange, ...otherProps }) => {
	const [showPassword, setShowPassword] = useState(false)
	function toggleShowPassword(e) {
		setShowPassword(!showPassword)
	}
	return (
		<PasswordInputWrapper showPassword={showPassword}>
			<label>Password</label>
			<div className="input-wrapper">
				<input
					type={showPassword ? "text" : "password"}
					className="form-control"
					placeholder="Enter Password"
					name="password"
					onChange={handleChange}
					minLength={6}
					{...otherProps}
				/>
				<div id="toggler" onClick={toggleShowPassword}>
					{showPassword ? (
						<i className="fas fa-eye-slash icon"></i>
					) : (
						<i className="fas fa-eye icon"></i>
					)}
				</div>
			</div>
		</PasswordInputWrapper>
	)
}

export default PasswordInput
