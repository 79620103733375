import {
	TableContainer,
	Paper,
	TableCell,
	makeStyles,
	Table,
	TableHead,
	TableRow,
	TableBody,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import React from "react"
import SwitchDayHour from "../SwitchDayHour/SwitchDayHour"
import axios from "../utils/axios"
import CollapsiableUSLRTable from "./CollapsiableUSLRTable"
import { notify } from "../utils/toast"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	table: {
		minWidth: 700,
		padding: "10px",
	},
	tableContainer: {
		width: "90%",
		margin: "auto",
	},
})

export default function UserSideLeaveRequest() {
	const classes = useStyles()
	const [leaveDetails, setLeaveDetails] = React.useState()
	const [showHour, setShowHour] = React.useState(false)
	const [isLoading,setIsLoading] = React.useState(false)

	React.useEffect(() => {
		axios
			.get(`api/pending/leaveRequest/userProfile`)
			.then((res) => {
				setLeaveDetails(res.data)
			})
			.catch((err) => 
				notify('error','Something went wrong !')
			)
	}, [isLoading])

	return (
		<div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
			<TableContainer className={classes.tableContainer} component={Paper}>
				<h4
					style={{ color: "#ff6600", marginTop: "0.7%", textAlign: "center" }}
				>
					Leave Requests
				</h4>
				{/* <br /> */}
				<div className="row">
					<div className="col-10"></div>
					<div
						className="col"
						style={{
							marginBottom: "1%",
							marginLeft: "4%",
						}}
					>
						<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
					</div>
				</div>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>Employee Name</StyledTableCell>
							<StyledTableCell>Leave Type</StyledTableCell>

							<StyledTableCell>From</StyledTableCell>
							<StyledTableCell>Leave Time</StyledTableCell>
							<StyledTableCell>To</StyledTableCell>
							<StyledTableCell>Arrival Time</StyledTableCell>

							<StyledTableCell>Duration</StyledTableCell>
							<StyledTableCell>Supervisor</StyledTableCell>
							<StyledTableCell>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{leaveDetails &&
							leaveDetails.map((ld) => (
								<CollapsiableUSLRTable
									isLoading={isLoading}
									setIsLoading={setIsLoading}
									ld={ld}
									showHour={showHour}
									setLeaveDetails={setLeaveDetails}
								/>
							))}
						{leaveDetails && leaveDetails.length === 0 && (
							<TableRow>
								<StyledTableCell colSpan={10}>
									<p style={{ textAlign: "center" }}> No leave Requests yet!</p>
								</StyledTableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
