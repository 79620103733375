import React from "react";

const ButtonLoader = ({ color }) => {
  return (
    <div className="spinner-border" role="status" style={{ color: color === "white" ? "#ffffff": "#ff6600" }}>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default ButtonLoader;
