import React from "react"
import {
	Table,
	TableCell,
	TableHead,
	TableBody,
	TableContainer,
	TableRow,
	IconButton,
} from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/styles"
import AddIcon from "@material-ui/icons/Add"
import Card from "@material-ui/core/Card"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import ClearIcon from "@material-ui/icons/Clear"
import axios from "../utils/axios"
import PublicHolidaysEdit from "./PublicHolidaysEdit"
import { notify } from "../utils/toast"
import { useSelector } from "react-redux"

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
	body: {
		textAlign: "center",
	},
}))(TableCell)

const useStyles = makeStyles({
	tableContainer: {
		height: 400,
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		overflow: "scroll",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none"
	},
})
export default function PublicHolidays({ role }) {
	const classes = useStyles()
	const scrollBottom = React.useRef(null)
	const [showAddInput, setShowAddInput] = React.useState(false)
	const permissions = useSelector((store)=>store.authReducer.permissions);
	
	const [holidayDetails, setHolidayDetails] = React.useState({
		holidayDate: "",
		holidayOccassion: "",
	})

	const [publicHoliday, setPublicHoliday] = React.useState()
	React.useEffect(() => {
		let isMounted=true;
		axios
			.get("api/get/publicHoliday")
			.then((res) => {
				if(isMounted){
					setPublicHoliday(res.data)
				}	
			})
			.catch((err) => console.log(err))
			scrollBottom.current?.scrollIntoView({ behavior: "smooth" })
			return ()=>{
				isMounted=false;
			}
	}, [showAddInput])

	const handleClickOpen = () => {
		setShowAddInput(true)
	}
	const handleChange = (e) => {
		setHolidayDetails({ ...holidayDetails, [e.target.name]: e.target.value })
	}
	const handleSubmitAdd = (e) => {
		e.preventDefault()

		axios
			.post("api/add/publicHoliday", holidayDetails)
			.then((res) => {
				setPublicHoliday(res.data)
				setShowAddInput(false)
			})
			.catch((err) => {
				notify('error',err.response.data);
			})
	}

	return (
		<div>
			<div
				className="row"
			>
				<div className="d-flex justify-content-between">
					<h5 style={{ color: "#ff6600" }}>Public Holidays</h5>
					{
						(permissions?.includes("add_holiday")) &&
						<IconButton style={{ float: "right" }} onClick={handleClickOpen}>
							<AddIcon color="primary" size="large" />
						</IconButton>
					}
				</div>
			</div>

			<TableContainer className={classes.tableContainer} component={Card}>
				<Table className={classes.table} aria-label="customized table" stickyHeader={true}>
					<TableHead>
						<TableRow>
							<StyledTableCell>Day</StyledTableCell>
							<StyledTableCell>Date</StyledTableCell>
							<StyledTableCell>Occassion</StyledTableCell>
							{(permissions?.includes("edit_holiday") || permissions?.includes("delete_holiday")) && <StyledTableCell>Action</StyledTableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{!!publicHoliday &&
							publicHoliday.map(
								(hd,key) =>
									!parseInt(hd.is_deleted) && (
										<PublicHolidaysEdit key={key}
											hd={hd}
											setHolidayDetails={setHolidayDetails}
											setPublicHoliday={setPublicHoliday}
											role={role}
										/>
									)
							)}
						{ (permissions?.includes("edit_holiday")) && showAddInput && (
							<TableRow>
								<TableCell colSpan={4} ref={scrollBottom}>
									<form onSubmit={handleSubmitAdd} style={{ marginLeft: "8%" }}>
										<input
											type="date"
											name="holidayDate"
											style={{ marginRight: "3%" }}
											value={holidayDetails.holidayDate}
											onChange={handleChange}
											required
										/>

										<input
											type="text"
											name="holidayOccassion"
											placeholder="enter holiday occassion"
											style={{ width: "30%", marginRight: "11%" }}
											value={holidayDetails.holidayOccassion}
											onChange={handleChange}
											required
										/>

										<IconButton type="submit">
											<DoneOutlineIcon color="primary" />
										</IconButton>
										<IconButton onClick={() => setShowAddInput(false)}>
											<ClearIcon color="primary" />
										</IconButton>
									</form>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
