import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { unallocation } from "../Redux/actions/allocationActions";
import { useDispatch } from "react-redux";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import CancelIcon from "@material-ui/icons/Cancel";
import { red } from '@material-ui/core/colors';

const UnAllocate = ({ id }) => {
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  function handleUnallocation() {
    dispatch(unallocation(id));
  }
  return (
    <div className="d-flex justify-content-center align-item-center">
      <ConfirmModal
        content="Are you sure you want to unallocate item ?"
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={handleUnallocation}
      />
      <Button
        style={{color:red[500], border: "1px solid red"}}
        variant="outlined"
        className="m-2"
        onClick={() => setShowConfirmation(true)}
      >
        Unallocate
        <CancelIcon style={{fontSize:"17px", color: red[500]}}/>
      </Button>
    </div>
  );
};

export default UnAllocate;
