import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Store, People, Note } from "@material-ui/icons";
import { createAllocation } from "../Redux/actions/allocationActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../Redux/actions/userActions";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import UserPreview from "./UserPreview";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
}));
export default function AllocationModal() {
  const [loading, setLoading] = useState(false);
  //modal states
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  //modal functions
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const users = useSelector((store) =>
    store.userReducer.users.filter((user) => !user.hasLeft)
  );
  const inventoryReducer = useSelector((store) => store.inventoryReducer);
  const { inventory } = inventoryReducer;
  const body = (
    <div className={classes.paper}>
      <>
        <div className="main container bg-light">
          <div className="card-header text-center" style={{ color: "#ff6600" }}>
            <Store /> Allocate inventory
          </div>
          <Formik
            initialValues={{ employeeId: "", notes: "", allocatedAt: "" }}
            validationSchema={Yup.object({
              employeeId: Yup.string().required("Required"),
              notes: Yup.string()
                .min(5, "Must be 5 characters or more")
                .required("Required"),
              allocatedAt: Yup.date().required("Required").max(new Date(), "Date cannot be future").min(moment(inventory.procurredAt).format('YYYY-MM-DD'),"Allocation date cannot be before procurement date:"+moment(inventory.procurredAt).format('YYYY-MM-DD')),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              values["inventoryId"] = params.id;
              setLoading(true);
              dispatch(createAllocation(values, setLoading));
              setOpen(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="my-2 d-block">
                  <div>
                    <label>
                      <People />
                      Allocate To:
                    </label>
                  </div>
                  <select
                    className="form-control custom-select"
                    name="employeeId"
                    value={values.employeeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {users.length && (
                      <>
                        <option value="">Choose employee...</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.employeeId}>
                            {user.firstName} {user.lastName}({user.employeeId})
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  <UserPreview users={users} employeeId={values.employeeId} />
                  <p className="text-danger">
                    {errors.employeeId &&
                      touched.employeeId &&
                      errors.employeeId}
                  </p>
                </div>
                <div className="form-group my-3">
                  <label>Allocated At</label>
                  <input
                    type="date"
                    className="form-control"
                    rows="3"
                    name="allocatedAt"
                    value={values.allocatedAt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // min={moment(inventory.procurredAt).format('YYYY-MM-DD')}
                  ></input>
                  <p className="text-danger">
                    {errors.allocatedAt &&
                      touched.allocatedAt &&
                      errors.allocatedAt}
                  </p>
                </div>
                <div className="form-group my-3">
                  <label>
                    <Note />
                    Notes
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  <p className="text-danger">
                    {errors.notes && touched.notes && errors.notes}
                  </p>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {
                      loading ? <ButtonLoader color="orange"/> : "Submit"
                    }
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="m-2"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </>
    </div>
  );

  return (
    <div>
      <Button
        color="primary"
        variant="outlined"
        onClick={handleOpen}
        className="m-2"
      >
        Allocate inventory
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {body}
      </Modal>
    </div>
  );
}
