import React from "react"
import {
	TableCell,
	TableRow,
	Chip,
	IconButton,
	Collapse,
	Tooltip,
	withStyles,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import AdminCollapsePanel from "../AdminCollapsePanel/AdminCollapsePanel"
import ReplayIcon from "@material-ui/icons/Replay"
import axios from "../../../utils/axios"
import ApproverAvatar from "../../../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../../../utils/dateTimeFormat"
import EditIcon from "@material-ui/icons/Edit";
import { notify } from "../../../utils/toast"
import SettlementChip from "../../../Common/SettlementChip/SettlementChip"

const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapseHistoryTable({
	data,
	setAllLeaveData,
	setCount,
	setLoggedInId,
	page,
	rowsPerPage,
	findBy,
	showHour,
}) {
	const [open, setOpen] = React.useState(false)

	React.useEffect(() => {
		setOpen(false)
	}, [page])

	// const DOL = new Date(data.date_of_leave)
	// const DOA = new Date(data.date_of_arrival)

	const handleUndoClick = (id) => {
		axios
			.put(`api/undo/leaveStatus/${id}`)
			.then((res) => {
				return axios.get(
					`api/allLeave/noPendingRequests?page=${page}&rowsPerPage=${rowsPerPage}&findBy=${JSON.stringify(
						findBy
					)}`
				)
			})
			.then((res) => {
				setAllLeaveData(res.data.result)
				setCount(res.data.count)
				setLoggedInId(res.data.useId)
			})
			.catch((err) => notify('error',err.response.data))
	}

	return (
		<>
			<TableRow key={data.id}>
				<StyledTableCell>
					<IconButton onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>
					{data.first_name + " " + data.last_name}
				</StyledTableCell>
				<StyledTableCell>
					{data.leave_type_name}
					<br />
					{data.leave_type_name ==="Unpaid Leave" && data.name === "Approved" && data.settled_with_cl > 0 && (<SettlementChip settleDays={data.settled_with_cl?data.settled_with_cl:0}/>)}
				</StyledTableCell>
				<StyledTableCell>{dateFormat(data.date_of_leave)}</StyledTableCell>
				<StyledTableCell>{data.leave_time}</StyledTableCell>
				<StyledTableCell>{dateFormat(data.date_of_arrival)}</StyledTableCell>
				<StyledTableCell>{data.arrival_time}</StyledTableCell>
				<StyledTableCell>
					{showHour === false ? data.durationDay : data.durationHour}
				</StyledTableCell>
				{data.name === "Approved" && (
					<StyledTableCell>
						<Chip
							label={data.name}
							style={{ color: "white", backgroundColor: "green" }}
						/>
					</StyledTableCell>
				)}
				{data.name === "Rejected" && (
					<StyledTableCell>
						<Chip
							label={data.name}
							style={{ color: "white", backgroundColor: "red" }}
						/>
					</StyledTableCell>
				)}
				<StyledTableCell>
					{data.approvers.map((ad,key) => (
						<ApproverAvatar ad={ad} key={key} declineMessage={data.decline_message} />
					))}
					{data.adminApprover && <ApproverAvatar ad={data.adminApprover} />}
				</StyledTableCell>

				<StyledTableCell>
					<Link to={`/dashboard/leaveTab/edit/${data.id}`}>
						<IconButton>
							<Tooltip title="Edit request">
								{/* <BorderColorIcon style={{ color: "#ff6600" }} /> */}
								<EditIcon style={{ color: "#ff6600"}} />
							</Tooltip>
						</IconButton>
					</Link>
					<IconButton onClick={() => handleUndoClick(data.id)}>
						<Tooltip title="Undo request">
							<ReplayIcon style={{ color: "#ff6600" }} />
						</Tooltip>
					</IconButton>
				</StyledTableCell>
			</TableRow>
			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={12}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<AdminCollapsePanel
							status={data.name}
							leaveDescription={data.leave_description}
							declineMessage={data.decline_message}
							leaveDoc={data.leave_documents}
							requestId={data.id}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
