import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { oAuthLogin } from "../Redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import Redirector from "../Redirector/Redirector";
import Spinner from "../Spinner/Spinner";

const Oauth = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const history = useHistory();
  useEffect(() => {
    const err = location.search.includes("error");
    if (err) {
      history.push("/login");
    }
    let params = new URLSearchParams(location.hash);
    params = [...params.entries()];
    let access_token = params.find((p) => p.includes("#access_token"));
    if (access_token) {
      access_token = access_token[1];
      dispatch(oAuthLogin(access_token, history));
    }
    // eslint-disable-next-line
  }, []);
  if (isAuthenticated) return <Redirector />;
  return <Spinner />;
};

export default Oauth;
