import React, {  useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./infostyles.css";
import InfoLabel from "./InfoLabel";
import { generateUserPayload } from "../../utils/payloadGenerator";
import { Button, Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
const Basics = ({ user }) => {
  const [editMode, setEditMode] = useState(false);
  // const [supervisors,setSupervisors] = useState([]);
	const supervisors = useSelector((store) => store.userReducer.supervisors)
  let userInfo = generateUserPayload(user);
  const textFields = [
    "firstname",
    "lastname",
    "email",
    "designation",
    "department",
  ];
  let dateFields;
  if(userInfo?.designation.toLowerCase() === "intern"){
    dateFields = ["dateofbirth", "joinedat", "probationendat"];
  } else {
    dateFields = ["dateofbirth", "joinedat"];
  }
  const editableFields = [
    "firstname",
    "lastname",
    "dateofbirth"
  ];

  return (
    <div className="mb-3">
      {!editMode && (
        <Button
          variant="contained"
          className='mr-3 mb-3'
          onClick={() => {
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      )}
      {editMode && (
        <Button
          variant="contained"
          className='mr-3 mb-3'
          onClick={() => {
            setEditMode(false);
          }}
        >
          Done
        </Button>
      )}
      
	  <h5 className="mt-1">Personal details</h5>
    	<hr />
      <Row>
        <Col md={6}>
          {/* text fields */}
          {Object.keys(generateUserPayload(user))
            .filter((i) => textFields.includes(i.toLowerCase()))
            .map((k) => (
              <InfoLabel
                key={k}
                label={k}
                value={generateUserPayload(user)[k]}
                user={generateUserPayload(user)}
                editMode={editMode}
                setEditMode={setEditMode}
                editableFields={editableFields}
                inputType="text"
              />
            ))}
        </Col>
        <Col md={6}>
          {/* date fields */}
          {Object.keys(userInfo)
            .filter((i) => dateFields.includes(i.toLowerCase()))
            .map((k) => (
              <InfoLabel
                key={k}
                label={k}
                value={userInfo[k]}
                user={userInfo}
                editMode={editMode}
                setEditMode={setEditMode}
                editableFields={editableFields}
                inputType="date"
              />
            ))}
        </Col>
      </Row>
    <h5 className="mt-1">Supervisors</h5>
      <hr />
      <div className="d-flex gap-3 justify-content-start ">
        {
          supervisors?.map((element,key)=>(
            <Chip label={element.first_name + " " + element.last_name} style={{
              color: "#ffffff",
              backgroundColor: "#ff6600"
            }}/>
          ))
        }
      </div>
    </div>
  );
};

export default Basics;

