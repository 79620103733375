import React from "react"
import { withStyles } from "@material-ui/styles"
import { IconButton } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import { TableCell, TableRow } from "@material-ui/core"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import ClearIcon from "@material-ui/icons/Clear"
import { dateFormat, getDayFromDate } from "../utils/dateTimeFormat"
import EditIcon from "@material-ui/icons/Edit"
import axios from "../utils/axios"
import { notify } from "../utils/toast"
import { useSelector } from "react-redux"

const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function PublicHolidaysEdit({ hd, setPublicHoliday, role }) {
	const permissions = useSelector((store)=>store.authReducer.permissions)
	const [holidayDetails, setHolidayDetails] = React.useState({
		holidayDate: "",
		holidayOccassion: "",
	})
	const handleChange = (e) => {
		setHolidayDetails({ ...holidayDetails, [e.target.name]: e.target.value })
	}
	const [showEditInput, setShowEditInput] = React.useState(false)
	const handleClickEdit = (id, occassion, date) => {
		setShowEditInput(true)
		setHolidayDetails({ holidayDate: date, holidayOccassion: occassion })
	}
	const handleSubmitEdit = (event, id) => {
		event.preventDefault()
		console.log(id)
		axios
			.put(`api/edit/publicHoliday/${id}`, holidayDetails)
			.then((res) => {
				setPublicHoliday(res.data)
				setShowEditInput(false)
			})
			.catch((err) => {
				notify('error',err.response.data);
				setShowEditInput(false)
			})
	}
	const handleDelete = (id) => {
		axios
			.put(`api/delete/publicHoldiay/${id}`)
			.then((res) => {
				setPublicHoliday(res.data)
			})
			.catch((err) => {
				notify('error',err.response.data);
			})
	}
	return (
		<TableRow>
			{showEditInput === false ? (
				<>
					<StyledTableCell>{getDayFromDate(hd.holiday_date)}</StyledTableCell>
					<StyledTableCell>{dateFormat(hd.holiday_date)}</StyledTableCell>
					<StyledTableCell>{hd.holiday_occassion}</StyledTableCell>
					{
						(permissions?.includes("delete_holiday") || permissions?.includes("edit_holiday")) && 
						<StyledTableCell>
							{
								permissions?.includes("delete_holiday") && 
								<IconButton onClick={() => handleDelete(hd.id)}>
									<CancelIcon style={{ color: "red" }} />
								</IconButton>
							}
							{
								permissions?.includes("edit_holiday") && 
								<IconButton
									onClick={() =>
										handleClickEdit(hd.id, hd.holiday_occassion, hd.holiday_date)
									}
									color="primary"
								>
									<EditIcon />
								</IconButton>
							}
						</StyledTableCell>
					}

				</>
			) : (
				<TableCell colSpan={4}>
					<form
						onSubmit={(e) => handleSubmitEdit(e, hd.id)}
						style={{ marginLeft: "8%" }}
					>
						<input
							type="date"
							name="holidayDate"
							style={{ marginRight: "3%" }}
							value={holidayDetails.holidayDate}
							onChange={handleChange}
							required
						/>

						<input
							type="text"
							name="holidayOccassion"
							placeholder="enter holiday occassion"
							style={{ width: "30%", marginRight: "12%" }}
							value={holidayDetails.holidayOccassion}
							onChange={handleChange}
							required
						/>

						<IconButton type="submit">
							<DoneOutlineIcon color="primary" />
						</IconButton>
						<IconButton onClick={() => setShowEditInput(false)}>
							<ClearIcon color="primary" />
						</IconButton>
					</form>
				</TableCell>
			)}
		</TableRow>
	)
}
