import React from "react";
import "./Login.css";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../Redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Image, Form, FormGroup } from "react-bootstrap";
import { Button } from "@material-ui/core";
import Zoho from "./Zoho";
import PasswordInput from "../Common/Form/PasswordInput/PasswordInput";
import ButtonLoader from "../ButtonLoader/ButtonLoader";


const Login = function () {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory()

  const error = useSelector((store) => store.authReducer.error);

  const dispatch = useDispatch();
  
  function handleChange(e) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  function handleSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    try {
      const credentials = { username: user.email, password: user.password };
      new Promise((resolve,reject)=>{
        dispatch(
          loginUser(credentials, setIsLoading, resolve, reject)
        );
      }).then((res)=>{
        history.push("/dashboard")
      })
    } catch (e) {
      console.log(e.message);
    }
  }
  
  return (
    <>
      <Container className="leftContainer">
        <Row className="align-items-center g-5">
          <Col
            lg={6}
            className="left d-none d-lg-block"
            data-aos="fade-up-right"
          >
            <Image className="img-fluid" src="./ims.png" />
            <p className="lead">
              WOMS keeps track of inventories assigined to our employees. Keeps
              employees info. Notifies inventories serviceing.
            </p>
          </Col>

          <Col lg={6} className="mt-lg-5 pt-5" data-aos="fade-up-left">
            <Form className="formContainer" onSubmit={handleSubmit} noValidate>
              <h3 className="h3" style={{ color: "#ff6600" }}>
                Log in
              </h3>
              <div className="form-group mt-2">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                />
              </div>
              <FormGroup className="mt-2">
                <PasswordInput
                  value={user.password}
                  handleChange={handleChange}
                />
              </FormGroup>
                <div className={`form-group mt-2 text-danger ${error ? 'visible': 'invisible'}`}>
                  <i className="fas fa-exclamation-triangle m-2"></i>
                  <label style={{ fontSize: "13px" }}>{error}</label>
                </div>
              <FormGroup className="my-2">
                <div className="form-group d-flex justify-content-start align-items-center">
                  <input type="checkbox" className="form-check-input" />
                  <label
                    className="custom-control-label m-2"
                    htmlFor="customCheck1"
                  >
                    Remember me
                  </label>
                </div>
              </FormGroup>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isLoading}
                style={{ color: "white" }}
              >
                {isLoading ? <ButtonLoader color="white"/> : "Sign In"}
              </Button>
            </Form>
            <p className="forgot-password text-center mt-1">
              <Link to="/forgotpassword" className="forgotPW">
                Forgot password?
              </Link>
            </p>
            <Zoho />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
