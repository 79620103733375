import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core"
import React from "react"
import { withStyles, makeStyles } from "@material-ui/styles"
import axios from "../utils/axios"
import { useDispatch, useSelector } from "react-redux"
import { fetchCurrentUser } from "../Redux/actions/authActions"
import Card from "@material-ui/core/Card"
import { Button } from "@material-ui/core";
import SwitchDayHour from "../SwitchDayHour/SwitchDayHour"
import { Link } from "react-router-dom"
import { notify } from "../utils/toast"
import Spinner from '../Spinner/Spinner'

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#ff6600",
		color: theme.palette.common.white,
		textAlign: "center",
	},
	body: {
		textAlign: "center",
	},
}))(TableCell)
const useStyles = makeStyles({
	tableContainer: {
		marginTop: "1%",
		height: 400,
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		overflow: "scroll",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none"
	},
})

export default function MyLeaveStatistics({id,apply = false}) {
	const classes = useStyles()

	const [leaveStat, setLeaveStat] = React.useState()
	const [showHour, setShowHour] = React.useState(false)
	const { user } = useSelector((store) => store.authReducer)
	const [isLoading,setIsLoading] = React.useState(false)
	const dispatch = useDispatch()

	React.useEffect(() => {
		// dispatch(fetchCurrentUser())
		let isMounted=true;
		setIsLoading(true)
		axios
		.get(`api/leave/type?userId=${user.id}`)
		.then((res) => {
			return axios.post(`api/leaveStat`, res.data.data)
			
		})
		.then((res) => {
			if(isMounted){
				setLeaveStat(res.data)
			}
		})
		.catch((err) => notify('error','Something went wrong !'))
		.finally(()=>{
			setIsLoading(false)
		})
		
		return ()=>{
			isMounted=false;
		}
	}, [dispatch])
	return (
		<>
		{isLoading ? (<Spinner />) : (<div >
			<div className="d-flex justify-content-between">
				<h5
					style={{
						color: "#ff6600",
					}}
				>
					Leaves
				</h5>
				{apply && <Link
					to={"/leave/applyLeave"}
					style={{ textDecoration: "none"}}
				>
					<Button
						color="primary"
						variant="outlined"
						style={{ fontSize: "13px" }}
					>
						<span>Request New Leave</span>
					</Button>
				</Link>}
				<SwitchDayHour showHour={showHour} setShowHour={setShowHour} />
			</div>

			<TableContainer className={classes.tableContainer} component={Card}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Leave Type</StyledTableCell>
							<StyledTableCell>Allowed</StyledTableCell>
							<StyledTableCell>Used</StyledTableCell>
							<StyledTableCell>Remaining</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{leaveStat &&
							leaveStat.map((ls,key) => (
								<TableRow key={key}>
									<StyledTableCell>{ls.leaveTypeName}</StyledTableCell>
									<StyledTableCell>
										{showHour ? ls.totalHour : ls.totalDay}
									</StyledTableCell>
									<StyledTableCell>
										{showHour ? ls.usedHour : ls.usedDay}
									</StyledTableCell>
									<StyledTableCell>
										{showHour ? ls.remainingHour : ls.remainingDay}
									</StyledTableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
		)}
		</>
	)
}
