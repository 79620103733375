import axios from "../utils/axios"
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import PastLeaveApplyForm from './PastLeaveApplyForm'
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default function AddPastLeave() {
  const params = useParams();
  const [user, setUser] = useState({});
  const history = useHistory();
  useEffect(()=>{
    const employeeId = params.id;
    axios
      .get(`/api/users/${employeeId}`)
      .then((response)=>{
        setUser({
          name:response.data.result.firstName+" "+response.data.result.lastName,
          email:response.data.result.email,
          employeeId:response.data.result.employeeId,
          designation: response.data.result.designation,
          id: response.data.result.id
        });
      })
    
  },[])
  return (
    <div>
      <div className="container-fluid" style={{color:"#ff6600", marginLeft:100}}>
        <div className="row">
          <div className="col-md-2">
            <Button
              color="secondary"
              variant="outlined"
              style={{ fontSize: "13px"}}
              onClick={()=> {
                history.goBack();
              }}
            >
              <ArrowBackIosIcon style={{fontSize: "12px"}}/>
              <span>Back</span>
            </Button>
          </div>
          <div className="col-md-5">
            <h6>Name: {user.name}</h6><br/>
            <h6>Email: {user.email}</h6><br/>
          </div>
          <div className="col-md-5">
            <h6>Employee Id: {user.employeeId}</h6><br/>
            <h6>designation: {user.designation}</h6><br/>
          </div>
        </div>
      </div>
      <PastLeaveApplyForm employeeId={user.employeeId} id={user.id} />
    </div>
  )
}
