import React from "react"
import { Box } from "@material-ui/core"
// import { useHistory } from "react-router-dom"
import WorkSubmitModel from "../../ExtraWork/WorkSubmitModel"
import TipTap from "../../TipTap/TipTap";

// const useStyles = makeStyles({
// 	button: {
// 		height: "20px",
// 		width: 250,
// 		color: "white",
// 		backgroundColor: "#ba000d",
// 		position: "relative",
// 		top: "-85%",
// 		right: "2%",
// 		"&:hover": {
// 			backgroundColor: "#690007",
// 		},
// 	},
// })

export default function CollapsePanel({
	// ld,
	workDescription,
	status,
	declineMessage,
	remarkMessage,
	requestId,
	isLoaded,
	setIsLoaded,
	setOpen,
}) {
	return (
		<>
			<Box
				style={{
					boxShadow:"2px 2px 35px 1px gray",
					marginBottom:"10px", 
					paddingBottom:"20px", 
					paddingLeft:"20px", 
					paddingRight:"20px",
					color:
						(status === "Request Pending" && "#1769aa")||
						(status === "Request Approved" && "green") ||
						(status === "Request Rejected" && "#ba000d") ||
						(status === "Request Cancelled" && "#ba000d") ||
						(status === "Work In Review" && "#1769aa")||
						(status === "Work Approved" && "green") ||
						(status === "Work Rejected" && "#ba000d"),
				}}
			>
				<br />
				<h6
					style={{
						textAlign: "left",
					}}
				>
					Work Description:{" "}
				</h6>
				<TipTap content={workDescription} editable={false} />
				{status==="Request Cancelled" && (
					<h6 style={{ textAlign: "left" }}>
						Remark (Cancelled)
					</h6>
				)}
				{status==="Request Cancelled" && (
					
				<TipTap content={remarkMessage} editable={false} />
				)}
				{(status==="Work In Review"|| status==="Work Approved" || status==="Work Rejected") && (
					<h6 style={{textAlign: "left" }}>
						Remark (Extra work done and sent for review)
					</h6>
				)}
				{(status==="Work In Review"|| status==="Work Approved" || status==="Work Rejected") && (
					<TipTap content={remarkMessage} editable={false} />
				)}
				{declineMessage && (
					<h6 style={{textAlign: "left" }}>
						Decline Message
					</h6>
				)}
				{declineMessage && (
					<TipTap content={declineMessage} editable={false} />
				)}
				<>
					<form style={{textAlign:"left"}}>
					{(status === "Request Approved" || status === "Work In Review" || status === "Request Pending" ) && 
						(
						<WorkSubmitModel
						type={"cancel"}
							id={requestId}
							// setLoggedInId={setLoggedInId}
							// setAllWorkData={setAllWorkData}
							setIsLoaded={setIsLoaded}
							isLoaded={isLoaded}
							setOpen={setOpen}
						/>
						)}
						{status === "Request Approved" && 
						(
							<WorkSubmitModel
							type={"done"}
								id={requestId}
								// setLoggedInId={setLoggedInId}
								// setAllWorkData={setAllWorkData}
								setIsLoaded={setIsLoaded}
								isLoaded={isLoaded}
								setOpen={setOpen}
							/>
						)
						}
						{/* <Button
							style={{color: "white",marginLeft:"15px",  backgroundColor:"green" }}
							variant="contained"
							color="primary"
							onClick={()=>{
								history.push({pathname:"/work/editExtraWorkForCL/"+requestId})
							}}
						>
							Extra Work Done and Send for Review
						</Button>					 */}
					</form>
				</>
			</Box>
		</>
	)
}
