import { useField } from "formik";
import '../styles.css';

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className='my-3'>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input form-control" {...field} {...props}/>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};
export default TextInput;
