import React from "react"
import { useDispatch, useSelector } from "react-redux"
import EditIcon from "@material-ui/icons/Edit"
import { removeUser, reviveUser } from "../../Redux/actions/userActions"
import DeleteIcon from "@material-ui/icons/Delete"
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore"
import RefreshIcon from "@material-ui/icons/Refresh"
import { useHistory } from "react-router"
//Material table
import { forwardRef } from "react"
import MaterialTable from "material-table"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
	ArrowDownward,
	Add,
	Search,
	Clear,
	FilterList,
	FirstPage,
	LastPage,
	ChevronLeft,
	ChevronRight,
	SaveAlt,
} from "@material-ui/icons"
import Chip from "@material-ui/core/Chip"
import ViewColumn from "@material-ui/icons/ViewColumn"
import axios from "../../utils/axios"
import Avatar from "@material-ui/core/Avatar"
import moment from 'moment';
import { notify } from "../../utils/toast"
import getUserInfoFromToken from '../../utils/token_expiry';

const tableIcons = {
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
}

export default function UserList() {
	const tableRef = React.createRef()
	const dispatch = useDispatch()
	const history = useHistory()

	const { user } = useSelector((store) => store.authReducer)
	const permissions = useSelector((store) => store.authReducer.permissions)

	const titleStyle = {
		color: "#ff6600",
		margin: "10px",
		padding: "2px",
	}

	const columns = [
		{
			title: null,
			field: "avatar",
			render: (rowData) => (
				<Avatar alt="user" src={rowData.avatar} variant="circular" />
			),
			filtering: false,
			width: "100",
			export: false,
		},
		{
			field: "fullName",
			title: "Full name",
			sortable: false,
			filtering: true,
			render: (rowData) => (
				<label style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
					{rowData.fullName}
				</label>
			),
		},
		{
			field: "employeeId",
			title: "Id",
			filtering: false,
		},
		{
			field: "email",
			title: "Email",
			filtering: false,
		},
		{
			field: "designation",
			title: "Designation",
			filtering: true,
		},
		{
			field: "status",
			title: "Status",
			filtering: true,
			lookup: {"left": "left", "active":"active"},
			render: (rowData) => {
				return rowData.status === "active" ? (
					<Chip
						key={rowData.status}
						label={rowData.status}
						style={{
							backgroundColor: "green",
							color: "white",
							width: "70px",
							fontWeight: "bold",
							opacity: "0.8",
						}}
					/>
				) : (
					<Chip
						key={rowData.status}
						label={rowData.status}
						style={{
							backgroundColor: "red",
							color: "white",
							width: "70px",
							fontWeight: "bold",
						}}
					/>
				)
			},
		},
		{
			field: "phone",
			title: "Phone Number",
			filtering: false,
		},
		{
			field: "dateOfBirth",
			title: "Date Of Birth",
			filtering: false,
		},
	]
	return (
		<div className="rounded my-3 bg-light" data-aos="fade-up"
		data-aos-anchor-placement="top-bottom">
			<MaterialTable
				tableRef={tableRef}
				icons={tableIcons}
				title={<h6 style={titleStyle}>Employees List</h6>}
				data={(query) => {
					return new Promise((resolve, reject) => {
						let url = "/api/users?"
						url += "per_page=" + query.pageSize
						url += "&page=" + (query.page + 1)
						let filters = null
						if (query.filters.length) {
							filters = query.filters
								.map((f) => f.column.field + f.operator + f.value)
								.join("&")
							url += "&" + filters
						}
						axios
							.get(url)
							.then((res) => {
								let users = res.data.result
								let rows = users.map((user) => {
									return {
										fullName: user.firstName + " " + user.lastName,
										employeeId: user.employeeId,
										email: user.email,
										designation: user.designation,
										role: user.roles[0],
										status: user.hasLeft === false ? "active" : "left",
										phone: user.userInfo.phoneNumber || "-",
										dateOfBirth: user.dateOfBirth || "-",
										avatar: user.userInfo.ppImagePath
											? process.env.REACT_APP_API_URL +
											  user.userInfo.ppImagePath
											: null,
									}
								})
								resolve({
									data: rows,
									page: res.data.page - 1,
									totalCount: res.data.totalCount,
								})
							})
							.catch((err) => notify("error",err.response.data))
					})
				}}
				columns={columns}
				options={{
					pageSize: 10,
					columnsButton: true,
					headerStyle: {
						backgroundColor: "#ff6600",
						color: "whitesmoke",
						fontFamily: "Poppins, sans-serif",
						fontWeight: "bold",
						lineHeight: "1rem",
					},
					actionsColumnIndex: -1,
					filtering: true,
					search: false,
					exportButton: {
						csv: true,
						pdf: true
					  },
					exportFileName:`Inventories-${moment().format("YYYY-MM-DD")}`,
					rowStyle: {
						color: "#111111",
						fontSize: "0.94rem",
						fontFamily: "Poppins, sans-serif",
					},
				}}
				actions={[
					{
						icon: () => <RefreshIcon />,
						tooltip: "Refresh Data",
						isFreeAction: true,
						onClick: () => {
							tableRef.current && tableRef.current.onQueryChange()
						},
					},
					{
						icon: () => <Add style={{ fontWeight: "bolder" }} />,
						tooltip: "Add User",
						isFreeAction: true,
						onClick: (event) => history.push("/dashboard/users/add"),
						hidden: !permissions?.includes("add_user")
					},
					(rowData) => {
						if(
							rowData.status === "active" &&
							(permissions?.includes("edit_user"))
						) {
							return {
								icon: () => <EditIcon style={{ color: "#ff6600" }} />,
								tooltip: "Edit User",
								onClick: (event, rowData) =>
									history.push("/dashboard/users/edit/" + rowData.employeeId),
							}
						}
					},
					(rowData) => {
						if (
							rowData.status === "active" &&
							(permissions?.includes("delete_user"))
							){
							return {
								icon: () => <DeleteIcon style={{ color: "red" }} />,
								tooltip: "Remove User",
								onClick: (event, rowData) => {
									dispatch(removeUser(rowData.employeeId))
									setTimeout(() => {
										tableRef.current && tableRef.current.onQueryChange()
									}, 400)
								},
							}
						} else if (rowData.status === "left" && permissions?.includes("delete_user")) {
							return {
								icon: () => (
									<SettingsBackupRestoreIcon style={{ color: "green" }} />
								),
								tooltip: "Restore User",
								onClick: (event, rowData) => {
									dispatch(reviveUser(rowData.employeeId))
									setTimeout(() => {
										tableRef.current && tableRef.current.onQueryChange()
									}, 400)
								},
							}
						}
					},
					(rowData) => {
						if (
							rowData.status === "active" && 
							rowData.role !== "ROLE_SUPER_ADMIN" && 
							(permissions?.includes("login_as_user"))
							){
							return {
								icon: () => <ExitToAppIcon style={{ color: "#ff6600" }} />,
								tooltip: "Login as User",
								onClick: (event, rowData) => {
									// console.log("view as",rowData.employeeId);
									axios.get('/api/sa/loginas/'+rowData.employeeId)
									.then()
									.then((res)=>{
										const usr_token = res.data.result;
										const sa_token = localStorage.getItem('token');
										const sa_token_expires_at = localStorage.getItem('token_expires_at');
										localStorage.setItem('sa_token',sa_token);
										localStorage.setItem('token',usr_token);
										localStorage.setItem('sa_token_expires_at',sa_token_expires_at);
										console.log("sa_exp_token_at",sa_token_expires_at);
										localStorage.setItem('token_expires_at', moment.utc((getUserInfoFromToken(usr_token).exp)*1000).format());
										history.replace("/dashboard");
										window.location.reload();
										notify('success', "Logged in as "+rowData.fullName);
									})
									.catch((error)=>{
										console.log(error);
									})
								},
							}
						}
					},
				]}
				onRowClick={(event, rowData) => {

					if(permissions?.includes("view_user_profile") || rowData.employeeId === user.employeeId){
						history.push("/dashboard/users/" + rowData.employeeId)
					}else{
						notify("error","you are not permitted to view profile of other users.")
					}

				}}
			/>
		</div>
	)
}
