import React from "react"
import {
	TableCell,
	TableRow,
	withStyles,
	CircularProgress,
	Tooltip
} from "@material-ui/core"

import axios from "../../../utils/axios"
import { notify } from "../../../utils/toast"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { Collapse } from "@material-ui/core"
import AdminCollapsePanel from "../AdminCollapsePanel/AdminCollapsePanel"
import ApproverAvatar from "../../../ApproverAvatar/ApproverAvatar"
import { dateFormat } from "../../../utils/dateTimeFormat"
import RejectWorkFromHomeModal from "../../RejectWorkFromHomeModal/RejectWorkFromHomeModal"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import { IconButton } from "@material-ui/core"

const StyledTableCell = withStyles((theme) => ({
	body: {
		textAlign: "center",
	},
}))(TableCell)

export default function CollapseRequestsTable({
	data,
	page,
	setIsLoaded,
	isLoaded,
	showHour,
}) {
	const [process,setProcess] = React.useState(false)
	const [open, setOpen] = React.useState(false)
	const DOL = dateFormat(data.start_date)
	const DOA = dateFormat(data.end_date)
	React.useEffect(() => {
		setOpen(false)
	}, [page])

	const handleApprove = (id) => {
		setProcess(true)
		axios
			.put(`api/WFHRequestApprove/${id}`)
			.then((response) => {
				setProcess(false)
				notify("success",response.data)
				setIsLoaded(!isLoaded);
			})
			.catch((error) => {
				notify("error", error.response.data)
				setProcess(false)
			})
	}

	return (
		<>
			<TableRow key={data.id}>
				<StyledTableCell>
					<IconButton onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</StyledTableCell>
				<StyledTableCell>
					{data.first_name + " " + data.last_name}
				</StyledTableCell>
				<StyledTableCell>{DOL}</StyledTableCell>
				<StyledTableCell>{data.start_time}</StyledTableCell>
				<StyledTableCell>{DOA}</StyledTableCell>
				<StyledTableCell>{data.end_time}</StyledTableCell>
				<StyledTableCell>
					{showHour === false ? data.durationDay : data.durationHour}
				</StyledTableCell>
				<StyledTableCell>
					{data.approvers.map((ad,key) => (
						<ApproverAvatar key={key} ad={ad} declineMessage={data.decline_message+(data.work_from_home_status==="Cancelled")} />
					))}
				</StyledTableCell>
				<StyledTableCell>
					{data.work_from_home_status === "Pending" && (
						!process ?
						<IconButton
							style={{ color: "green" }}
							onClick={() => handleApprove(data.id)}
						>
							<Tooltip title="Approve">
								<ThumbUpIcon />
							</Tooltip>
						</IconButton>
						:
						<CircularProgress	
						/>
					)}
					<RejectWorkFromHomeModal
						id={data.id}
						setIsLoaded={setIsLoaded}
						isLoaded={isLoaded}
					/>
				</StyledTableCell>
			</TableRow>

			<TableRow>
				<StyledTableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={10}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<AdminCollapsePanel
							status={data.complementary_status}
							workFromHomeReason={data.work_from_home_reason}
							requestId={data.id}
							remark={data.remark}
						/>
					</Collapse>
				</StyledTableCell>
			</TableRow>
		</>
	)
}
